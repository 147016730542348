import React from "react"
import { Edit, useTranslate } from "react-admin"
import { TopBarTitleAction, BetterCustomForm, EditToolbar } from "./"
import { FormFields } from "./components"
const PriceBookEdit = (props) => {
  const translate = useTranslate()
  return (
    <Edit
      {...props}
      actions={
        <TopBarTitleAction
          title={translate("resources.pricebook.edit_title")}
        />
      }
    >
      <BetterCustomForm {...props} toolbar={<EditToolbar />}>
        <FormFields {...props} />
      </BetterCustomForm>
    </Edit>
  )
}

export default PriceBookEdit
