import React from "react"
import { useQueryWithStore, useTranslate } from "react-admin"
import { Box, Text } from "rebass"

// import FileExplorerTheme from "react-sortable-tree-theme-minimal"
import "react-sortable-tree/style.css"
import "./filters/shortable-tree.css"
import ListCategories from "./ListCategories"

const FiltersListSA = (props) => {
  const { websiteId } = props
  const translate = useTranslate()
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "categories",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "ASC" },
      filter: { website_id: websiteId },
    },
  })

  if (loading) {
    return (
      <Box p={[2]}>{translate("resources.categories.messages.loading")}</Box>
    )
  }
  // console.log(data)
  return (
    <Box width={[1]}>
      {data ? (
        <ListCategories dataSource={data} translate={translate} {...props} />
      ) : (
        <Box px={[3]}>
          <Text fontSize={[2]}>
            {translate("resources.categories.messages.empty_list")}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default FiltersListSA
