import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { SelectInput, RadioButtonGroupInput } from "react-admin";
import { PaperCard } from "./PaperCard";
import { dataCustomerFrom, dataDiscountType } from "./utils";
import { RaNumberInput } from "../../../../components";
import CategoryFields from "./CategoryFields";
import ProductFields from "./ProductFields";
const CustomerGetFields = (props) => {
  const { translate, formData, listCategories } = props;
  const buyFrom = get(formData, "criteria.buy_x_get_y.gets.from", "");
  const discountedType = get(
    formData,
    "criteria.buy_x_get_y.gets.discount_type",
    ""
  );
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.customer_gets")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <RaNumberInput
            source='criteria.buy_x_get_y.gets.qty'
            label={"resources.promotions.fields.qty"}
          />
          <SelectInput
            source='criteria.buy_x_get_y.gets.from'
            label={"resources.promotions.fields.any_items_from"}
            choices={dataCustomerFrom}
            style={{ width: 300 }}
          />
          {formData.website_id && buyFrom !== "" && (
            <Fragment>
              {buyFrom === dataCustomerFrom[0].id && (
                <CategoryFields
                  {...props}
                  categories={listCategories}
                  categorySource={"buy_x_get_y.gets"}
                />
              )}
              {buyFrom === dataCustomerFrom[1].id && (
                <ProductFields {...props} productSource={"buy_x_get_y.gets"} />
              )}
            </Fragment>
          )}

          <RadioButtonGroupInput
            row={false}
            label={"resources.promotions.fields.discount_type_buyx_gety"}
            source={`criteria.buy_x_get_y.gets.discount_type`}
            choices={dataDiscountType}
          />
          {discountedType === "Percentage" && (
            <RaNumberInput
              style={{ width: 330 }}
              source='criteria.buy_x_get_y.gets.percentage'
              label={"resources.promotions.fields.discounted_percentage"}
              defaultValue={0}
            />
          )}
          <RaNumberInput
            style={{ width: 330 }}
            source='criteria.buy_x_get_y.gets.max_usage_per_order'
            label={"resources.promotions.fields.max_number_uses_per_order"}
            defaultValue={0}
          />
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default CustomerGetFields;
