import React from "react";
import { get, lowerCase } from "lodash";
import lomap from "lodash/map";
import { Flex } from "rebass";
import { useTranslate } from "react-admin";
import { ImageVariantByColor, ImageVariantByAll } from "./";
import { checkPhotosColorRecord, BoxMessage, isVariant } from "./utils";

const ContentImages = (props) => {
  const { variant, photos, onUpdatePhotos, name } = props;
  const isByColor =
    variant.options.filter((item) => {
      const name = get(item, "name", undefined);
      return (
        lowerCase(name) === "color" ||
        lowerCase(name) === "colors" ||
        lowerCase(name) === "warna"
      );
    }).length > 0;
  return isByColor ? (
    <ImagesWithColor {...props} />
  ) : (
    <ImageVariantByAll
      dataImage={
        photos && photos !== null
          ? photos["*"]
          : {
              photos: [],
            }
      }
      nameProduct={name}
      onUpdatePhotos={(value) => onUpdatePhotos(value)}
    />
  );
};

const ImagesWithColor = (props) => {
  const { photos, variant, name, updateField } = props;

  const updateItemImage = (data, groupPhotos, key) => {
    groupPhotos[key] = data;
    updateField("photos", { photo_set: groupPhotos });
  };
  const dataphotos = checkPhotosColorRecord(photos, variant);
  // console.log(dataphotos)
  return lomap(dataphotos, (v, k) => (
    <ImageVariantByColor
      key={k}
      keyObject={k}
      dataImage={v}
      nameProduct={name}
      onUpdatePhotos={(value) => updateItemImage(value, dataphotos, k)}
    />
  ));
};

const ImageFields = (props) => {
  const { formValues } = props;
  const translate = useTranslate();
  const updateItemImageAll = (value) => {
    props.updateField("photos", { "*": value });
  };
  const photos = get(formValues, "photos", undefined);
  const name = get(formValues, "name", undefined);
  const variant = get(formValues, "variant", undefined);
  if (name === undefined) {
    return (
      <BoxMessage style={{ backgroundColor: "#FFFFFF" }}>
        {translate("resources.products.note_text.check_name_product")}
      </BoxMessage>
    );
  }
  return (
    <Flex width={[1]} flexDirection={"column"} p={[2]}>
      <BoxMessage>
        {translate("resources.products.note_text.image_variant")}
      </BoxMessage>
      {isVariant(variant, name) ? (
        <ContentImages
          photos={photos}
          name={name}
          variant={variant}
          updateField={props.updateField}
          onUpdatePhotos={(value) => updateItemImageAll(value)}
        />
      ) : (
        <ImageVariantByAll
          dataImage={
            photos && photos !== null
              ? photos["*"]
              : {
                  photos: [],
                }
          }
          nameProduct={name ? name : ""}
          onUpdatePhotos={(value) => updateItemImageAll(value)}
        />
      )}
    </Flex>
  );
};

export default ImageFields;
