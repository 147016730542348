import React from "react";
import { required, SelectInput } from "react-admin";
import { Box } from "rebass";
import {
  RaTextInput,
  RaBooleanInput,
  RaSelectArrayInputOptions,
} from "../../../components";

import { DataCurrency, DataLocale } from "./data";
//

const MainTab = ({ permissions, showAccount, ...props }) => {
  // const translate = useTranslate()
  return (
    <Box width={[1]} p={[3]}>
      <RaTextInput source="name" validate={required()} style={{ width: 300 }} />
      <RaBooleanInput source="active" id={"active"} />
      <Box>
        <RaSelectArrayInputOptions
          source={"locales"}
          label="resources.sites.fields.language"
          choices={DataLocale}
          fullWidth={true}
          notetext="resources.sites.note.language"
          validate={[required()]}
        />
      </Box>
      <Box width={[1]}>
        <SelectInput
          source="default_locale"
          label="resources.sites.fields.default_language"
          choices={DataLocale}
          validate={[required()]}
          margin="dense"
          style={{ width: 180 }}
        />
      </Box>
      <Box width={[1]}>
        <SelectInput
          source="currency"
          label="resources.sites.fields.currency"
          choices={DataCurrency}
          margin="dense"
          style={{ width: 180 }}
        />
      </Box>
      {/* <Box>
        <ArrayInput source="modules">
          <SimpleFormIterator disableRemove disableAdd>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <RaBooleanInput
                    source={rest.getSource("value")}
                    label={rest.scopedFormData.key}
                    style={{
                      textTransform: "capitalize",
                    }}
                  />
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box> */}
    </Box>
  );
};

export default MainTab;
