/* eslint-disable react/react-in-jsx-scope */
import React from "react"
import { Edit } from "react-admin"
import { BetterCustomSimpleForm, EditToolbar, BackTitleAction } from "./"
import { FormFields } from "./components"

export const AttributeEdit = (props) => (
  <Edit
    {...props}
    actions={
      <BackTitleAction
        title={"resources.attributes.edit_attributes"}
        backTitle="resources.attributes.back"
        basePath="/attributes/list"
      />
    }
  >
    <BetterCustomSimpleForm
      {...props}
      toolbar={<EditToolbar />}
      sourceApi="attributes"
      typePostUrl={"UPDATE"}
      pathUrl="attributes"
      customBackUrl="/attributes/list"
      title={"resources.attributes.back"}
      labelBack={"resources.attributes.edit_attributes"}
      isShowBack={true}
      hideAction={true}
    >
      <FormFields {...props} />
    </BetterCustomSimpleForm>
  </Edit>
)
