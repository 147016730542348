import React from "react";
import { Create, useTranslate } from "react-admin";

import {
  BetterTabbedForm,
  EditToolbar,
  TopBarTitleAction,
  FormValueTab,
} from "../../components";
import { InfoFields, ImageFields, CreateVariantFields } from "./components";
import { getSiteID } from "../../restClient";
const initValues = {
  site_id: getSiteID(),
};

const ProductCreate = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props;
  const translate = useTranslate();
  return (
    <Create
      {...rest}
      actions={
        <TopBarTitleAction title={translate("resources.products.new_title")} />
      }
    >
      <BetterTabbedForm
        toolbar={<EditToolbar />}
        {...rest}
        initialValues={initValues}
      >
        <FormValueTab label='resources.products.tabs.info'>
          <InfoFields {...props} isCreate={true} translate={translate} />
        </FormValueTab>
        <FormValueTab
          label='resources.products.tabs.variants'
          path={"variants"}
        >
          <CreateVariantFields {...props} translate={translate} />
        </FormValueTab>
        <FormValueTab label='resources.products.tabs.images' path={"images"}>
          <ImageFields {...props} translate={translate} />
        </FormValueTab>
      </BetterTabbedForm>
    </Create>
  );
};

export default ProductCreate;
