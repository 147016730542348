import React, { useState } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { useTranslate } from "react-admin";
import { RTDescriptionProduct } from "../";
import { toolbar } from "./utils";
import TextField from "@material-ui/core/TextField";

const ContentItem = (props) => {
  const { locale, formValues } = props;
  const translate = useTranslate();
  const [myValue, setMyValue] = useState(
    get(formValues, `locale.${locale}.name`, "")
  );

  const onChangeValue = (e) => {
    setMyValue(e.target.value);
  };
  const onBlurValue = (e) => {
    props.updateField(`locale.${locale}.name`, e.target.value);
    if (locale === "en") {
      props.updateField("name", e.target.value);
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <TextField
        id={`text-field-locale.${locale}.name`}
        name={`text-field-locale.${locale}.name`}
        value={myValue}
        label={translate("resources.products.fields.name")}
        type={"text"}
        style={{ width: 400 }}
        onBlur={(e) => onBlurValue(e)}
        onChange={(e) => onChangeValue(e)}
      />
      {/* <RaTextInput
        source={`locale.${locale}.name`}
        label='resources.products.fields.name'
        style={{ width: 400 }}
        onChange={onChangeName(locale, "name")}
      /> */}
      <RTDescriptionProduct
        resource={"products"}
        source={`locale.${locale}.description`}
        label={"resources.products.fields.description"}
        toolbar={toolbar}
      />
    </Flex>
  );
};

export default ContentItem;
