import React from "react"
import { union } from "lodash"
import { Flex } from "rebass"
import Button from "@material-ui/core/Button"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Dialog, DialogContent } from "@material-ui/core"
import { useQueryWithStore, useGetMany, useTranslate } from "react-admin"

import { makeStyles } from "@material-ui/core/styles"
import Chip from "@material-ui/core/Chip"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}))

const ChipFieldCategory = ({ categories }) => {
  const classes = useStyles()
  console.log(categories)
  const { data, loading } = useGetMany("categories", categories)
  // const { data, loading } = useQueryWithStore({
  //   type: "getMany",
  //   resource: `categories`,
  //   payload: { ids: categories },
  // })
  if (loading) {
    return <div>loading...</div>
  }
  console.log(data)
  return (
    <Flex>
      <div className={classes.root}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return <Chip label={item.name} key={index} />
          })}
      </div>
    </Flex>
  )
}

const ShowCategoriesField = ({ record }) => {
  const [open, setOpen] = React.useState(false)
  const translate = useTranslate()
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: `productCategories`,
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        product_id: record.id,
        site_id: record.site_id,
      },
    },
  })
  if (loading) {
    return <div>{translate(`resources.categories.messages.loading`)}</div>
  }
  let myCategories = []

  data.map((item) => {
    // myCategories.push(item.categories)
    let newArray = myCategories
    myCategories = union(newArray, item.categories)
    return ""
  })
  // console.log(myCategories)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        style={{ textTransform: "capitalize" }}
      >
        {translate("resources.products.label.show_categories")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="product-categories-dialog-title"
        open={open}
        maxWidth={"lg"}
      >
        <DialogTitle id="product-categories-dialog-title">
          {translate("resources.products.fields.categories")}
        </DialogTitle>
        <DialogContent>
          <ChipFieldCategory categories={myCategories} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ShowCategoriesField
