import React from "react";
import { withDataProvider, Loading } from "react-admin";
import { Flex } from "rebass";
import { languages, choicesLanguages } from "../../../utils";
import PageContent from "./PageContent";

class Content extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      languageSite: [],
      default_locale: "en",
      locale: languages,
      localesList: {
        default_locale: "en",
        locale: languages,
        languageSite: [],
      },
      error: "",
    };
  }
  componentDidMount() {
    const { dataProvider, values } = this.props;
    this.setState({
      loading: true,
    });
    dataProvider
      .getOne("sites", { id: values.site_id })
      .then(({ data }) => {
        if (data) {
          let newLanguage = data.locales.map(function (e) {
            let f = choicesLanguages.find((a) => a.id === e);
            return f ? f : undefined;
          });

          let newItem = newLanguage.map((item) => {
            return {
              id: item.id,
              name: item.name,
              data: undefined,
              layoutSelected: [],
              isShowing: true,
            };
          });

          this.onUpdate(newItem, data, newLanguage);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }
  onUpdate = (newItem, data, newLanguage) => {
    this.setState({
      loading: false,
      languageSite: newItem,
      default_locale: data.default_locale,
      locale: newLanguage,
    });
  };
  render() {
    const { loading, languageSite } = this.state;
    if (loading)
      return (
        <Flex width={[1]} justifyContent={"center"} style={{ height: "70vh" }}>
          <Loading />
        </Flex>
      );
    // console.log(languageSite)
    return (
      <PageContent
        {...this.props}
        languageSite={languageSite}
        statusForm={"edit"}
      />
    );
  }
}

export default withDataProvider(Content);
