import React from "react";
import {
  ReferenceField,
  TextField,
  SearchInput,
  useQueryWithStore,
} from "react-admin";
import { Flex } from "rebass";
import { get, find } from "lodash";
import { LinearProgress } from "@material-ui/core";
import { BetterList, Filter } from "../../components/ra-list";
import { ListAction } from "./components";
const InventoriesFilter = (props) => (
  <Flex width={[1]}>
    <Filter {...props}>
      <SearchInput source='search' alwaysOn />
    </Filter>
  </Flex>
);

const VariantField = ({ record }) => {
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "products",
    payload: { id: record.product_id },
  });
  const variants = get(data, "variant.variants", []);
  const myVariantName = find(variants, function (o) {
    return o.sku === record.sku;
  });
  if (loading) {
    return <LinearProgress />;
  }
  return <span>{myVariantName.name}</span>;
};
const NumberField = ({ record, source }) => {
  return (
    <div style={{ width: "100%", textAlign: "right" }}>
      {get(record, source, "")}
    </div>
  );
};

const InventoriesList = (props) => {
  return (
    <BetterList
      {...props}
      actions={<ListAction title={`resources.inventories.name`} />}
      filters={<InventoriesFilter />}
    >
      <ReferenceField
        label='resources.inventories.fields.product_name'
        source='product_id'
        reference='products'
        link={false}
      >
        <TextField source='name' />
      </ReferenceField>
      <VariantField
        source='name'
        label={"resources.inventories.fields.variant"}
      />
      <TextField source='sku' label={"resources.inventories.fields.sku"} />
      <NumberField
        source='qty'
        label={"resources.inventories.fields.available"}
      />
      {/* <NumberField
        source='threshold'
        label={"resources.inventories.fields.threshold"}
      />
      <NumberField
        source='ordered'
        label={"resources.inventories.fields.ordered"}
      /> */}
    </BetterList>
  );
};

export default InventoriesList;
