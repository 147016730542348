import React from "react";
import { useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
import { Title } from "../../../components";
import { BOX_SHADOW_COLOR } from "../../../utils";
import ButtonUploadCsv from "./upload/ButtonUploadCsv";
import ButtonExportCsv from "./ButtonExportCsv";
const ListAction = (props) => {
  const {
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    title,
  } = props;
  const translate = useTranslate();
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        p={[3]}
        bg={"white"}
        style={{
          boxShadow: BOX_SHADOW_COLOR,
        }}
      >
        <Box width={[1]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(title)}
          </Title>
        </Box>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Box width={["130px"]} pt={[2]}>
            {filters &&
              React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
              })}
          </Box>
          {/* {!isHideCreate && (
            <CreateButton
              basePath={basePath}
              icon={<AddIcon />}
              sizeIcon={"xsmall"}
              style={{ textTransform: "capitalize" }}
            />
          )} */}
          <ButtonExportCsv {...props} translate={translate} />
          <ButtonUploadCsv {...props} />
        </Flex>
      </Flex>
      <Box
        width={[1]}
        style={{ height: 24, backgroundColor: "transparent" }}
        mb={[2]}
      />
    </Box>
  );
};

export default ListAction;
