import React from "react";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import TextField from "@material-ui/core/TextField";
import { Currency } from "../../../../components";
import { NumberFormatCustom } from "./utils";
import ReasonFields from "./ReasonFields";

const FormFields = (props) => {
  const { translate, state, setState, website } = props;

  const handleChange = (k, v) => {
    let value = v;
    if (k === "shipping_fee" || k === "admin_fee") {
      value = v * 1;
    }
    setState({
      ...state,
      [k]: value,
    });
  };
  const currency_symbol = get(website, "locale.currency_symbol", "");
  let total = state.amount_paid - (state.shipping_fee + state.admin_fee);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ReasonFields {...props} handleChange={handleChange} />
      <Flex width={[1]} flexDirection={"column"}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          bg={"#eeeeee"}
          p={[2]}
        >
          <Text>{translate("resources.orders.labels.refund_subtotal")}</Text>
          <Text>
            <Currency
              value={state.amount_paid * 1}
              decimalScale={2}
              unit={currency_symbol}
              fixedDecimalScale={true}
            />
          </Text>
        </Flex>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          p={[2]}
        >
          <Text>
            {translate("resources.orders.labels.return_shipping_fee")}
          </Text>
          <Flex flexDirection={"row"} alignItems={"center"}>
            (
            <TextField
              label=""
              value={state.shipping_fee * 1}
              onChange={(e) => handleChange("shipping_fee", e.target.value)}
              name="shipping_fee"
              id={`input_shipping_fee-${currency_symbol}`}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            )
          </Flex>
        </Flex>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          p={[2]}
        >
          <Text>{translate("resources.orders.labels.admin_fee")}</Text>
          <Flex flexDirection={"row"} alignItems={"center"}>
            (
            <TextField
              label=""
              value={state.admin_fee * 1}
              onChange={(e) => handleChange("admin_fee", e.target.value)}
              name="admin_fee"
              id={`input_admin_fee-${currency_symbol}`}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            )
          </Flex>
        </Flex>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          bg={"#eeeeee"}
          p={[2]}
          pb={[3]}
        >
          <Text>{translate("resources.orders.labels.total_refund")}</Text>
          <Text>
            <Currency
              value={total * 1}
              decimalScale={2}
              unit={currency_symbol}
              fixedDecimalScale={true}
            />
          </Text>
        </Flex>
      </Flex>

      <Box width={[1]} mt={[3]}>
        <Text fontSize={[0]} fontWeight={"bold"}>
          {translate("resources.orders.fields.remarks")}
        </Text>
        <TextField
          id="remarks-input"
          label={""}
          multiline
          rows={6}
          value={state.remarks}
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => handleChange("remarks", e.target.value)}
          variant="filled"
          InputProps={{
            style: {
              paddingBottom: 0,
              backgroundColor: "#eeeeee",
              paddingTop: 0,
            },
          }}
        />
      </Box>
    </Flex>
  );
};

export default FormFields;
