import React from "react";
import { filter, get, union, uniq, isNumber, without } from "lodash";
import { Flex, Box } from "rebass";
import { PaperCardWithButton } from "../../promotions/components/promotions/PaperCard";
import { useNotify } from "react-admin";
import DialogProducts from "./DialogProducts";
// import TableVariants from "./TableVariants";
import TableProductsSKU from "./TableProductsSKU";
import ButtonImportCSV from "./ButtonImportCSV";
const ProductFields = (props) => {
  const { translate, formData } = props;
  const notify = useNotify();
  const setSelectedProduct = (selected, product) => {
    const products = get(formData, `products`, []);
    const newProducts = filter(products, function (o) {
      return o !== product;
    });
    const newData = union(newProducts, selected);
    const myList = uniq(newData);

    props.updateField(`products`, without(myList, undefined));
  };

  const onDeleteProductSKU = (product) => {
    const products = get(formData, `products`, []);
    const newProducts = filter(products, function (o) {
      return o !== product;
    });
    const myList = uniq(newProducts);
    props.updateField(`products`, without(myList, undefined));
  };

  const onImport = (list) => {
    const listproducts = get(formData, `products`, []);
    if (list && list.length > 0) {
      const newList = [...listproducts, ...list];
      const checkList = newList.map((item) => {
        return item * 1;
      });
      const myFilter = filter(checkList, function (o) {
        if (isNumber(o * 1)) {
          return o;
        }
      });
      const myList = uniq(myFilter);
      props.updateField(`products`, without(myList, undefined));
    } else {
      notify("resources.errors.list_empty", "warning", {
        smart_count: 1,
      });
    }
  };
  return (
    <Flex width={[1]} py={[1]} flexDirection='column'>
      <PaperCardWithButton
        title={translate("resources.products.name")}
        buttons={
          <Flex
            px={[2]}
            sx={{
              "&>*": {
                mx: [2],
              },
            }}
          >
            <ButtonImportCSV onImport={onImport} translate={translate} />
          </Flex>
        }
        elevation={3}
      >
        <Box width={[1]}>
          <DialogProducts {...props} />
        </Box>
        <TableProductsSKU
          {...props}
          onDeleteProduct={(p) => onDeleteProductSKU(p)}
          setSelectedProduct={setSelectedProduct}
        />
      </PaperCardWithButton>
    </Flex>
  );
};

export default ProductFields;
