import React from "react";
import { Flex } from "rebass";
// import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { TextRecord } from "../../../../components";
import { dataReason, useStyles } from "./utils";

const EditReasonFields = (props) => {
  const { translate, state, handleChange } = props;
  const classes = useStyles();
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      alignItems={"center"}
      sx={{
        "&>div": {
          pr: [2],
        },
      }}
    >
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="reason_select-label">
          {translate("resources.orders.fields.reason")}
        </InputLabel>
        <Select
          labelId="reason_select-label"
          id="reason_select"
          value={state.reason}
          onChange={(e) => handleChange("reason", e.target.value)}
        >
          {dataReason.map((p, index) => {
            return (
              <MenuItem value={p.id} key={index}>
                {p.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Flex pt={[3]}>
        <TextRecord
          label={"resources.orders.fields.status"}
          source="status"
          record={state}
          isCaiptal={true}
        />
      </Flex>
    </Flex>
  );
};

export default EditReasonFields;
