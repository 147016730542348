import React, { Fragment } from "react"
import { Flex, Box } from "rebass"
import { SelectInput, FormDataConsumer, required } from "react-admin"
import { RaTextInput } from "./"
import { LocaleAtributes } from "./LocaleAtributes"
import { getSiteID } from "../../../restClient"
import { ROLE_USER } from "../../../utils"
const dataType = [
  { id: "Text", name: "Text" },
  { id: "Number", name: "Number" },
  { id: "Rich Text", name: "Rich Text" },
  { id: "Yes/No", name: "Yes/No" },
]

export const FormFields = (props) => {
  const { permissions, isCreate } = props
  return (
    <Flex flexDirection={"column"} px={[2]}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const SiteID =
            formData.site_id === getSiteID() ? formData.site_id : getSiteID()
          if (
            formData.site_id !== getSiteID() &&
            permissions === ROLE_USER.super_admin
          ) {
            props.updateField("site_id", SiteID)
          }

          return (
            // <Fragment>
            //   {formData.data_type === "Number" && (
            //     <RaNumberInput
            //       source="value"
            //       label={"resources.attributes.fields.value"}
            //       notetext={"resources.attributes.note.value"}
            //     />
            //   )}
            <Fragment>
              <Box width={[1]} pb={[2]}>
                <LocaleAtributes
                  {...rest}
                  formData={formData}
                  updateField={props.updateField}
                  site_id={SiteID}
                />
              </Box>
            </Fragment>
          )
        }}
      </FormDataConsumer>

      <SelectInput
        label={"resources.attributes.fields.data_type"}
        source={"data_type"}
        choices={dataType}
        style={{ width: 256 }}
      />
      {/* <RaTextInput
        source="code"
        label={"resources.attributes.fields.code"}
        notetext={"resources.attributes.note.code"}
        style={{ width: 256 }}
        validate={[required()]}
      /> */}
      {!isCreate && (
        <RaTextInput
          source="code"
          label={"resources.attributes.fields.code"}
          notetext={"resources.attributes.note.code"}
          style={{ width: 256 }}
          validate={[required()]}
        />
      )}
    </Flex>
  )
}
