import React from "react";
import moment from "moment";
import { get } from "lodash";
import {
  TextField,
  SelectInput,
  ReferenceInput,
  SearchInput,
  // ReferenceField,
} from "react-admin";
import { Filter, BetterList } from "../../components/ra-list";
import { getSiteID } from "../../restClient";
import { dataStatus } from "./components";
import {
  ListOrderAction,
  RaTextField,
  RaCurrencyField,
  RaReferenceField,
} from "../../components";
// import { ROLE_USER } from "../../utils";

const CreateOnField = ({ record }) => {
  const createdOn = moment(record.created_at).format("DD-MMM-YYYY HH:mm");
  return <span>{createdOn}</span>;
};

const OrderFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label=''
      source='website_id'
      reference='websites'
      alwaysOn
      InputLabelProps={{
        shrink: true,
      }}
      style={{ top: -10, position: "absolute", marginLeft: 100 }}
    >
      <SelectInput optionText='name' />
    </ReferenceInput>

    <SelectInput source='status' choices={dataStatus} alwaysOn />
    <SearchInput source='search' alwaysOn />
  </Filter>
);

const PaymentField = ({ record, source }) => {
  return (
    <span style={{ textTransform: "capitalize" }}>
      {get(record, source, "")}
    </span>
  );
};

const OrdersList = (props) => {
  return (
    <BetterList
      {...props}
      actions={<ListOrderAction title='resources.orders.title.name' />}
      filters={<OrderFilter />}
      filter={{ site_id: getSiteID() }}
      rowClick={"show"}
      sort={{ field: "created_at", order: "DESC" }}
      exporter={false}
    >
      <TextField source='ref' label={"resources.orders.fields.reference"} />
      <RaTextField source='status' label={"resources.orders.fields.status"} />
      <TextField
        source='customer_info.name'
        label={"resources.orders.fields.customer"}
      />
      <PaymentField
        source='payment_info.[0].status'
        label={"resources.orders.fields.payment"}
      />
      <RaReferenceField
        label={"resources.orders.fields.amount"}
        source='website_id'
        reference='websites'
        link={false}
      >
        <RaCurrencyField
          source='amounts.total'
          label={"resources.orders.fields.amount"}
        />
      </RaReferenceField>
      {/* <RaCurrencyField
        source="amounts.total"
        label={"resources.orders.fields.amount"}
      /> */}
      <CreateOnField
        source={"created_at"}
        label={"resources.orders.fields.created_at"}
      />
      {/* <ButtonEditView {...props} /> */}
    </BetterList>
  );
};
export default OrdersList;
