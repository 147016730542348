import React, { Fragment } from "react"
import { Flex, Box, Text } from "rebass"
import {
  ReferenceInput,
  SelectInput,
  useGetOne,
  Loading,
  FormDataConsumer,
  useTranslate,
} from "react-admin"

import { RaNumberInput, RaBooleanInput, RaSelectArrayChipInput } from "./"

const ItemFields = props => {
  const { formData } = props

  const { data, loading } = useGetOne("attributes", formData.attribute_id)
  if (loading) {
    return <Loading />
  }
  return (
    <Box width={[1]}>
      <Text p={[2]} pl={[1]}>
        {data.data_type}
      </Text>
      {data.data_type === "Number" && <ItemNumbers {...props} />}
      {(data.data_type === "Text" || data.data_type === "Rich Text") && (
        <ItemText {...props} />
      )}
    </Box>
  )
}

const ItemNumbers = () => {
  return (
    <Fragment>
      <RaNumberInput
        label={"resources.filters.fields.first_step"}
        source="first_step"
        notetext={"resources.filters.note_text.first_step"}
      />
      <RaNumberInput
        label={"resources.filters.fields.last_step"}
        source="last_step"
        notetext={"resources.filters.note_text.last_step"}
      />
      <RaNumberInput
        label={"resources.filters.fields.interval"}
        source="interval"
        notetext={"resources.filters.note_text.interval"}
      />
    </Fragment>
  )
}
const ItemText = ({ formData, translate, ...rest }) => {
  return (
    <Fragment>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Text width={"80px"} pr={[3]}>
          {translate("resources.filters.fields.filters")}
        </Text>
        <RaBooleanInput
          label={"resources.filters.fields.automatics"}
          source="filters"
          notetext={"resources.filters.note_text.filter"}
          defaultValue={false}
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-end"}
      >
        <Text width={"80px"} pr={[3]}>
          {translate("resources.filters.fields.filters")}
        </Text>
        {!formData.filters && (
          <RaSelectArrayChipInput
            label={"resources.filters.fields.empty"}
            source="data_filters"
            updateField={rest.updateField}
            fullWidth={true}
          />
        )}
      </Flex>
    </Fragment>
  )
}

const FormFields = props => {
  const translate = useTranslate()

  return (
    <Flex flexDirection={"column"}>
      <ReferenceInput
        source="attribute_id"
        reference="attributes"
        defaultValue={1}
        label={"resources.filters.fields.attribute"}
      >
        <SelectInput optionText="name" style={{ width: 256 }} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <Fragment>
            {formData.attribute_id && (
              <ItemFields
                {...rest}
                formData={formData}
                translate={translate}
                updateField={props.updateField}
              />
            )}
          </Fragment>
        )}
      </FormDataConsumer>
    </Flex>
  )
}

export default FormFields
