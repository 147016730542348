import React from "react"
import { Edit } from "react-admin"
import { BetterCustomSimpleForm, EditToolbar, BackTitleAction } from "./"
import { FormFields } from "./components"

export const AttributeSetEdit = (props) => {
  return (
    <Edit
      {...props}
      actions={
        <BackTitleAction
          title={"resources.attributeSet.edit_attributeset"}
          backTitle="resources.attributeSet.back"
          basePath="/attributes"
        />
      }
    >
      <BetterCustomSimpleForm
        {...props}
        toolbar={<EditToolbar />}
        title={"resources.attributeSet.back"}
        labelBack={"resources.attributeSet.new_attributeset"}
        sourceApi="attributeSets"
        typePostUrl={"UPDATE"}
        pathUrl="attributeSets"
        isShowBack={true}
        customBackUrl={"/attributes"}
        hideAction={true}
      >
        <FormFields {...props} />
      </BetterCustomSimpleForm>
    </Edit>
  )
}
