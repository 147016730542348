import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { get } from "lodash";
import { ThemeProvider } from "@material-ui/core/styles";
import { themeRaTA1 } from "../../../../components/ra-field/themeTextArea";

const useStyles = makeStyles((theme) => ({
  withoutLabel: {
    marginTop: theme.spacing(2),
  },
}));

const TextFieldInput = (props) => {
  const {
    value,
    source,
    updateField,
    label,
    style,
    type = "text",
    required,
    data,
    sourceParent,
  } = props;
  const classes = useStyles();
  const [myValue, setMyValue] = useState(value);

  const onChangeValue = (e) => {
    setMyValue(e.target.value);
  };
  const onBlurValue = (e) => {
    if (data && get(data, "id", "") !== "") {
      updateField(`${sourceParent}.id`, get(data, "id", ""));
    }

    if (type === "number") {
      updateField(source, e.target.value * 1);
    } else {
      updateField(source, e.target.value);
    }
  };
  if (required) {
    return (
      <FormControl className={clsx(classes.withoutLabel)}>
        <TextField
          InputLabelProps={{ shrink: true }}
          error={myValue === ""}
          id={`text-field-${source}`}
          name={`text-field-${source}`}
          value={myValue}
          label={label}
          type={type}
          style={style}
          onBlur={(e) => onBlurValue(e)}
          onChange={(e) => onChangeValue(e)}
          helperText={myValue === "" ? "Required" : ""}
        />
      </FormControl>
    );
  }
  return (
    <FormControl className={clsx(classes.withoutLabel)}>
      <TextField
        InputLabelProps={{ shrink: true }}
        id={`text-field-${source}`}
        name={`text-field-${source}`}
        value={myValue}
        label={label}
        type={type}
        style={style}
        onBlur={(e) => onBlurValue(e)}
        onChange={(e) => onChangeValue(e)}
      />
    </FormControl>
  );
};

const TextFieldAttInput = (props) => {
  const {
    value,
    source,
    updateField,
    label,
    style,
    type = "text",
    required,
    data,
    sourceParent,
  } = props;

  const classes = useStyles();
  const [myValue, setMyValue] = useState(value);
  const onChangeValue = (e) => {
    setMyValue(e.target.value);
  };
  const onBlurValue = (e) => {
    if (data && get(data, "id", "") !== "") {
      updateField(`${sourceParent}.id`, get(data, "id", ""));
    }

    if (type === "number") {
      updateField(source, e.target.value * 1);
    } else {
      updateField(source, e.target.value);
    }
  };
  // if (required) {
  //   return (
  //     <FormControl className={clsx(classes.withoutLabel)}>
  //       <TextField
  //         InputLabelProps={{ shrink: true }}
  //         error={myValue === ""}
  //         id={`text-field-${source}`}
  //         name={`text-field-${source}`}
  //         value={myValue}
  //         label={label}
  //         type={type}
  //         style={style}
  //         onBlur={(e) => onBlurValue(e)}
  //         onChange={(e) => onChangeValue(e)}
  //       />
  //     </FormControl>
  //   );
  // }
  return (
    <FormControl className={clsx(classes.withoutLabel)}>
      <TextField
        InputLabelProps={{ shrink: true }}
        id={`text-field-${source}`}
        name={`text-field-${source}`}
        value={myValue}
        label={label}
        type={type}
        style={style}
        onBlur={(e) => onBlurValue(e)}
        onChange={(e) => onChangeValue(e)}
        error={required && myValue === ""}
        helperText={required && myValue === "" ? "Required" : ""}
      />
    </FormControl>
  );
};

const TextAreaInput = (props) => {
  const {
    value,
    source,
    updateField,
    label,
    style,
    type = "text",
    required,
    data,
    sourceParent,
  } = props;

  const [myValue, setMyValue] = useState(value);

  const onChangeValue = (e) => {
    setMyValue(e.target.value);
  };
  const onBlurValue = (e) => {
    if (data && get(data, "id", "") !== "") {
      updateField(`${sourceParent}.id`, get(data, "id", ""));
    }
    updateField(source, e.target.value);
  };
  if (required) {
    return (
      <ThemeProvider theme={themeRaTA1}>
        <TextField
          InputLabelProps={{ shrink: true }}
          error={myValue === ""}
          id={`text-field-${source}`}
          name={`text-field-${source}`}
          value={myValue}
          label={label}
          type={type}
          variant='filled'
          multiline
          rows={6}
          style={style}
          onBlur={(e) => onBlurValue(e)}
          onChange={(e) => onChangeValue(e)}
          helperText={myValue === "" ? "Required" : ""}
        />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={themeRaTA1}>
      <TextField
        id={`text-field-${source}`}
        name={`text-field-${source}`}
        value={myValue}
        label={label}
        multiline
        rows={6}
        type={type}
        style={style}
        onBlur={(e) => onBlurValue(e)}
        onChange={(e) => onChangeValue(e)}
      />
    </ThemeProvider>
  );
};

/*multiline
          rowsMax={4}*/
const BooleanFieldInput = (props) => {
  const { value, source, updateField, style, label, data, sourceParent } =
    props;
  const [myValue, setMyValue] = useState(value);

  const onChangeValue = (e) => {
    setMyValue(e.target.checked);
  };
  const onBlurValue = (e) => {
    if (data && get(data, "id", "") !== "") {
      updateField(`${sourceParent}.id`, get(data, "id", ""));
    }
    updateField(source, e.target.checked);
  };
  if (label && label !== "") {
    return (
      <FormControlLabel
        control={
          <Switch
            name={`switch-field-${source}`}
            checked={myValue}
            color='primary'
            style={style}
            onBlur={(e) => onBlurValue(e)}
            onChange={(e) => onChangeValue(e)}
          />
        }
        label={label}
      />
    );
  }
  return (
    <Switch
      name={`switch-field-${source}`}
      checked={myValue}
      label={label ? label : ""}
      color='primary'
      style={style}
      onBlur={(e) => onBlurValue(e)}
      onChange={(e) => onChangeValue(e)}
    />
  );
};

export { TextFieldInput, BooleanFieldInput, TextAreaInput, TextFieldAttInput };
