import React, { Fragment, useCallback, useState } from "react";
import { Box } from "rebass";
import { useTranslate, useQueryWithStore } from "react-admin";
import CategoriesEdit from "./Edit";
import CategoriesCreate from "./Create";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { HeaderTitle } from "../../components";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { usePermissions } from "react-admin";
import { FiltersList, FiltersListSA } from "./components";
import { getSiteID } from "../../restClient";
import { ROLE_USER } from "../../utils";
import { useStyles } from "./styles";

export const CategoriesMultiList = (props) => {
  const { permissions } = usePermissions();
  const [website, setWebsite] = useState("");
  const classes = useStyles();
  const translate = useTranslate();
  // const theme = useTheme()
  const history = useHistory();

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "websites",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "ASC" },
      filter: { site_id: getSiteID() },
    },
  });

  const handleClose = useCallback(() => {
    history.push("/categories");
  }, [history]);

  const openCreate = useCallback(
    (id) => {
      let pathUrl = "/categories/create";
      if (id !== null) {
        pathUrl = `/categories/create?pid=${id}`;
      }
      history.push(pathUrl);
    },
    [history]
  );

  const openEdit = useCallback(
    (id) => {
      history.push(`/categories/${id}`);
    },
    [history]
  );

  const handleChange = (event) => {
    if (website !== event.target.value) {
      setWebsite(event.target.value);
    }
  };

  if (loading) {
    return (
      <Box width={[1]} p={[2]}>
        {translate(`resources.categories.messages.loading`)}
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <HeaderTitle title={`resources.categories.title`} />
      {data &&
        data.length > 0 &&
        (permissions === ROLE_USER.super_admin ? (
          <Fragment>
            <Box width={[1]} p={[3]}>
              <FormControl className={classes.formControl}>
                <InputLabel id="websites-select-label">
                  {translate(`resources.categories.fields.website`)}
                </InputLabel>
                <Select
                  labelId="websites-select-label"
                  id="websites-select"
                  value={website === "" ? data[0].id : website}
                  onChange={handleChange}
                >
                  {/* <MenuItem value={0}>All</MenuItem> */}
                  {data &&
                    data.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
            <FiltersListSA
              {...props}
              openCreate={openCreate}
              openEdit={openEdit}
              websiteId={website === "" ? data[0].id : website}
            />
          </Fragment>
        ) : (
          <FiltersList {...props} openCreate={openCreate} openEdit={openEdit} />
        ))}

      <div className={classes.rootList}>
        <Route path="/categories/:id">
          {({ match }) => {
            const isMatch = !!(
              match &&
              match.url &&
              match.url !== "/categories/create"
            );
            if (match && match.url && match.url === "/categories/create") {
              return (
                <Fragment>
                  <Drawer
                    variant="persistent"
                    open={
                      match && match.url && match.url === "/categories/create"
                    }
                    anchor="right"
                    onClose={handleClose}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    {match &&
                    match.url &&
                    match.url === "/categories/create" ? (
                      <CategoriesCreate
                        onCancel={handleClose}
                        {...props}
                        website={website === "" ? data[0].id : website}
                      />
                    ) : null}
                  </Drawer>
                </Fragment>
              );
            }
            return (
              <Fragment>
                <Drawer
                  variant="persistent"
                  open={isMatch}
                  anchor="right"
                  onClose={handleClose}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {isMatch &&
                  match &&
                  match.url &&
                  match.url !== "/categories/create" ? (
                    <CategoriesEdit
                      id={match.params.id}
                      onCancel={handleClose}
                      {...props}
                      website={website === "" ? data[0].id : website}
                    />
                  ) : null}
                </Drawer>
              </Fragment>
            );
          }}
        </Route>
      </div>
    </div>
  );
};
