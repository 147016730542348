import React, { useState } from "react"
import { keyBy } from "lodash"
import { Box } from "rebass"
import { get } from "lodash"
import {
  useQueryWithStore,
  Pagination,
  Loading,
  TextField,
  BooleanField,
} from "react-admin"
import { useLocation } from "react-router-dom"
import { CreateButton, ButtonEditView, Datagrid } from "../../../../components"
import { HeaderBox, ContentBox } from "../../../../utils"

const SourceGroupDataGrid = (props) => {
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)

  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "sourceGroups",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { site_id: get(props, "id", 0) },
    },
  })
  if (loading) {
    return <Loading />
  }
  return (
    <Box width={[1]}>
      <HeaderBox px={[1]}>
        <CreateButton
          basePath={location.pathname}
          source={"/createGroup"}
          sizeIcon={"xsmall"}
          style={{ textTransform: "capitalize" }}
        />
      </HeaderBox>

      {data && (
        <ContentBox px={[1]} py={[3]}>
          <Datagrid
            {...props}
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath="/sources" // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField source="name" />
            <BooleanField source="active" />
            <ButtonEditView {...props} basePath="/sourceGroups" />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={total}
          />
        </ContentBox>
      )}
    </Box>
  )
}

export default SourceGroupDataGrid
