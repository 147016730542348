import React, { useState } from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { Tree } from "antd";
import { PaperCard } from "./PaperCard";
import { listCategories } from "../../../products/components/categories";

const CategoryFields = (props) => {
  const {
    categories,
    formData,
    categorySource,
    updateField,
    translate,
  } = props;
  const joinSoureParent = `criteria.${categorySource}.categories`;
  const value = get(formData, joinSoureParent, []);
  const dataSource = listCategories(categories);
  const [expandedKeys, setExpandedKeys] = useState(value);
  const [checkedKeys, setCheckedKeys] = useState(value);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (newValue) => {
    // console.log("onCheck", newValue);
    setCheckedKeys(newValue);
    updateField(joinSoureParent, newValue);
  };
  return (
    <Flex width={[1]} py={[1]} flexDirection="column">
      <PaperCard
        title={translate(
          "resources.promotions.labels.select_applicable_categories"
        )}
        elevation={3}
      >
        <div>
          <Tree
            checkable
            selectable={false}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={dataSource}
          />
        </div>
      </PaperCard>
    </Flex>
  );
};

export default CategoryFields;
