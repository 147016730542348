import React, { useState } from "react"
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useTranslate,
} from "react-admin"
import { get, has } from "lodash"
import IconContentAdd from "@material-ui/icons/Add"
import IconCancel from "@material-ui/icons/Cancel"
import { makeStyles } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import FormFieldDialog from "./FormFieldDialog"
import { getSiteID } from "../../../restClient"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 14,
    height: 14,
  },
  smallIcon: {
    fontSize: 14,
  },
}))

function CreateVariantDialog({ onChange, ...props }) {
  const classes = useStyles()
  const translate = useTranslate()
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("variants")
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    let finalValues = values
    if (has(values, "locale.en")) {
      finalValues["name"] = get(values, "locale.en.name", "")
      finalValues["code"] = get(values, "locale.en.code", "")
    }
    create(
      { payload: { data: finalValues } },
      {
        onSuccess: ({ data }) => {
          console.log(data)
          notify("ra.notification.created")
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("id", data.id)
          setShowDialog(false)
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create" classes={classes}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("resources.variants.new_title")}
      >
        <DialogTitle>{translate("resources.variants.new_title")}</DialogTitle>

        <FormWithRedirect
          initialValues={{
            site_id: getSiteID(),
          }}
          resource="variants"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => {
            return (
              <>
                <DialogContent>
                  <FormFieldDialog {...props} />
                </DialogContent>
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    </>
  )
}

export default CreateVariantDialog
