import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get, find } from "lodash";
import {
  ReferenceInput,
  SelectInput,
  useTranslate,
  FormDataConsumer,
  required,
} from "react-admin";
import { RaTextInput, RaBooleanInput } from "../../../components";
import { ROLE_USER } from "../../../utils";
import {
  DateInputFields,
  PromotionTypeFields,
  RequirementFields,
  AppliesToFields,
  CouponSettingFields,
  dataPromoType,
  BuyXGetYFields,
  CustomerTypeFields,
  PromotionRule,
  AdditionalDiscountFields,
} from "./promotions";
import DescriptionTerms from "./DescriptionTerms";
// import moment from "moment";
import momentTimeZone from "moment-timezone";
import { rawTimeZone } from "../../../components/timezone";
import { dataPromotionRule } from "./promotions/utils";

const FormFields = (props) => {
  const { permissions, isCreate, updateField, websites } = props;
  const translate = useTranslate();

  const handleOpen = (name) => {
    if (name === "generate") {
    } else if (name === "view") {
    } else if (name === "download") {
    }
  };
  const onBlur = (e, formData) => {
    if (e.target.value !== get(formData, "website_id")) {
      const website = find(websites, function (o) {
        return o.id === e.target.value;
      });
      const timeZone = get(website, "locale.timezone", "");
      if (timeZone !== "") {
        const getRawTime = find(rawTimeZone, function (io) {
          return io.name === timeZone;
        });
        const myFormatED = `ddd MMM DD YYYY, hh:mm:ss`;
        const myFormatDefault = `ddd MMM DD YYYY 23:59:59`;
        const myFormatDefault1 = `ddd MMM DD YYYY 00:00:00`;
        const myTextStartDate = get(formData, "start_date").format(
          myFormatDefault1
        );
        const myTextEndDate = get(formData, "end_date").format(myFormatDefault);
        const myDateSD = momentTimeZone.tz(
          `${myTextStartDate}  GMT${getRawTime.rawTFormat}`,
          myFormatED,
          `${getRawTime.name}`
        );
        const myDateED = momentTimeZone.tz(
          `${myTextEndDate}  GMT${getRawTime.rawTFormat}`,
          myFormatED,
          `${getRawTime.name}`
        );
        updateField("start_date", myDateSD);
        updateField("end_date", myDateED);
      }
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData }) => {
          const type = get(formData, "type", "");
          const promotionRule = get(formData, "promotion_rule", "");

          return (
            <Fragment>
              {permissions === ROLE_USER.super_admin && isCreate && (
                <ReferenceInput
                  source='website_id'
                  reference='websites'
                  label={"resources.promotions.fields.website_id"}
                  validate={required()}
                  onChange={(e) => onBlur(e, formData)}
                >
                  <SelectInput optionText='name' style={{ width: 256 }} />
                </ReferenceInput>
              )}

              <RaTextInput
                source='name'
                label='resources.promotions.fields.name'
                style={{ width: 400, marginTop: 0 }}
                validate={required()}
              />

              <DateInputFields {...props} formValues={formData} />
              <RaBooleanInput
                source='require_coupon_code'
                label='resources.promotions.fields.need_coupon'
              />
              <PromotionRule
                {...props}
                translate={translate}
                formData={formData}
              />
              <PromotionTypeFields
                {...props}
                translate={translate}
                formData={formData}
              />
              {promotionRule === dataPromotionRule[0].id &&
                type === dataPromoType[0].id && (
                  <AppliesToFields
                    {...props}
                    sourceParent='percentage'
                    translate={translate}
                    formData={formData}
                  />
                )}
              {promotionRule === dataPromotionRule[0].id &&
                type === dataPromoType[4].id && (
                  <AppliesToFields
                    {...props}
                    sourceParent='free_shipping'
                    translate={translate}
                    formData={formData}
                  />
                )}
              {promotionRule === dataPromotionRule[0].id &&
                type === dataPromoType[1].id && (
                  <AppliesToFields
                    {...props}
                    sourceParent='amount'
                    translate={translate}
                    formData={formData}
                  />
                )}
              {promotionRule === dataPromotionRule[1].id &&
                type === dataPromoType[3].id && (
                  <AppliesToFields
                    {...props}
                    sourceParent='fixed_price'
                    translate={translate}
                    formData={formData}
                  />
                )}
              {type === dataPromoType[2].id && formData.website_id && (
                <BuyXGetYFields
                  {...props}
                  translate={translate}
                  formData={formData}
                />
              )}
              {type === dataPromoType[0].id && (
                <AdditionalDiscountFields
                  {...props}
                  source='criteria.percentage.additional_discount'
                  sourceParent='percentage.additional_discount'
                  translate={translate}
                  formData={formData}
                />
              )}
              {type === dataPromoType[1].id && (
                <AdditionalDiscountFields
                  {...props}
                  source='criteria.amount.additional_discount'
                  sourceParent='amount.additional_discount'
                  translate={translate}
                  formData={formData}
                />
              )}

              {type === dataPromoType[0].id && (
                <RequirementFields
                  {...props}
                  source='criteria.percentage.minimum_order_amount'
                  translate={translate}
                  formData={formData}
                  sourceMoq='criteria.percentage.minimum_order_qty'
                />
              )}
              {type === dataPromoType[1].id && (
                <RequirementFields
                  {...props}
                  source='criteria.amount.minimum_order_amount'
                  translate={translate}
                  formData={formData}
                  sourceMoq='criteria.amount.minimum_order_qty'
                />
              )}
              {type === dataPromoType[4].id && (
                <RequirementFields
                  {...props}
                  source='criteria.free_shipping.minimum_order_amount'
                  translate={translate}
                  formData={formData}
                  sourceMoq='criteria.free_shipping.minimum_order_qty'
                />
              )}
              <CustomerTypeFields
                {...props}
                translate={translate}
                formData={formData}
              />
              {get(formData, "require_coupon_code", false) && (
                <CouponSettingFields
                  {...props}
                  translate={translate}
                  handleOpen={handleOpen}
                  formData={formData}
                />
              )}
            </Fragment>
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          const website_id = get(formData, "website_id", "0");
          if (website_id === "0") {
            return <div />;
          }
          return (
            <DescriptionTerms
              {...props}
              translate={translate}
              formData={formData}
            />
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
};

export default FormFields;
