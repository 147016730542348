import React from "react";
import { PaperCard } from "./PaperCard";
import { Box } from "rebass";
import { RadioButtonGroupInput } from "react-admin";
import { dataPromotionRule } from "./utils";
const PromotionRule = (props) => {
  const { translate, formData } = props;
  const onChange = (e) => {
    if (e === "Product-based" && formData) {
      const myType = formData.type;
      if (
        myType !== "Percentage" &&
        myType !== "Amount" &&
        myType !== "Free Shipping"
      ) {
        props.updateField("type", "Percentage");
      }
    }
  };
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.promotion_rule")}
        elevation={3}
      >
        <RadioButtonGroupInput
          source='promotion_rule'
          label={""}
          choices={dataPromotionRule}
          onChange={(e) => onChange(e)}
        />
      </PaperCard>
    </Box>
  );
};

export default PromotionRule;
