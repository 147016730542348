import React from "react";
import moment from "moment";
import { get } from "lodash";
import { TextField, SearchInput, downloadCSV } from "react-admin";
// SelectInput, ReferenceInput, // ReferenceField,
import { Filter, BetterList } from "../../components/ra-list";
import { getSiteID } from "../../restClient";
import { ListCustomerAction } from "./components";
import jsonExport from "jsonexport/dist";
const FullName = ({ record }) => {
  const firstName = get(record, "first_name", "");
  const lastName = get(record, "last_name", "");
  return (
    <span>
      {firstName} {lastName}
    </span>
  );
};

const CreateOnField = ({ record }) => {
  const createdOn = moment(record.created_at).format("DD-MMM-YYYY HH:mm");
  return <span>{createdOn}</span>;
};

const exporter = (customers) => {
  const customersForExport = customers.map((post) => {
    const params = {
      ID: post.id,
      Name: get(post, "first_name", "") + " " + get(post, "last_name", ""),
      Gender: get(post, "gender", ""),
      DoB: get(post, "birth_date", ""),
      Email: get(post, "email", ""),
      Mobile: get(post, "phone", ""),
      Subscribed: get(post, "marketing_preference", false),
      "Created On": get(post, "created_at", false),
    };
    return params;
  });
  jsonExport(
    customersForExport,
    {
      headers: [
        "ID",
        "Name",
        "Gender",
        "DoB",
        "Email",
        "Mobile",
        "Subscribed",
        "Created On",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "customers"); // download as 'posts.csv` file
    }
  );
};
//export CSV columns ID, Name, Gender, DoB, Email, Mobile, Subscribed, Created On
const CustomerFilter = (props) => (
  <Filter {...props}>
    {/* <ReferenceInput
      label=''
      source='website_id'
      reference='websites'
      alwaysOn
      InputLabelProps={{
        shrink: true,
      }}
      style={{ top: -10, position: "absolute", marginLeft: 180 }}
    >
      <SelectInput optionText='name' />
    </ReferenceInput> */}
    <SearchInput source='search' alwaysOn />
  </Filter>
);

const CustomerList = (props) => {
  return (
    <BetterList
      {...props}
      actions={<ListCustomerAction title='resources.customers.name' />}
      filters={<CustomerFilter />}
      filter={{ site_id: getSiteID() }}
      rowClick={"show"}
      sort={{ field: "created_at", order: "DESC" }}
      //   exporter={false}
      exporter={exporter}
    >
      <FullName source='first_name' label={"resources.customers.fields.name"} />
      <TextField source='email' label={"resources.customers.fields.email"} />
      <TextField source='phone' label={"resources.customers.fields.mobile"} />
      <TextField source='gender' label={"resources.customers.fields.gender"} />
      <CreateOnField
        source={"created_at"}
        label={"resources.customers.fields.created_at"}
      />
      {/* <ButtonEditView {...props} /> */}
    </BetterList>
  );
};
export default CustomerList;
