import React, { useState } from "react"
import { Box, Text } from "rebass"
import { useTranslate } from "react-admin"
import DualListBox from "react-dual-listbox"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"

import "react-dual-listbox/lib/react-dual-listbox.css"
import "./styles.css"

export const Attributes = ({ formData, myOptions, ...rest }) => {
  const translate = useTranslate()

  const [selected, setSelected] = useState(formData.attributes)
  const handleChange = (selected) => {
    setSelected(selected)
    rest.updateField("attributes", selected)
  }
  return (
    <Box width={[1]} pt={[3]}>
      <Text pb={[2]}>
        {translate("resources.attributeSet.fields.choose_atributes")}
      </Text>
      <DualListBox
        name={"attributes"}
        options={myOptions}
        selected={selected}
        onChange={handleChange}
        preserveSelectOrder
        showOrderButtons
        icons={{
          moveLeft: <NavigateBeforeIcon />,
          moveAllLeft: [
            <NavigateBeforeIcon key={0} />,
            <NavigateBeforeIcon key={1} style={{ marginLeft: -15 }} />,
          ],
          moveRight: <NavigateNextIcon />,
          moveAllRight: [
            <NavigateNextIcon key={0} />,
            <NavigateNextIcon key={1} style={{ marginLeft: -15 }} />,
          ],
          moveDown: <ExpandMoreIcon />,
          moveUp: <ExpandLessIcon />,
        }}
      />
    </Box>
  )
}
