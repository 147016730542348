import React from "react";
import { TextField } from "react-admin";
import { BetterList, ButtonEditView, ListAction } from "../../components";

export const CollectionsList = (props) => (
  <BetterList
    {...props}
    actions={<ListAction title={`resources.collections.name`} />}
  >
    <TextField source='name' />
    <ButtonEditView {...props} />
  </BetterList>
);
