import React, { useState } from "react";
import { get, uniq, map, filter } from "lodash";
import { ReactSortable } from "react-sortablejs";
import { AutoCompleteProduct } from "../../../components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box } from "rebass";
import { red, grey } from "@material-ui/core/colors";
import { useQuery } from "react-query";
import LinearProgress from "@material-ui/core/LinearProgress";
import { RestApi } from "../../../restClient";
const cacheTimeMinute = 1000 * 60 * 30;
const getListProducts = (list) => {
  const myList = map(list, function (o) {
    return { id: o, name: o };
  });
  return myList;
};

const ItemList = ({ item, onDelete }) => {
  const { data, isLoading } = useQuery(
    ["products", item.id],
    () => RestApi(`/products/${item.id}`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return (
      <Box width={[1]} py={[1]}>
        <LinearProgress color='primary' />
      </Box>
    );
  }
  const name = `${get(data, "name", "")} - ${get(data, "slug", "")}`;
  return (
    <ListItem
      button
      key={item.id}
      style={{ borderBottom: `1px solid ${grey[500]}` }}
    >
      <ListItemText primary={name} />
      <ListItemIcon onClick={() => onDelete(item.id)}>
        <DeleteIcon style={{ color: red[900] }} />
      </ListItemIcon>
    </ListItem>
  );
};

const ListProductCollections = (props) => {
  const { formData } = props;
  const myList = get(formData, "products", []);
  const [value, setValue] = useState("");

  const [suggestions, setSuggestions] = useState([]);
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const getItem = (product, formData) => {
    const oldList =
      get(formData, "products", []) === null
        ? []
        : get(formData, "products", []);
    if (product && product.id) {
      oldList.push(product.id);
      const newList = uniq(oldList);
      props.updateField("products", newList);
    }
  };

  const setList = (v) => {
    const newList = map(v, function (ii) {
      return ii.id;
    });
    props.updateField("products", newList);
  };

  const onDelete = (id) => {
    const list = filter(myList, function (oid) {
      return oid !== id;
    });
    props.updateField("products", list);
  };

  const listku = getListProducts(myList);
  return (
    <Box width={[3 / 4]} py={[2]}>
      <AutoCompleteProduct
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        value={value}
        setSuggestions={setSuggestions}
        suggestions={suggestions}
        setValue={setValue}
        getItem={(product) => getItem(product, formData)}
      />
      <Card variant='outlined' style={{ marginTop: 10 }}>
        <CardContent>
          <List component='nav' aria-label='main mailbox folders'>
            <ReactSortable list={listku} setList={setList}>
              {listku &&
                listku.length > 0 &&
                listku.map((item) => (
                  <ItemList item={item} key={item.id} onDelete={onDelete} />
                ))}
            </ReactSortable>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListProductCollections;
