import React from "react"
//BooleanField
import { TextField, DateField } from "react-admin"
import { BetterList, ButtonEditView, ListAction } from "../../components"
export { create as AccountCreate, edit as AccountEdit } from "./Create"

export const AccountList = (props) => (
  <BetterList
    {...props}
    actions={<ListAction title="resources.accounts.name" />}
  >
    <TextField source="name" />
    {/* <BooleanField source="active" /> */}
    <DateField
      source="created_at"
      options={{
        day: "numeric",
        month: "short",
        year: "numeric",
      }}
    />
    <ButtonEditView {...props} />
  </BetterList>
)
