import React from "react";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { dataApplyTo } from "./utils";
import { RadioButtonGroupInput, useQueryWithStore } from "react-admin";
import { PaperCard } from "./PaperCard";
import { RaNumberInput } from "../../../../components";
import ProductFields from "./ProductFields";
import CategoryFields from "./CategoryFields";
const AdditionalDiscountFields = (props) => {
  const { translate, formData, source, sourceParent } = props;
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: `categories`,
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: {
        website_id: formData.website_id,
      },
    },
  });
  const appliesTo = get(formData, `${source}.applies_to`, "");
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.additional_discount")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"} mb={[2]}>
          <RaNumberInput
            style={{ width: 256 }}
            source={`${source}.minimum_order_amount`}
            label='resources.promotions.fields.min_order_amount_ad'
            notetext='resources.promotions.note_text.min_order_amount'
            defaultValue={0}
          />
          <RaNumberInput
            style={{ width: 256 }}
            source={`${source}.minimum_order_qty`}
            label='resources.promotions.fields.minimum_order_qty'
            notetext='resources.promotions.note_text.minimum_order_qty'
            defaultValue={0}
          />
          <RaNumberInput
            style={{ width: 256 }}
            source={`${source}.additional_discount_amount`}
            label='resources.promotions.fields.additional_discount_amount'
            notetext='resources.promotions.note_text.additional_discount_amount'
            defaultValue={0}
          />
        </Flex>
        <PaperCard
          title={translate("resources.promotions.labels.ad_applies_to")}
          elevation={1}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <RadioButtonGroupInput
              row={false}
              label={""}
              source={`${source}.applies_to`}
              choices={dataApplyTo}
            />
            {appliesTo === dataApplyTo[0].id &&
              (loading ? (
                <Box width={[1]} p={[2]}>
                  <Text textAlign={"center"} fontSize={[2]}>
                    {translate(`resources.categories.messages.loading`)}
                  </Text>
                </Box>
              ) : formData.website_id ? (
                <CategoryFields
                  {...props}
                  categories={data}
                  categorySource={sourceParent}
                />
              ) : (
                <Box width={[1]} p={[2]}>
                  <Text textAlign={"center"} fontSize={[2]}>
                    {translate(
                      `resources.promotions.note_text.website_id_not_found`
                    )}
                  </Text>
                </Box>
              ))}
            {appliesTo === dataApplyTo[1].id && (
              <ProductFields {...props} productSource={sourceParent} />
            )}
          </Flex>
        </PaperCard>
      </PaperCard>
    </Box>
  );
};

export default AdditionalDiscountFields;
