import React, { useEffect } from "react";
import { Flex, Text, Box } from "rebass";
import { Link } from "react-router-dom";
import {
  useTranslate,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { Button } from "@material-ui/core";
import {
  RaTextInput,
  RaSelectArrayChipInput,
  RaBooleanInput,
} from "../../../components";
import { get, find } from "lodash";
import momentTimeZone from "moment-timezone";
import { ROLE_USER } from "../../../utils";
import { getSiteID } from "../../../restClient";
import DateInputFields from "./DateInputFields";
import { rawTimeZone } from "../../../components/timezone";
import { Paper } from "@material-ui/core";
export const FormFields = (props) => {
  const { updateField, formValues, location, permissions, websites } = props;
  const translate = useTranslate();
  const defaultSiteID = getSiteID();
  const SiteID =
    formValues.site_id === getSiteID() ? formValues.site_id : getSiteID();
  useEffect(() => {
    if (
      formValues.site_id !== defaultSiteID &&
      permissions === ROLE_USER.super_admin
    ) {
      updateField("site_id", SiteID);
    }
  }, [formValues, SiteID, permissions, defaultSiteID, updateField]);
  if (location.pathname === "/contents/create/page") {
    return <div />;
  }
  // const onBlur = (e, formData) => {
  //   if (e.target.value !== get(formData, "website_id")) {
  //     const website = find(websites, function (o) {
  //       return o.id === e.target.value;
  //     });
  //     const timeZone = get(website, "locale.timezone", "");
  //     if (timeZone !== "") {
  //       const getRawTime = find(rawTimeZone, function (io) {
  //         return io.name === timeZone;
  //       });
  //       const myFormat = `ddd MMM DD YYYY, hh:mm:ss`;
  //       const myTextStartDate = get(formData, "start_date").format(myFormat);
  //       const myTextEndDate = get(formData, "end_date").format(myFormat);
  //       const myDateSD = moment.tz(
  //         `${myTextStartDate}  GMT${getRawTime.rawTFormat}`,
  //         myFormat,
  //         `${getRawTime.name}`
  //       );
  //       const myDateED = moment.tz(
  //         `${myTextEndDate}  GMT${getRawTime.rawTFormat}`,
  //         myFormat,
  //         `${getRawTime.name}`
  //       );
  //       updateField("start_date", myDateSD);
  //       updateField("end_date", myDateED);
  //     }
  //   }
  // };

  const onBlur = (e, formData) => {
    if (e.target.value !== get(formData, "website_id")) {
      const website = find(websites, function (o) {
        return o.id === e.target.value;
      });
      const timeZone = get(website, "locale.timezone", "");
      if (timeZone !== "") {
        const getRawTime = find(rawTimeZone, function (io) {
          return io.name === timeZone;
        });
        const myFormatED = `ddd MMM DD YYYY, hh:mm:ss`;
        const myFormatDefault = `ddd MMM DD YYYY 23:59:59`;
        const myFormatDefault1 = `ddd MMM DD YYYY 00:00:00`;
        const myTextStartDate = get(formData, "start_date").format(
          myFormatDefault1
        );
        const myTextEndDate = get(formData, "end_date").format(myFormatDefault);
        const myDateSD = momentTimeZone.tz(
          `${myTextStartDate}  GMT${getRawTime.rawTFormat}`,
          myFormatED,
          `${getRawTime.name}`
        );
        const myDateED = momentTimeZone.tz(
          `${myTextEndDate}  GMT${getRawTime.rawTFormat}`,
          myFormatED,
          `${getRawTime.name}`
        );
        updateField("start_date", myDateSD);
        updateField("end_date", myDateED);
      }
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"} p={[3]}>
      {permissions === ROLE_USER.super_admin && (
        <ReferenceInput
          label={"resources.contents.fields.website"}
          source='website_id'
          reference='websites'
          filter={{ site_id: SiteID }}
          style={{ width: 320 }}
          onChange={(e) => onBlur(e, formValues)}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
      )}
      <RaBooleanInput
        source={"active"}
        label={"resources.contents.fields.active"}
      />
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Text width={"80px"} pr={[3]}>
          {translate("resources.contents.fields.tags")}
        </Text>
        <RaSelectArrayChipInput
          label={"resources.filters.fields.empty"}
          source='tags'
          updateField={updateField}
          fullWidth={true}
          formData={formValues}
          notetext={"resources.contents.note_text.tags"}
        />
      </Flex>
      <RaTextInput
        source='title'
        label={"resources.contents.fields.title"}
        fullWidth
      />
      <DateInputFields {...props} />
      {/* <DateInput
        source='start_date'
        label={"resources.contents.fields.start_date"}
        style={{ width: 256 }}
        parse={(v) => moment(v).toISOString()}
      />
      <DateInput
        source='end_date'
        label={"resources.contents.fields.end_date"}
        style={{ width: 256 }}
        parse={(v) => moment(v).toISOString()}
      /> */}
      <RaTextInput
        source='slug'
        label={"resources.contents.fields.slug"}
        fullWidth
        notetext={"resources.contents.note_text.slug"}
      />
      <Box py={[2]} mb={[2]}>
        <Button
          to={`${location.pathname}/page`}
          component={Link}
          variant='contained'
          color='primary'
          style={{ borderRadius: 16, textTransform: "capitalize" }}
        >
          {translate("resources.contents.fields.edit_page_design")}
        </Button>
      </Box>
      <Paper style={{ padding: 16 }} elevation={3}>
        <Box width={[1]} py={[1]}>
          <Text fontWeight={"bold"} fontSize={[2]}>
            {translate("resources.categories.label.metadata")}
          </Text>
          <TextInput
            source='metadata_title'
            label='resources.categories.label.metadata_title'
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
          />
          <TextInput
            source='metadata_keywords'
            label='resources.categories.label.metadata_keywords'
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
          />
          <TextInput
            source='metadata_description'
            label='resources.categories.label.metadata_description'
            fullWidth={true}
            multiline={true}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Paper>
    </Flex>
  );
};
