import React, { useState } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { useTranslate } from "react-admin";
import { Dialog, Button } from "@material-ui/core";
import { useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from "../../../../components";
import FormFields from "./FormFields";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { RestApiCRUDString } from "../../../../restClient";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateMemo = (props) => {
  const { record, onRefreshList } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    open: false,
    message: "",
    variant: "error",
  });
  const [state, setState] = useState({
    amount_paid: get(record, "amounts.total", 0),
    reason: "",
    remarks: "",
    adjustment: 0,
    shipping_fee: 0,
    admin_fee: 0,
    total: 0,
  });
  const [show, setShow] = React.useState(false);
  const indexParent = 1;

  const onHandleSnackBar = (k, v) => {
    setSnackBarMessage({
      ...snackBarMessage,
      [k]: v,
    });
  };

  const onSubmit = () => {
    if (state.reason === "") {
      onHandleSnackBar("message", translate("resources.errors.reason_empty"));
      onHandleSnackBar("variant", "error");
      onHandleSnackBar("open", true);
    } else if (state.amount_paid === 0) {
      onHandleSnackBar("message", translate("resources.errors.amount_paid"));
      onHandleSnackBar("variant", "error");
      onHandleSnackBar("open", true);
    } else {
      setLoading(true);
      const params = {
        ...state,
        total: state.amount_paid - (state.shipping_fee - state.admin_fee),
      };

      RestApiCRUDString(`/orders/${record.id}/creditMemos`, "POST", params)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          setLoading(false);
          // console.log("create memo", res);
          if (res) {
            if (res.id) {
              setShow(false);
              onRefreshList();
              notify("ra.notification.created", "success", {
                smart_count: 1,
              });
            } else {
              onHandleSnackBar(
                "message",
                get(
                  res,
                  "message",
                  translate("resources.errors.please_try_again")
                )
              );
              onHandleSnackBar("variant", "error");
              onHandleSnackBar("open", true);
            }
          } else {
            onHandleSnackBar(
              "message",
              translate("resources.errors.please_try_again")
            );
            onHandleSnackBar("variant", "error");
            onHandleSnackBar("open", true);
          }
          setShow(false);
        })
        .catch((e) => {
          // console.log(e);
          setLoading(false);
          setShow(false);
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
        });
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Button onClick={() => setShow(true)} color={"primary"}>
        {translate("resources.buttons.create_memo")}
      </Button>
      <Dialog
        onClose={() => setShow(false)}
        aria-labelledby={`customized-dialog-title-${indexParent}`}
        open={show}
        scroll={"paper"}
      >
        <DialogTitle
          id={`customized-dialog-title-${indexParent}`}
          onClose={() => setShow(false)}
        >
          {translate("resources.orders.labels.create_credit_memo")}
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 600 }}>
          <FormFields
            {...props}
            state={state}
            setState={setState}
            translate={translate}
          />
        </DialogContent>
        <DialogActions>
          <Flex width={[1]}>
            <Button
              onClick={() => onSubmit()}
              color={"primary"}
              variant={"contained"}
              style={{ borderRadius: 16, marginRight: 16 }}
            >
              <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
                {loading ? (
                  <CircularProgress size={24} color={"secondary"} />
                ) : (
                  translate("resources.buttons.save")
                )}
              </Flex>
            </Button>
          </Flex>
        </DialogActions>
        <Snackbar
          open={snackBarMessage.open}
          autoHideDuration={6000}
          onClose={() => onHandleSnackBar("open", false)}
        >
          <Alert
            onClose={() => onHandleSnackBar("open", false)}
            severity={snackBarMessage.variant}
          >
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </Flex>
  );
};

export default CreateMemo;
