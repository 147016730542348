import React from "react";
import SubMenu from "./SubMenu";
import MenuItemLink from "./MenuItemLink";
import {
  MenuOrders,
  MenuProducts,
  MenuMarketing,
  MenuContent,
  MenuAdmin,
  MenuOperationOrder,
  MenuStores,
} from "./contentmenus";
import {
  StarIcon,
  BagIcon,
  SettingsIcon,
  ViewIcon,
  UserIcon,
  User1Icon,
  BadgeIcon,
  Chart8,
} from "../icon_svg";
import { DEFAULT_BG_SIDEBAR } from "../../utils";

export const SuperAdminMenu = ({ onMenuClick, ...rest }) => {
  return (
    <div style={{ backgroundColor: DEFAULT_BG_SIDEBAR }}>
      <MenuOrders onMenuClick={onMenuClick} {...rest} />
      <MenuProducts onMenuClick={onMenuClick} {...rest} />
      <MenuMarketing onMenuClick={onMenuClick} {...rest} />
      <MenuContent onMenuClick={onMenuClick} {...rest} />
      <MenuStores onMenuClick={onMenuClick} {...rest} />
      <MenuAdmin onMenuClick={onMenuClick} {...rest} />
    </div>
  );
};

export const OperationMenu = ({ onMenuClick, ...rest }) => {
  return (
    <div style={{ backgroundColor: DEFAULT_BG_SIDEBAR }}>
      <MenuOperationOrder onMenuClick={onMenuClick} {...rest} />
      {/* <MenuProducts onMenuClick={onMenuClick} {...rest} />
      <MenuMarketing onMenuClick={onMenuClick} {...rest} />
      <MenuContent onMenuClick={onMenuClick} {...rest} />
      <MenuAdmin onMenuClick={onMenuClick} {...rest} isHide={true} /> */}
    </div>
  );
};

export const AccountAdminMenu = ({
  onMenuClick,
  handleToggle,
  menuSettings,
  open,
  translate,
}) => {
  return (
    <div style={{ backgroundColor: "#696969" }}>
      <MenuItemLink
        to={`/clients`}
        primaryText={translate(`resources.menus.clients`, {
          smart_count: 2,
        })}
        leftIcon={<StarIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/catalogues`}
        primaryText={translate(`resources.menus.catalogues`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/merchants`}
        primaryText={translate(`resources.menus.merchants`, {
          smart_count: 2,
        })}
        leftIcon={<BagIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/members`}
        primaryText={translate(`resources.menus.members`, {
          smart_count: 2,
        })}
        leftIcon={<User1Icon />}
        onClick={onMenuClick}
      />
      <SubMenu
        handleToggle={() => handleToggle()}
        isOpen={menuSettings}
        sidebarIsOpen={open}
        name='resources.menus.admin'
        icon={<SettingsIcon />}
      >
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.menus.users`, {
            smart_count: 2,
          })}
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
    </div>
  );
};
export const AccountClientMenu = ({ onMenuClick, translate }) => {
  return (
    <div style={{ backgroundColor: "#696969" }}>
      <MenuItemLink
        to={`/`}
        primaryText={translate(`resources.menus.dashboard`, {
          smart_count: 2,
        })}
        leftIcon={<Chart8 />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/programmes`}
        primaryText={translate(`resources.menus.programmes`, {
          smart_count: 2,
        })}
        leftIcon={<BadgeIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/catalogues`}
        primaryText={translate(`resources.menus.catalogues`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={onMenuClick}
      />

      <MenuItemLink
        to={`/members`}
        primaryText={translate(`resources.menus.members`, {
          smart_count: 2,
        })}
        leftIcon={<User1Icon />}
        onClick={onMenuClick}
      />
    </div>
  );
};
