import React, { Component } from "react";
import { Flex } from "rebass";
import { isEqual, isArray } from "lodash";
import { ArrayInput } from "react-admin";
import { RaTextInput, RaNumberInput, RaBooleanInput } from "../";
import TableFormIteratorVariant from "../TableFormIteratorVariant";
import VariantDialog from "./VariantDialog";
import { getVariants } from "../utils";

class FormVariants extends Component {
  constructor(props) {
    super(props);
    let newVariant = props.formData.variant;
    if (!isArray(newVariant.options)) {
      newVariant["options"] = [newVariant.options];
      props.updateField("variant", newVariant);
    }
    this.state = {
      oldVariant: newVariant,
    };
  }
  onCancelOptions = (items) => {
    if (this.state.oldVariant !== undefined) {
      this.props.updateField("variant", this.state.oldVariant);
    }
  };
  onSaveOptions = () => {
    let value = [];
    let count = 0;
    const { formData } = this.props;
    const newValues = getVariants(
      formData.variant.options,
      formData.price,
      formData.brand,
      formData.slug
    );
    formData.variant.options.map((item, index) => {
      const contentValues = item.values.filter(
        (f) => !this.state.oldVariant.options[index].values.includes(f)
      );
      if (contentValues.length > 0) {
        contentValues.map((item) => {
          value[count] = item;
          count++;
          return null;
        });
      }
      return null;
    });
    let newVariant = [];
    let count1 = 0;
    value.map((item, index1) => {
      const nextValue = newValues.filter((f) => {
        const name = f.name;
        return name.includes(item);
      });
      if (nextValue.length > 0) {
        nextValue.map((item) => {
          newVariant[count1] = item;
          count1++;
          return null;
        });
      }
      return null;
    });
    if (newVariant.length > 0) {
      newVariant.map((item) => {
        const contentVariants = formData.variant.variants.filter((f) => {
          const itemName = item.name;
          const fieldName = f.name;
          return isEqual(itemName.toLowerCase(), fieldName.toLowerCase());
        });
        if (contentVariants.length === 0) {
          formData.variant.variants.push(item);
        }
        return null;
      });
      this.setState({
        oldVariant: formData.variant,
      });
    }
  };
  render() {
    const { formData, translate } = this.props;
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <VariantDialog
          {...this.props}
          dataOptions={this.state.oldVariant}
          onSave={() => this.onSaveOptions()}
          variant={formData.variant}
          onCancel={(items) => this.onCancelOptions(items)}
          translate={translate}
        />
        <ArrayInput source={`variant.variants`} label="">
          <TableFormIteratorVariant
            disableRemove
            disableAdd
            titleAdd={"resources.buttons.add_variant"}
          >
            <RaTextInput
              source={`name`}
              label={"resources.products.fields.sku"}
            />
            <RaTextInput
              source={`sku`}
              label={"resources.products.fields.sku"}
            />
            {/* <RaTextInput
              source={`code`}
              label={"resources.products.fields.code"}
            /> */}
            <RaNumberInput
              source={`threshold`}
              label={"resources.products.fields.threshold"}
              style={{ width: 80 }}
            />
            <RaNumberInput
              source={`price`}
              label={"resources.products.fields.price"}
              style={{ width: 180 }}
            />
            <RaBooleanInput
              source={`active`}
              label={"resources.products.fields.active"}
            />
          </TableFormIteratorVariant>
        </ArrayInput>
      </Flex>
    );
  }
}

export default FormVariants;
