import React, { Fragment } from "react";
import { Flex, Text } from "rebass";
import { get, has } from "lodash";
import Box from "@material-ui/core/Box";
import { Field } from "react-final-form";
import moment from "moment";
import { SelectInput, ReferenceInput, required, DateInput } from "react-admin";
import { RaLocaleTabs, ROLE_USER } from "./";
import { getSiteID, dataProvider } from "../../../restClient";
import EditSearchChipInput from "./EditSearchChipInput";
import { TextFieldInput, BooleanFieldInput } from "./variants";
import {
  // WhenFieldChanges,
  useStyles,
  DataProductType,
  ContentItem,
  ShippingFields,
} from "./info";
const dateParser = (v) => {
  // v is a string of "YYYY-MM-DD" format
  const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
  if (match === null) return;
  const d = moment(v).toISOString();

  return d;
};

const InfoFields = (props) => {
  const { permissions, updateField, translate, formValues, record } = props;
  const classes = useStyles();
  const listSearchAble = get(formValues, "searchable.colors", []);
  const listTags = get(formValues, "tags", []);
  const onChangeAttribute = (e) => {
    dataProvider
      .getOne("attributeSets", { id: e.target.value })
      .then((items) => {
        if (items && items.data) {
          const { data } = items;
          let myObjAttributes = {};
          const list = get(data, "attributes", []);
          list.map((item) => {
            if (has(formValues, `attributes.a-${item}.id`)) {
              if (get(formValues, `attributes.a-${item}.value`, "") === "") {
                myObjAttributes["a-" + item] = get(
                  record,
                  `attributes.a-${item}`
                );
              } else {
                myObjAttributes["a-" + item] = get(
                  formValues,
                  `attributes.a-${item}`
                );
              }
            } else if (has(record, `attributes.a-${item}.id`)) {
              myObjAttributes["a-" + item] = get(
                record,
                `attributes.a-${item}`
              );
            } else {
              myObjAttributes["a-" + item] = {
                id: item,
                description: "",
                value: "",
                switch: false,
              };
            }

            return "";
          });
          updateField("attributes", myObjAttributes);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const SiteID =
    formValues.site_id === getSiteID() ? formValues.site_id : getSiteID();
  if (
    formValues.site_id !== getSiteID() &&
    permissions === ROLE_USER.super_admin
  ) {
    props.updateField("site_id", SiteID);
  }
  return (
    <Flex width={[1]} flexDirection={"column"}>
      {/* <WhenFieldChanges field="attribute_set_id" set="attributes" to={{}} /> */}
      <Fragment>
        <Box p={2}>
          <RaLocaleTabs siteId={formValues.site_id} nameTab='tab-product-info'>
            <ContentItem {...props} />
          </RaLocaleTabs>
          <Flex
            width={[1]}
            flexDirection={["column", "row"]}
            justifyContent={"space-between"}
            p={[3]}
          >
            <Box
              width={[1]}
              mr={1}
              boxShadow={3}
              className={classes.paper}
              display='flex'
              flexDirection='column'
            >
              <TextFieldInput
                source={"slug"}
                value={formValues.slug}
                label={translate("resources.products.fields.slug")}
                required={true}
                updateField={updateField}
              />
              <BooleanFieldInput
                source={"active"}
                label={translate("resources.products.fields.active")}
                updateField={updateField}
                value={formValues.active}
              />
              <SelectInput
                source='product_type'
                label='resources.products.fields.type'
                choices={DataProductType}
                margin='dense'
                style={{ width: 180 }}
                validate={[required()]}
              />
              <ReferenceInput
                label='resources.products.fields.attributeSet'
                source='attribute_set_id'
                reference='attributeSets'
                onChange={onChangeAttribute}
              >
                <SelectInput optionText='name' style={{ width: 256 }} />
              </ReferenceInput>
              <TextFieldInput
                source={"sort_order"}
                value={formValues.sort_order}
                label={translate("resources.products.fields.sort_order")}
                type={"number"}
                updateField={updateField}
                style={{ width: 256 }}
              />
            </Box>
            <Box
              boxShadow={3}
              className={classes.paper}
              ml={1}
              display='flex'
              flexDirection='column'
            >
              <TextFieldInput
                source={"price"}
                value={formValues.price}
                label={translate("resources.products.fields.price")}
                type={"number"}
                updateField={updateField}
                style={{ width: 256 }}
              />
              <TextFieldInput
                source={"brand"}
                value={formValues.brand}
                label={translate("resources.products.fields.brand")}
                updateField={updateField}
                style={{ width: 300 }}
              />
              {/* <TextFieldInput
                source={"shipping.weight"}
                value={get(formValues, "shipping.weight", 0)}
                label={translate("resources.products.fields.shipping_weight")}
                type={"number"}
                updateField={updateField}
                style={{ width: 256 }}
              />
              <TextFieldInput
                source={"unit_price"}
                value={get(formValues, "unit_price", 0)}
                label={translate("resources.products.fields.unit_price")}
                type={"number"}
                updateField={updateField}
                style={{ width: 256 }}
              /> */}
              <DateInput
                source='launch_date'
                label='resources.products.fields.launch_date'
                style={{ width: 256 }}
                parse={dateParser}
              />
            </Box>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={["column", "row"]}
            justifyContent={"space-between"}
            p={[3]}
          >
            <Box
              width={[1]}
              // mr={1}
              boxShadow={3}
              className={classes.paper}
              display='flex'
              flexDirection='column'
            >
              <Box width={[1]}>
                <Text fontWeight={"bold"} fontSize={[0]}>
                  {translate("resources.products.fields.searchable_colors")}
                </Text>
                <Field
                  label={""}
                  name={`searchable.colors`}
                  component={EditSearchChipInput}
                  dataOptions={listSearchAble}
                  updateField={updateField}
                />
                <Text fontSize={[0]}>
                  {translate("resources.products.note_text.searchable_colors")}
                </Text>
              </Box>
              <Box width={[1]} pt={[2]}>
                <Text fontWeight={"bold"} fontSize={[0]}>
                  {translate("resources.products.fields.tags")}
                </Text>
                <Field
                  label={""}
                  name={`tags`}
                  component={EditSearchChipInput}
                  dataOptions={listTags}
                  updateField={updateField}
                />
                {/* <Text fontSize={[0]}>
                        {translate(
                          "resources.products.note_text.searchable_colors"
                        )}
                      </Text> */}
              </Box>
            </Box>
            {/* <Flex width={["435px"]} /> */}
          </Flex>
        </Box>
        <ShippingFields {...props} classes={classes} />
      </Fragment>
    </Flex>
  );
};

export default InfoFields;
