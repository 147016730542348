import React from "react";
import { Admin, Resource } from "react-admin";
import { JssProvider } from "react-jss";
import authClient from "./authClient";
import LoginPage from "./auth/LoginPage";
import { themeMui } from "./themes";
import { AppLayout, AppLogoutButton } from "./components/app";
import translations from "./i18n";
import {
  AttributeEdit,
  AttributeCreate,
  AttributesList,
} from "./screens/attributes";
import { AttributeSetEdit, AttributeSetCreate } from "./screens/attributeSet";
import { AccountList, AccountCreate, AccountEdit } from "./screens/accounts";
import { UserList, UserCreate, UserEdit, UserShow } from "./screens/users";
import { SitesList, SiteEdit, SiteCreate } from "./screens/sites";
import { ContentList, ContentCreate, ContentEdit } from "./screens/contents";
import { CategoriesMultiList } from "./screens/categories";
import { FiltersList, FiltersCreate } from "./screens/filters";
import { ProductList, ProductCreate, ProductEdit } from "./screens/products";
import {
  // PromotionsList,
  CreatePromotion,
  EditPromotion,
  ListPromotions,
} from "./screens/promotions";
import {
  PriceBookList,
  PriceBookCreate,
  PriceBookEdit,
} from "./screens/pricebook";
import { VariantsCreate, VariantsEdit, VariantsList } from "./screens/variants";
import { OrdersList, OrderShow } from "./screens/orders";
import { ReturnsList } from "./screens/returns";
import { WebsiteEdit } from "./screens/websites";
import { SourceEdit } from "./screens/sources";
import { SourceGroupsEdit } from "./screens/sourceGroups";
import {
  CollectionsList,
  CreateCollection,
  EditCollection,
} from "./screens/collections";
import { CustomerList } from "./screens/customers";
// import { FAQList, CreateFAQ, EditFAQ } from "./screens/faq"
import { generateClassName, ROLE_USER } from "./utils";
import customRoutes from "./customRoutes";
import { ReviewsList } from "./screens/reviews";
import { dataProvider } from "./restClient";
import { InventoriesList } from "./screens/inventories";
import { NoAccessPage } from "./components";
import { StoresList } from "./screens/stores";
import { StorePromotionsList } from "./screens/storePromotions";
import { StoreProductsList } from "./screens/storeProducts";
import { StoreTopSellingsList } from "./screens/topSellings";
// import { dataProvider } from "./utils/dataDummy"

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

export const isShowing = (permissions, type) => {
  let showing = false;
  if (permissions === ROLE_USER.super_admin) {
    return true;
  } else if (permissions === ROLE_USER.account_admin) {
    switch (type) {
      case "users":
        return true;
      default:
        return false;
    }
  } else if (permissions === ROLE_USER.client_admin) {
    switch (type) {
      default:
        return false;
    }
  } else if (permissions === ROLE_USER.operation) {
    switch (type) {
      case "orders":
        // if (basepath === "show") {
        //   return false;
        // }
        return true;

      default:
        return false;
    }
  }
  return showing;
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <JssProvider generateClassName={generateClassName}>
      <Admin
        layout={AppLayout}
        dataProvider={dataProvider}
        customRoutes={customRoutes}
        title='PlayitRight Admin'
        theme={themeMui}
        authProvider={authClient}
        i18nProvider={translations}
        loginPage={LoginPage}
        logoutButton={AppLogoutButton}
      >
        {(permissions) => {
          return [
            <Resource
              name='accounts'
              list={
                permissions === ROLE_USER.super_admin
                  ? AccountList
                  : NoAccessPage
              }
              create={
                permissions === ROLE_USER.super_admin
                  ? AccountCreate
                  : NoAccessPage
              }
              edit={
                permissions === ROLE_USER.super_admin
                  ? AccountEdit
                  : NoAccessPage
              }
            />,
            <Resource
              name='users'
              create={
                isShowing(permissions, "users") ? UserCreate : NoAccessPage
              }
              list={isShowing(permissions, "users") ? UserList : NoAccessPage}
              edit={isShowing(permissions, "users") ? UserEdit : NoAccessPage}
              show={isShowing(permissions, "users") ? UserShow : NoAccessPage}
            />,
            <Resource
              name='contents'
              list={
                isShowing(permissions, "contents") ? ContentList : NoAccessPage
              }
              edit={
                isShowing(permissions, "contents") ? ContentEdit : NoAccessPage
              }
              create={
                isShowing(permissions, "contents")
                  ? ContentCreate
                  : NoAccessPage
              }
            />,
            <Resource
              name='categories'
              // create={CategoriesCreate}
              list={
                isShowing(permissions, "categories")
                  ? CategoriesMultiList
                  : NoAccessPage
              }
            />,
            <Resource
              name='sites'
              list={isShowing(permissions, "sites") ? SitesList : NoAccessPage}
              edit={isShowing(permissions, "sites") ? SiteEdit : NoAccessPage}
              create={
                isShowing(permissions, "sites") ? SiteCreate : NoAccessPage
              }
            />,
            <Resource
              name='stores'
              list={
                isShowing(permissions, "stores") ? StoresList : NoAccessPage
              }
            />,
            <Resource
              name='storePromotions'
              list={
                isShowing(permissions, "storePromotions")
                  ? StorePromotionsList
                  : NoAccessPage
              }
            />,
            <Resource
              name='storeProducts'
              list={
                isShowing(permissions, "storeProducts")
                  ? StoreProductsList
                  : NoAccessPage
              }
            />,
            <Resource
              name='storeTopSellings'
              list={
                isShowing(permissions, "storeTopSellings")
                  ? StoreTopSellingsList
                  : NoAccessPage
              }
            />,

            <Resource
              name='attributeSets'
              create={
                isShowing(permissions, "attributeSets")
                  ? AttributeSetCreate
                  : NoAccessPage
              }
              edit={
                isShowing(permissions, "attributeSets")
                  ? AttributeSetEdit
                  : NoAccessPage
              }
            />,
            <Resource
              name='attributes'
              create={
                isShowing(permissions, "attributes")
                  ? AttributeCreate
                  : NoAccessPage
              }
              edit={
                isShowing(permissions, "attributes")
                  ? AttributeEdit
                  : NoAccessPage
              }
              list={
                isShowing(permissions, "attributes")
                  ? AttributesList
                  : NoAccessPage
              }
            />,
            <Resource
              name='products'
              list={
                isShowing(permissions, "products") ? ProductList : NoAccessPage
              }
              edit={
                isShowing(permissions, "products") ? ProductEdit : NoAccessPage
              }
              create={
                isShowing(permissions, "products")
                  ? ProductCreate
                  : NoAccessPage
              }
            />,
            <Resource
              name='promotions'
              list={
                isShowing(permissions, "promotions")
                  ? ListPromotions
                  : NoAccessPage
              }
              create={
                isShowing(permissions, "promotions")
                  ? CreatePromotion
                  : NoAccessPage
              }
              edit={
                isShowing(permissions, "promotions")
                  ? EditPromotion
                  : NoAccessPage
              }
            />,
            <Resource
              name='filters'
              list={
                isShowing(permissions, "filters") ? FiltersList : NoAccessPage
              }
              create={
                isShowing(permissions, "filters") ? FiltersCreate : NoAccessPage
              }
            />,
            <Resource
              name='inventories'
              list={
                isShowing(permissions, "inventories")
                  ? InventoriesList
                  : NoAccessPage
              }
            />,
            <Resource
              name='priceBooks'
              list={
                isShowing(permissions, "priceBooks")
                  ? PriceBookList
                  : NoAccessPage
              }
              create={
                isShowing(permissions, "priceBooks")
                  ? PriceBookCreate
                  : NoAccessPage
              }
              edit={
                isShowing(permissions, "priceBooks")
                  ? PriceBookEdit
                  : NoAccessPage
              }
            />,
            <Resource
              name='orders'
              list={
                isShowing(permissions, "orders") ? OrdersList : NoAccessPage
              }
              show={isShowing(permissions, "orders") ? OrderShow : NoAccessPage}
            />,
            <Resource
              name='returns'
              list={
                isShowing(permissions, "returns") ? ReturnsList : NoAccessPage
              }
            />,
            <Resource name='loyaltyPrograms' />,
            <Resource
              name='websites'
              edit={
                isShowing(permissions, "websites") ? WebsiteEdit : NoAccessPage
              }
            />,
            <Resource
              name='collections'
              list={
                isShowing(permissions, "collections")
                  ? CollectionsList
                  : NoAccessPage
              }
              create={
                isShowing(permissions, "collections")
                  ? CreateCollection
                  : NoAccessPage
              }
              edit={
                isShowing(permissions, "collections")
                  ? EditCollection
                  : NoAccessPage
              }
            />,
            <Resource name='warehouses' />,
            <Resource name='webhooks' />,
            <Resource name='layouts' />,
            <Resource name='websiteLayouts' />,
            <Resource name='questionAnswers' />,
            <Resource name='topics' />,
            <Resource name='productCategories' />,
            <Resource name='channels' />,
            <Resource name='packagings' />,
            <Resource
              name='sources'
              edit={
                isShowing(permissions, "sources") ? SourceEdit : NoAccessPage
              }
            />,
            <Resource
              name='sourceGroups'
              edit={
                isShowing(permissions, "sourceGroups")
                  ? SourceGroupsEdit
                  : NoAccessPage
              }
            />,
            <Resource
              name='variants'
              list={
                isShowing(permissions, "variants") ? VariantsList : NoAccessPage
              }
              create={
                isShowing(permissions, "variants")
                  ? VariantsCreate
                  : NoAccessPage
              }
              edit={
                isShowing(permissions, "variants") ? VariantsEdit : NoAccessPage
              }
            />,
            <Resource
              name='reviews'
              list={
                isShowing(permissions, "reviews") ? ReviewsList : NoAccessPage
              }
            />,
            <Resource
              name='customers'
              list={
                isShowing(permissions, "customers")
                  ? CustomerList
                  : NoAccessPage
              }
            />,
          ];
        }}
      </Admin>
    </JssProvider>
  </QueryClientProvider>
);

export default App;
