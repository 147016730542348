export const DataLocale = [
  { id: "id", name: "Bahasa" },
  { id: "en", name: "English" },
  { id: "zh_CN", name: "Chinese" },
  { id: "th", name: "Thailand" },
]
export const DataCurrency = [
  { id: "IDR", symbol: "Rp", name: "IDR" },
  { id: "SGD", symbol: "$", name: "SGD" },
  { id: "CNY", symbol: "¥", name: "CNY" },
  { id: "THB", symbol: "฿", name: "THB" },
  { id: "MYR", symbol: "RM", name: "MYR" },
]

// const unitCurrency = { cny: "¥", idr: "", myr: "RM", sgd: "$", thb: "฿" }
