import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
  Currency,
} from "../../../../components";
import EditMemo from "./EditMemo";

const ListMemos = (props) => {
  const { translate, list, website } = props;
  const [show, setShow] = React.useState(false);
  const [memo, setMemo] = React.useState({});
  const onRowClick = (item) => {
    // console.log(item);
    setMemo(item);
    setShow(true);
  };
  // console.log(memo);
  return (
    <Fragment>
      <Flex width={[1]} flexDirection="column" bg={"#FFFFFF"}>
        <Flex width={[1]} flexDirection="column" p={[2]}>
          <TableContainer component={"div"}>
            <Table aria-label="shipment table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    {translate("resources.orders.fields.reference")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {translate("resources.orders.fields.status")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {translate("resources.orders.fields.amount")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {translate("resources.orders.fields.reason")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {translate("resources.orders.fields.created_at")}
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.length > 0 &&
                  list.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      onClick={() => onRowClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.reference}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.status}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Currency
                          value={row.total}
                          unit={get(website, "locale.currency_symbol", "")}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.reason}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.created_at).format("DD-MMM-YYYY HH:mm")}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <EditMemo
        {...props}
        show={show}
        setShow={setShow}
        recordMemo={memo}
        setMemo={setMemo}
      />
    </Fragment>
  );
};

export default ListMemos;
