import React from "react";
import {
  BetterCustomForm,
  BetterEdit,
  EditToolbar,
  DetailAction,
} from "../../components";
import { Loading } from "react-admin";
import { FormFields } from "./components";
import { useQuery } from "../../components";
export const EditCollection = (props) => {
  const { id } = props;
  const { data, loading } = useQuery(`/collections/${id}`);
  if (loading) {
    return <Loading />;
  }

  return (
    <BetterEdit
      {...props}
      actions={<DetailAction title={"resources.collections.edit_page"} />}
    >
      <BetterCustomForm
        {...props}
        initialValues={data && data.id ? data : {}}
        toolbar={<EditToolbar />}
        style={{ backgroundColor: "#FFFFFF" }}
        defaultSubscription={{
          submitting: true,
          pristine: false,
          valid: true,
          invalid: true,
        }}
      >
        <FormFields {...props} isCreate={false} />
      </BetterCustomForm>
    </BetterEdit>
  );
};
