import React, { Fragment } from "react";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  useStyles,
} from "./";
import Box from "@material-ui/core/Box";
import { get } from "lodash";
import { Flex } from "rebass";
import { FieldArray } from "react-final-form-arrays";
import Chip from "@material-ui/core/Chip";
import { useTranslate } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextFieldInput, BooleanFieldInput } from "./VariantItem";

export default (props) => {
  const translate = useTranslate();
  const classes = useStyles(props);

  return (
    <Flex width={[1]} flexDirection={"column"} pt={[2]}>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate("resources.products.fields.name_sku")}
              </StyledTableCell>
              <StyledTableSmall>
                {translate("resources.products.fields.threshold")}
              </StyledTableSmall>
              <StyledTableSmall>
                {translate("resources.products.fields.price")}
              </StyledTableSmall>
              <StyledTableSmall>
                {translate("resources.products.fields.active")}
              </StyledTableSmall>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name='variant.variants'>
              {({ fields }) => (
                <Fragment>
                  {fields.map((member, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component='td' scope='row'>
                          <Box>
                            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                              <Chip
                                variant='default'
                                color='primary'
                                label={get(fields, `value[${index}].name`, "")}
                              />
                            </div>

                            <TextFieldInput
                              source={`${member}.sku`}
                              {...props}
                              value={fields.value[index].sku}
                              label={""}
                              style={{ width: 300 }}
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableSmall>
                          <TextFieldInput
                            source={`${member}.threshold`}
                            {...props}
                            value={fields.value[index].threshold}
                            label={""}
                            type={"number"}
                            style={{ width: 80 }}
                          />
                        </StyledTableSmall>
                        <StyledTableSmall>
                          <TextFieldInput
                            source={`${member}.price`}
                            {...props}
                            value={fields.value[index].price}
                            label={""}
                            type={"number"}
                            style={{ width: 180 }}
                          />
                        </StyledTableSmall>
                        <StyledTableSmall>
                          <BooleanFieldInput
                            source={`${member}.active`}
                            {...props}
                            value={fields.value[index].active}
                            label={""}
                          />
                        </StyledTableSmall>
                      </StyledTableRow>
                    );
                  })}
                </Fragment>
              )}
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};
