import React from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import moment from "moment";
import { PaperCard, TextRecord } from "../../../../components";
import { RestApi } from "../../../../restClient";
import AmountFields from "./AmountFields";
import { getPaymentInfo } from "./utils";
import { Button } from "@material-ui/core";
import SetPaidFields from "./SetPaidFields";
const generateFile = (content, fileName) => {
  // console.log("content", content); // here at console if i copy the code and use online tool(https://base64.guru/converter/decode/pdf) it shows the correct pdf
  //{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",}
  const blob = new Blob([content]);
  // console.log(blob);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

const PaymentFields = (props) => {
  const { translate, record, website } = props;
  const currency_symbol = get(website, "locale.currency_symbol", "");
  const onPrintInvoice = () => {
    RestApi(`/orders/${record.id}/pdf`, "application/pdf")
      .then((res) => {
        return res.blob();
      })
      .then((data) => {
        // console.log(data);
        if (data) {
          const myfilename = `orders-${moment().format(
            "DD-MMM-YYYYHH:mm"
          )}.pdf`;
          generateFile(data, myfilename);
        }
      })
      .catch((e) => {});
  };
  const myPaymentInfo = getPaymentInfo(record);
  // add payment_info. when using from record
  return (
    <Flex width={[1]} flexDirection='column' pb={[3]}>
      <PaperCard
        showButton={true}
        isCustomButton={true}
        childrenDialog={
          <Flex>
            <SetPaidFields {...props} />
            <Button color={"primary"} onClick={() => onPrintInvoice()}>
              {translate("resources.buttons.print_invoice")}
            </Button>
          </Flex>
        }
        title={translate("resources.orders.fields.payment")}
        onClick={onPrintInvoice}
        elevation={3}
        propsHeader={{ px: [3], py: [2] }}
        labelButton={translate("resources.buttons.print_invoice")}
      >
        <Flex
          width={[1]}
          px={[3]}
          flexDirection={"row"}
          bg={"#eeeeee"}
          p={[3]}
          sx={{
            "&>div": {
              pr: [3],
            },
          }}
        >
          <TextRecord
            label={"resources.orders.labels.payment_method"}
            source={"mode"}
            record={myPaymentInfo}
            defaultValue={""}
          />
          <TextRecord
            label={"resources.orders.labels.payment_reference"}
            source={"order_ref"}
            record={myPaymentInfo}
            defaultValue={""}
          />
          <TextRecord
            label={"resources.orders.labels.payment_date"}
            source={"created_at"}
            record={myPaymentInfo}
            defaultValue={""}
            isDate={true}
            formatDate={"DD-MMM-YYYY HH:mm a"}
          />
          <TextRecord
            label={"resources.orders.labels.paid_amount"}
            source={"amount"}
            record={myPaymentInfo}
            defaultValue={``}
            isCurrency={true}
            currencySymbol={currency_symbol}
          />
          <TextRecord
            label={"resources.orders.fields.payment_status"}
            source='status'
            record={myPaymentInfo}
            defaultValue=''
          />
        </Flex>
        <AmountFields {...props} />
        {/* <Flex width={[1]} flexDirection={"column"} px={[3]} py={[3]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              "&>div": {
                py: [2],
              },
            }}
          >
            <Text fontWeight={"bold"}>
              {translate("resources.orders.labels.subtotal")}
            </Text>
            <Text>
              <Currency
                value={"0"}
                unit={currency_symbol}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Text>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              "&>div": {
                py: [2],
              },
            }}
          >
            <Text fontWeight={"bold"}>
              {translate("resources.orders.labels.shipping")}
            </Text>
            <Text>
              <Currency
                value={"0"}
                unit={currency_symbol}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Text>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              "&>div": {
                py: [2],
              },
            }}
          >
            <Text fontWeight={"bold"}>
              {translate("resources.orders.labels.tax")}
            </Text>
            <Text>
              <Currency
                value={"0"}
                unit={currency_symbol}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Text>
          </Flex>
        </Flex>
        <Flex  width={[1]}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          bg={"#EEEEEE"}
          px={[3]}
          mb={[2]}
          sx={{
            "&>div": {
              py: [2],
            },
          }}
        >
          <Text fontWeight={"bold"}>
            {translate("resources.orders.labels.total")}
          </Text>
          <Text>
            <Currency
              value={"0"}
              unit={currency_symbol}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Text>
        </Flex> */}
      </PaperCard>
    </Flex>
  );
};

export default PaymentFields;
