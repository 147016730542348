import React from "react";
import { useStyles, useStylesTable } from "../utils";
import { Datagrid } from "react-admin";
import List from "./List";
export { default as List } from "./List";
export { default as Filter } from "./Filter";
export { Datagrid } from "./datagrid";

const betterRowStyle = (record, index) => ({
  borderBottomWidth: 0,
  borderWidth: 0,
  padding: 0,
});

export const BetterList = (props) => {
  const { classes: classesOverride, rowClick, exporter, ...rest } = props;
  const classes = useStyles({ classes: classesOverride });
  const classesT = useStylesTable({ classes: classesOverride });

  return (
    <List
      bulkActionButtons={false}
      {...rest}
      classes={classes}
      exporter={exporter ? exporter : false}
    >
      <Datagrid
        rowStyle={betterRowStyle}
        classes={classesT}
        rowClick={rowClick}
      >
        {props.children}
      </Datagrid>
    </List>
  );
};
