import React from "react";
import { get, find } from "lodash";
import { Flex, Box } from "rebass";
import { useTranslate } from "react-admin";
import { Field } from "react-final-form";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { rawTimeZone } from "../../../components/timezone";
const DateInputFields = (props) => {
  const { updateField, formValues, websites } = props;
  const translate = useTranslate();
  const website = find(websites, function (o) {
    return o.id === formValues.website_id;
  });
  const timeZone = get(website, "locale.timezone", "");
  // const handleDateChange = (name) => (date) => {
  //   // console.log(formValues.website_id);
  //   // console.log("date", date.toISOString());
  //   // // console.log("iso", myDate.toISOString());
  //   // updateField(name, date);
  //   if (timeZone !== "") {
  //     const getRawTime = find(rawTimeZone, function (io) {
  //       return io.name === timeZone;
  //     });
  //     const myFormat = `ddd MMM DD YYYY, hh:mm:ss`;
  //     const myTextStartDate = date.format(myFormat);
  //     const myDateSD = moment.tz(
  //       `${myTextStartDate}  GMT${getRawTime.rawTFormat}`,
  //       myFormat,
  //       `${getRawTime.name}`
  //     );
  //     updateField(name, myDateSD);
  //   }
  // };
  const handleDateChange = (name) => (date) => {
    if (timeZone !== "") {
      const getRawTime = find(rawTimeZone, function (io) {
        return io.name === timeZone;
      });
      const myFormatED = `ddd MMM DD YYYY, hh:mm:ss`;
      const myFormatDefault = `ddd MMM DD YYYY 23:59:59`;
      const myFormatDefault1 = `ddd MMM DD YYYY 00:00:00`;

      if (name === "start_date") {
        const myTextStartDate = date.format(myFormatDefault1);
        const myDateSD = momentTimeZone.tz(
          `${myTextStartDate}  GMT${getRawTime.rawTFormat}`,
          myFormatED,
          `${getRawTime.name}`
        );

        updateField(name, myDateSD);
      } else if (name === "end_date") {
        const myTextEndDate = date.format(myFormatDefault);
        const myDateED = momentTimeZone.tz(
          `${myTextEndDate}  GMT${getRawTime.rawTFormat}`,
          myFormatED,
          `${getRawTime.name}`
        );

        updateField(name, myDateED);
      }
    }
  };

  if (
    get(formValues, "website_id") &&
    get(formValues, "website_id") !== "" &&
    get(formValues, "website_id") !== 0
  ) {
    return (
      <Flex
        width={[1]}
        flexDirection={"row"}
        alignContent={"center"}
        sx={{
          "&> div": {
            pr: [2],
          },
        }}
      >
        <Box>
          <Field name={`start_date`}>
            {(props) => {
              let newParams = props.input.value;
              if (typeof props.input.value === "string") {
                newParams = moment(props.input.value);
              }
              return (
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='DD-MMM-YYYY'
                  margin='normal'
                  label={translate("resources.contents.fields.start_date")}
                  style={{ marginRight: 16 }}
                  inputProps={{
                    style: { width: 100, minWidth: 100 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  id='start-date-inline-content'
                  name={props.input.name}
                  value={newParams}
                  onChange={handleDateChange("start_date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              );
            }}
          </Field>
        </Box>
        <Box>
          <Field name={`end_date`}>
            {(props) => {
              let newParams1 = props.input.value;
              if (typeof props.input.value === "string") {
                newParams1 = moment(props.input.value);
              }
              return (
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='DD-MMM-YYYY'
                  margin='normal'
                  style={{ marginRight: 16 }}
                  inputProps={{
                    style: { width: 100, minWidth: 100 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  id='end-date-inline-content'
                  label={translate("resources.contents.fields.end_date")}
                  name={props.input.name}
                  value={newParams1}
                  onChange={handleDateChange("end_date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              );
            }}
          </Field>
        </Box>
      </Flex>
    );
  }

  return <div />;
};
export default DateInputFields;
