import React from "react";
import { get } from "lodash";
import {
  OrderFieldView,
  OrderItemFields,
  CustomerInfoFields,
  // CommentFields,
  PaymentFields,
  CreateMemoFields,
} from "./order_item";
import { Flex } from "rebass";

const OrderFields = (props) => {
  // console.log(props.record);
  const { translate, record } = props;
  const onEditInfo = (key) => {};
  const customerInfo = get(record, "customer_info", {});
  return (
    <Flex width={[1]} p={[2]} flexDirection={"column"}>
      <OrderFieldView {...props} />
      <OrderItemFields {...props} />
      <PaymentFields {...props} />
      <CreateMemoFields {...props} />
      {/* <CommentFields {...props} /> */}
      <Flex
        width={[1]}
        flexDirection={"row"}
        pt={[3]}
        sx={{
          "&>div": {
            px: [1],
          },
        }}
      >
        <CustomerInfoFields
          {...props}
          info={customerInfo}
          title={translate("resources.orders.labels.billing_information")}
          onClick={() => onEditInfo("billing")}
        />
        <CustomerInfoFields
          {...props}
          info={customerInfo}
          title={translate("resources.orders.labels.shipping_information")}
          onClick={() => onEditInfo("shipping")}
          isShowEmail={true}
        />
      </Flex>
    </Flex>
  );
};

export default OrderFields;
