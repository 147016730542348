import React, { useState } from "react";
import { useNotify } from "react-admin";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "rebass";
// import { message } from "antd";
import { UploadClient } from "../../../../restClient";
// const errorMessage = (text) => {
//   message.error(text);
// };
const UploadCsv = (props) => {
  const {
    accept,
    disableClick,
    maxSize,
    minSize,
    multiple,
    translate,
    handleSubmit,
    urlPath,
    typeImport,
    setOpen,
  } = props;
  /**
   * type: type,
    start_at: start_at,
    end_at: end_at,
    name: state.name,
    country_id: state.country_id,
   */
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      let formData = new FormData();
      accepted.forEach((file) => {
        //   console.log(file);
        formData.append("file", file);
      });
      formData.append("type", typeImport);
      // console.log(urlPath, formData);
      setLoading(true);
      UploadClient(formData, urlPath)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // console.log("SUCCESSS");
            return response.json();
          } else if (response.status === 500) {
            setOpen(false);
            return response.json();
            // errorMessage(
            //   translate("resources.products.messages.import_product_failed_500")
            // );
          }
        })
        .then((data) => {
          // console.log(data);
          if (data) {
            if (data && data.message && data.message !== "") {
              setOpen(false);
              notify(data.message, "warning");
            } else {
              handleSubmit(data);
              // this.onAdd(data.url);
              // notify("resources.pricebook.messages.success", "success");
            }
          } else {
            setOpen(false);
            notify(
              "resources.products.messages.import_product_failed",
              "warning"
            );
          }
          setLoading(false);
        })
        .catch((e) => {
          setOpen(false);
          notify(e.message, "warning");
          setLoading(false);
        });
    }
  };
  return (
    <Box p={[2]}>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick || loading}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div>
            <div {...getRootProps()}>
              {!(disableClick || loading) && <input {...getInputProps()} />}

              <Button
                variant={"contained"}
                color='primary'
                disabled={disableClick || loading}
                startIcon={loading ? <CircularProgress size={16} /> : null}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {translate("resources.buttons.upload_csv")}
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
    </Box>
  );
};

UploadCsv.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
};
const maxSizeCSV = 1000000 * 15;

UploadCsv.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: maxSizeCSV,
  accept: ".csv, application/vnd.ms-excel, text/csv",
  onUpload: () => {},
};

export default UploadCsv;
