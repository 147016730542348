import React, { Fragment } from "react"
import { Flex } from "rebass"
import { FormDataConsumer } from "react-admin"
import { RaTextInput, RaLocaleTabs } from "../../../components"
import { getSiteID } from "../../../restClient"
const FormFields = (props) => {
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData }) => {
          const SiteID =
            formData.site_id === getSiteID() ? formData.site_id : getSiteID()
          return (
            <RaLocaleTabs siteId={SiteID} nameTab="tab-variants-info">
              <ContentItem {...props} />
            </RaLocaleTabs>
          )
        }}
      </FormDataConsumer>
    </Flex>
  )
}

const ContentItem = (props) => {
  const { locale, ...rest } = props

  const onChangeName = (key, name) => (e) => {
    //localeName,
    if (key === "en") {
      rest.updateField(name, e.target.value)
      //   // rest.updateField(localeName, e.target.value)
      // } else {
      //   rest.updateField(localeName, e.target.value)
    }
  }

  return (
    <Fragment>
      <RaTextInput
        source={`locale.${locale}.name`}
        label="resources.variants.fields.name"
        onChange={onChangeName(locale, "name")}
        style={{ width: 400 }}
      />
      <RaTextInput
        source={`locale.${locale}.code`}
        onChange={onChangeName(locale, "code")}
        label="resources.variants.fields.code"
        style={{ width: 400 }}
        notetext={"resources.variants.note_text.code"}
      />
    </Fragment>
  )
}

export default FormFields
