import React, { useState, useEffect } from "react";
import { Box } from "rebass";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { useGetOne, Loading, useTranslate } from "react-admin";
import {
  TabPanel,
  a11yProps,
  languages,
  choicesLanguages,
  RaTextInput,
  ImageOlaSingleInput,
} from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

let LocaleSiteId = 0;

const LocaleCategories = (props) => {
  const { formData } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [localesList, setLocalesList] = useState({
    default_locale: "en",
    locale: languages,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { data, loading } = useGetOne("sites", formData.site_id);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (data && LocaleSiteId !== formData.site_id) {
        // console.log(localesList)
        let newLanguage = data.locales.map(function (e) {
          let f = choicesLanguages.find((a) => a.id === e);
          return f ? f : undefined;
        });
        // setTabId(data.default_locale)
        setLocalesList({
          default_locale: data.default_locale,
          locale: newLanguage,
        });
        LocaleSiteId = formData.site_id;
      }
    }, 300);
    return () => clearTimeout(timeout);
  });
  if (loading) {
    return <Loading />;
  }

  if (localesList && localesList.locale && localesList.locale.length === 1) {
    const item = localesList.locale[0];
    return (
      <div className={classes.root}>
        <ContentItem locale={item.id} translate={translate} {...props} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Tabs value={value} indicatorColor='primary' onChange={handleChange}>
        {localesList &&
          localesList.locale &&
          localesList.locale.map((choice, index) => (
            <Tab
              key={choice.id}
              label={choice.name}
              {...a11yProps("LocaleCategories", index)}
            />
          ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {localesList &&
          localesList.locale &&
          localesList.locale.map((item, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              dir={theme.direction}
              name={"LocaleCategories"}
            >
              <ContentItem
                key={index}
                locale={item.id}
                translate={translate}
                {...props}
              />
            </TabPanel>
          ))}
      </SwipeableViews>
    </div>
  );
};

const ContentItem = ({ locale, translate, ...rest }) => {
  const onChangeName = (key, name) => (e) => {
    if (key === "en") {
      rest.updateField(name, e.target.value);
    }
  };
  return (
    <Box width={[1]}>
      <RaTextInput
        source={`locale.${locale}.name`}
        label={"resources.categories.fields.category_name"}
        onChange={onChangeName(locale, "name")}
        fullWidth={true}
      />
      <ImageOlaSingleInput
        source={`locale.${locale}.banner`}
        toValue={"categories"}
        labelImage={translate("resources.categories.fields.banner")}
        label={"resources.categories.fields.banner"}
        accept='image/*'
        key={locale}
        record={rest.formData}
        {...rest}
        basePath={"/categories"}
      />
      {/* <RaTextInput
        source={`locale.${locale}.code`}
        label={"resources.categories.fields.code"}
        fullWidth={true}
        notetext={"resources.categories.note_text.code"}
        onChange={onChangeName(locale, "code")}
      /> */}

      {/* <SelectArrayInput
        label="resources.categories.fields.property"
        source={`locale.${locale}.properties`}
        fullWidth
        key={locale}
      /> */}
    </Box>
  );
};

export default LocaleCategories;
