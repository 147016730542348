import React, { Fragment, useState } from "react";
import { get } from "lodash";
import moment from "moment";
import {
  TextField,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
} from "react-admin";
import { ListReviewAction } from "../../components";
import { Filter, BetterList } from "../../components/ra-list";
import { getSiteID } from "../../restClient";
import { dataStatus, DialogEditReview } from "./components";

const CustomDateField = ({ record }) => {
  const created_at = get(record, "created_at", "");
  return (
    <span>
      {created_at !== "" ? moment(created_at).format("DD-MMM-YYYY") : ""}
    </span>
  );
};

const ReviewsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label=""
      source="website_id"
      reference="websites"
      alwaysOn
      InputLabelProps={{
        shrink: true,
      }}
      style={{ top: 0, position: "absolute", marginLeft: 110 }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="status"
      label="resources.reviews.fields.status"
      choices={dataStatus}
      InputLabelProps={{
        shrink: true,
      }}
      alwaysOn
    />
    <ReferenceInput
      label="resources.products.fields.product"
      source="product_id"
      reference="products"
      style={{ minWidth: 300 }}
      sort={{ field: "id", order: "ASC" }}
      filterToQuery={(searchText) => ({
        name: searchText,
      })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 2;
      }}
      InputLabelProps={{
        shrink: true,
      }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" fullWidth={true} />
    </ReferenceInput>
  </Filter>
);

export const ReviewsList = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [record, setRecord] = useState({});
  const [basePath, setBasePath] = useState("/");
  const [id, setId] = useState(0);
  const onRowClick = (id, basePath, record) => {
    // console.log(id, record)
    // console.log(basePath)
    setBasePath(basePath);
    setId(id);
    setRecord(record);
    setShowDialog(true);
  };
  return (
    <Fragment>
      <BetterList
        {...props}
        actions={
          <ListReviewAction
            title="resources.reviews.title"
            isHideCreate={true}
          />
        }
        filter={{ site_id: getSiteID() }}
        filters={<ReviewsFilter />}
        rowClick={onRowClick}
      >
        <TextField
          source="order_detail_id"
          label={"resources.reviews.fields.id"}
        />
        <TextField source="status" label={"resources.reviews.fields.status"} />
        <TextField
          source="nickname"
          label={"resources.reviews.fields.customer"}
        />
        <ReferenceField
          label={"resources.reviews.fields.product"}
          source="product_id"
          reference="products"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <CustomDateField
          source="created_at"
          label={"resources.reviews.fields.created_on"}
        />
      </BetterList>
      <DialogEditReview
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        record={record}
        id={id}
        basePath={basePath}
      />
    </Fragment>
  );
};
