import React from "react"
import { Flex } from "rebass"
import { TextRecord } from "../../../components"
const HeaderFields = (props) => {
  const { record } = props
  return (
    <Flex flexDirection={"row"} sx={{ "&>div": { px: [3] } }}>
      <TextRecord
        source={"order_detail_id"}
        label={"resources.reviews.fields.id"}
        record={record}
      />
      <TextRecord
        source={"nickname"}
        label={"resources.reviews.fields.customer"}
        record={record}
      />
      <TextRecord
        isDate={true}
        source="created_at"
        label={"resources.reviews.fields.created_on"}
        record={record}
      />
    </Flex>
  )
}
export default HeaderFields
