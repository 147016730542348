import React from "react";
import { Flex } from "rebass";
import Box from "@material-ui/core/Box";
import { get } from "lodash";
// import { RaTextInput, RaBooleanInput, RaNumberInput } from "../";
import { TextFieldInput, BooleanFieldInput } from "../variants";
export default (props) => {
  const { classes, formValues, updateField, translate } = props;
  return (
    <Box
      boxShadow={3}
      className={classes.paper}
      ml={4}
      mr={4}
      display='flex'
      flexDirection='column'
    >
      <Flex width={[1]} flexDirection={"row"} alignItems={"flex-end"} mb={[2]}>
        <TextFieldInput
          source={"shipping.box"}
          value={get(formValues, "shipping.box", "")}
          label={translate("resources.products.fields.shipping.box")}
          updateField={updateField}
          style={{ width: 300 }}
        />
        <BooleanFieldInput
          source={"shipping.free_shipping"}
          label={translate("resources.products.fields.shipping.free_shipping")}
          updateField={updateField}
          value={get(formValues, "shipping.free_shipping", false)}
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            mr: [2],
          },
        }}
      >
        <TextFieldInput
          source='shipping.weight'
          value={get(formValues, "shipping.weight", 0)}
          label={translate("resources.products.fields.shipping.weight")}
          type={"number"}
          updateField={updateField}
          style={{ width: 256 }}
        />
        <TextFieldInput
          source='shipping.width'
          value={get(formValues, "shipping.width", 0)}
          label={translate("resources.products.fields.shipping.width")}
          type={"number"}
          updateField={updateField}
          style={{ width: 256 }}
        />
        <TextFieldInput
          source='shipping.length'
          value={get(formValues, "shipping.length", 0)}
          label={translate("resources.products.fields.shipping.length")}
          type={"number"}
          updateField={updateField}
          style={{ width: 256 }}
        />
        <TextFieldInput
          source='shipping.height'
          value={get(formValues, "shipping.height", 0)}
          label={translate("resources.products.fields.shipping.height")}
          type={"number"}
          updateField={updateField}
          style={{ width: 256 }}
        />
        <TextFieldInput
          source='shipping.subsidy'
          value={get(formValues, "shipping.subsidy", 0)}
          label={translate("resources.products.fields.shipping.subsidy")}
          type={"number"}
          updateField={updateField}
          style={{ width: 256 }}
        />
        {/* <RaNumberInput
          source='shipping.weight'
          label='resources.products.fields.shipping.weight'
          style={{ width: 180 }}
        />
        <RaNumberInput
          source='shipping.width'
          label='resources.products.fields.shipping.width'
          style={{ width: 180 }}
        />
        <RaNumberInput
          source='shipping.length'
          label='resources.products.fields.shipping.length'
          style={{ width: 180 }}
        />

        <RaNumberInput
          source='shipping.height'
          label='resources.products.fields.shipping.height'
          style={{ width: 180 }}
        /> 
         <RaNumberInput
          source='shipping.subsidy'
          label='resources.products.fields.shipping.subsidy'
          style={{ width: 180 }}
        /> */}
      </Flex>
    </Box>
  );
};
