import React from "react";
import { Flex, Box } from "rebass";
import { PaperCard, TextRecord } from "../../../../components";
import EmailFieldView from "./FieldView";
const CustomerInfoFields = (props) => {
  const { title, info, onClick, translate, isShowEmail } = props;

  return (
    <Flex width={[1]} flexDirection='column'>
      <PaperCard
        title={title}
        showButton={false}
        onClick={onClick}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
        labelButton={translate("resources.buttons.edit")}
      >
        <Flex width={[1]} flexDirection={"column"} px={[3]} py={[3]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Box width={[1, "320px"]}>
              <TextRecord
                source={"name"}
                record={info}
                label={"resources.orders.fields.recipient"}
              />
            </Box>
            <TextRecord
              source={"phone"}
              record={info}
              label={"resources.orders.fields.mobile_number"}
            />
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Box width={[1, isShowEmail ? "320px" : 1]} pr={[0, 2]}>
              <TextRecord
                source={"address"}
                record={info}
                label={"resources.orders.fields.address_line_1"}
              />
              <TextRecord
                source={"address2"}
                record={info}
                label={"resources.orders.fields.address_line_2"}
              />
            </Box>
            {isShowEmail && (
              <Box>
                <EmailFieldView
                  record={info}
                  source={"customer_id"}
                  resources={"customers"}
                  optionText={"email"}
                  label={"resources.orders.fields.email"}
                />
              </Box>
            )}
          </Flex>

          <Box width={[1]}></Box>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Box width={[1, "320px"]}>
              <TextRecord
                source={"city"}
                record={info}
                label={"resources.orders.fields.city_town"}
              />
            </Box>
            <TextRecord
              source={"province"}
              record={info}
              label={"resources.orders.fields.state_province"}
            />
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Box width={[1, "320px"]}>
              <TextRecord
                source={"country"}
                record={info}
                label={"resources.orders.fields.country"}
              />
            </Box>
            <TextRecord
              source={"zip"}
              record={info}
              label={"resources.orders.fields.postcal_code"}
            />
          </Flex>
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default CustomerInfoFields;
