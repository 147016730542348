import React from "react";
import { useTranslate, ExportButton } from "react-admin";
import { Flex, Box } from "rebass";
import { Title } from "../../../components";
import { BOX_SHADOW_COLOR } from "../../../utils";
const ListCustomerAction = (props) => {
  const {
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    title,
    exporter,
    currentSort,
    total,
    permanentFilter,
  } = props;
  const translate = useTranslate();
  const totalItems = total + 10;
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        p={[3]}
        bg={"white"}
        style={{
          boxShadow: BOX_SHADOW_COLOR,
        }}
      >
        <Box width={[1]} mr={[4]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(title)}
          </Title>
        </Box>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Box width={[1]} pt={[2]}>
            {filters &&
              React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
              })}
          </Box>
          <Box width={"160px"}>
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filterValues={{ ...filterValues, ...permanentFilter }}
              exporter={exporter}
              label={translate("resources.buttons.export_to_csv")}
              maxResults={totalItems}
            />
          </Box>
        </Flex>
      </Flex>
      <Box
        width={[1]}
        style={{ height: 24, backgroundColor: "transparent" }}
        mb={[2]}
      />
    </Box>
  );
};

export default ListCustomerAction;
