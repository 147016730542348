import React from "react";
import {
  TextField,
  BooleanField,
  FunctionField,
  SearchInput,
} from "react-admin";
import { Flex } from "rebass";
import {
  ButtonEditView,
  // RaSearchInput,
  // RaSearchSelectInput,
  RaTextField,
} from "../../components";
import { BetterList, Filter } from "../../components/ra-list";
import { ShowFieldSKu, ListAction } from "./components";
import CategoryFilter from "./CategoryFilter";
//ShowCategoriesField

const ProductFilter = (props) => (
  <Flex width={[1]}>
    <Filter {...props}>
      <CategoryFilter alwaysOn source='category_id' />
      {/* <ReferenceInput
        label='resources.products.fields.category'
        
        reference='categories'
        alwaysOn
        style={{ width: 300 }}
      >
        <RaSearchSelectInput optionText='name' />
      </ReferenceInput> */}
      {/* <RaSearchInput label="Search" source="search" alwaysOn /> */}
      <SearchInput source='search' alwaysOn />
    </Filter>
  </Flex>
);

const ProductList = (props) => {
  return (
    <BetterList
      {...props}
      actions={<ListAction title={`resources.products.name`} />}
      filters={<ProductFilter />}
    >
      <RaTextField source='name' />
      <FunctionField
        label='resources.products.fields.sku'
        render={(record) => <ShowFieldSKu record={record} />}
      />
      {/* <FunctionField
        label="resources.products.fields.categories"
        render={(record) => <ShowCategoriesField record={record} />}
      /> */}
      {/* <ReferenceField
        label="resources.products.fields.category"
        source="category_id"
        reference="categories"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField> */}
      {/* <ReferenceArrayField
        label="resources.products.fields.categories"
        reference="categories"
        source="categories"
        link={false}
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField> */}
      <TextField source='brand' />
      <BooleanField source='active' />
      <ButtonEditView {...props} />
    </BetterList>
  );
};
export default ProductList;
