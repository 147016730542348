import React from "react"
import { Edit } from "react-admin"
import { EditToolbar, BetterCustomSimpleForm } from "../../components"
import { FormFields } from "./components"

const SourceEdit = (props) => {
  // const translate = useTranslate()
  return (
    <Edit {...props}>
      <BetterCustomSimpleForm
        {...props}
        sourceApi="sources"
        title={"resources.sources.edit_title"}
        typePostUrl={"UPDATE"}
        pathUrl="sources"
        toolbar={<EditToolbar />}
      >
        <FormFields {...props} />
      </BetterCustomSimpleForm>
    </Edit>
  )
}

export default SourceEdit
