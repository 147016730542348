import React from "react"
import { Create, useTranslate } from "react-admin"
import {
  BetterCustomForm,
  TopBarTitleAction,
  FooterToolbar,
} from "../../components"
import { FormFields } from "./components"
import { getSiteID } from "../../restClient"
const initValues = {
  site_id: getSiteID(),
}

const PriceBookCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create
      {...props}
      actions={
        <TopBarTitleAction title={translate("resources.variants.new_title")} />
      }
    >
      <BetterCustomForm
        {...props}
        toolbar={<FooterToolbar />}
        redirect={"list"}
        initialValues={initValues}
      >
        <FormFields {...props} />
      </BetterCustomForm>
    </Create>
  )
}

export default PriceBookCreate
