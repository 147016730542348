/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { Edit } from "react-admin";
import { EditToolbar, DetailAction, Loading } from "../../components";
import { FormFields, WizardEditorForm, ContentField } from "./components";
import FormTab from "./components/FormTab";
import { getWebsites } from "../../restClient";
import { useQuery } from "react-query";
const cacheTimeMinute = 1000 * 60 * 30;
// const initValues = {
//   start_date: moment(),
//   end_date: moment().add(7, "days"),
// };

const ContentEdit = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props;
  const { data, isLoading } = useQuery("websites", () => getWebsites(), {
    cacheTime: cacheTimeMinute,
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Edit
      {...rest}
      actions={<DetailAction title={"resources.contents.edit_page"} />}
    >
      <WizardEditorForm
        {...rest}
        toolbar={<EditToolbar />}
        redirect='list'
        submitOnEnter={false}
        // initialValues={initValues}
      >
        <FormTab label='resources.products.tabs.info' path={""}>
          <FormFields {...rest} isCreate={false} websites={data} />
        </FormTab>
        <FormTab label='resources.products.tabs.images' path={"page"}>
          <ContentField {...rest} isCreate={false} />
        </FormTab>
      </WizardEditorForm>
    </Edit>
  );
};

export default ContentEdit;
