import React from "react"
import { Create } from "react-admin"
import { BetterCustomSimpleForm, FooterToolbar } from "../../components"
import { FormFields } from "./components"
const initValues = {
  filters: false,
}

const FiltersCreate = props => {
  return (
    <Create {...props}>
      <BetterCustomSimpleForm
        {...props}
        toolbar={<FooterToolbar />}
        initialValues={initValues}
        sourceApi="filters"
        typePostUrl={"CREATE"}
        pathUrl="filters"
        title={"resources.filters.back"}
        labelBack={"resources.filters.new_filters"}
        isShowBack={true}
      >
        <FormFields {...props} isCreate={true} />
      </BetterCustomSimpleForm>
    </Create>
  )
}

export default FiltersCreate
