import React, { useState, useEffect } from "react";
import { Flex } from "rebass";
import LinearProgress from "@material-ui/core/LinearProgress";
import { PaperCard } from "../../../../components";
import { CreateMemo, ListMemos } from "../memo";
import { RestApi } from "../../../../restClient";
const CreateMemoFields = (props) => {
  const { translate, record } = props;
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const orderId = record.id;
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      RestApi(`/orders/${orderId}/creditMemos`)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          // console.log("res", res);
          if (res) {
            setList(res);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [orderId]);

  const onRefreshList = () => {
    setLoading(true);
    RestApi(`/orders/${orderId}/creditMemos`)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log(res);
        if (res) {
          setList(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  if (loading) {
    return (
      <Flex width={[1]} p={[3]}>
        <LinearProgress style={{ width: "100%" }} />
      </Flex>
    );
  }
  // console.log(list);
  const onCreateMemo = () => {};
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate("resources.orders.labels.credit_memo")}
        showButton={false}
        onClick={onCreateMemo}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
        isCustomButton={true}
        childrenDialog={<CreateMemo {...props} onRefreshList={onRefreshList} />}
        labelButton={translate("resources.buttons.create_memo")}
      >
        <Flex width={[1]} flexDirection={"column"} sx={{ minHeight: 80 }}>
          <ListMemos {...props} list={list} onRefreshList={onRefreshList} />
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default CreateMemoFields;
