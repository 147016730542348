import React from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { RadioButtonGroupInput } from "react-admin";
import { PaperCard } from "./PaperCard";
import { dataCustomerType, dataPromoType } from "./utils";
const CustomerTypeFields = (props) => {
  const { translate, formData } = props;
  const typeValue = get(formData, "type", "");

  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.customer_type")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"} px={[2]}>
          {typeValue === dataPromoType[0].id && (
            <RadioButtonGroupInput
              label={""}
              row={false}
              source='criteria.percentage.customer_type'
              defaultValue={"All"}
              choices={dataCustomerType}
            />
          )}
          {typeValue === dataPromoType[1].id && (
            <RadioButtonGroupInput
              label={""}
              row={false}
              source='criteria.amount.customer_type'
              defaultValue={"All"}
              choices={dataCustomerType}
            />
          )}
          {typeValue === dataPromoType[2].id && (
            <RadioButtonGroupInput
              label={""}
              row={false}
              source='criteria.buy_x_get_y.customer_type'
              defaultValue={"All"}
              choices={dataCustomerType}
            />
          )}
          {typeValue === dataPromoType[3].id && (
            <RadioButtonGroupInput
              label={""}
              row={false}
              source='criteria.fixed_price.customer_type'
              defaultValue={"All"}
              choices={dataCustomerType}
            />
          )}
          {typeValue === dataPromoType[4].id && (
            <RadioButtonGroupInput
              label={""}
              row={false}
              source='criteria.free_shipping.customer_type'
              defaultValue={"All"}
              choices={dataCustomerType}
            />
          )}
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default CustomerTypeFields;
