import React from "react";
import { get, has, keys, find } from "lodash";
import { Box, Flex, Text } from "rebass";
import { useTranslate } from "react-admin";
import { RTDescriptionProduct } from "./";
import { useQuery } from "react-query";
import {
  TextFieldInput,
  BooleanFieldInput,
  TextFieldAttInput,
} from "./variants";
//TextAreaInput
import { dataProvider, RestApi } from "../../../restClient";
const cacheTimeMinute = 1000 * 60 * 30;
const cacheTimeMinute2 = 1000 * 60 * 5;
const toolbar = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
];

const setAttributesList = (value, updateField) => {
  if (value && value !== null) {
    dataProvider
      .getOne("attributeSets", { id: value })
      .then((items) => {
        if (items && items.data) {
          const { data } = items;
          let myObjAttributes = {};
          data.attributes.map((item) => {
            myObjAttributes["a-" + item] = {
              id: item,
              description: "",
              value: "",
              switch: false,
            };
            return {
              id: item,
              description: "",
              value: "",
              switch: false,
            };
          });
          // const myAttributes = data.attributes.map((item) => {
          //   return {
          //     id: item,
          //     description: "",
          //     value: 0,
          //     switch: false,
          //   }
          // })
          updateField("attributes", myObjAttributes);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
};
const editAttributesList = (value, updateField, defaultAttributes) => {
  if (value && value !== null) {
    dataProvider
      .getOne("attributeSets", { id: value })
      .then((items) => {
        if (items && items.data) {
          const { data } = items;
          let myObjAttributes = {};
          data.attributes.map((item) => {
            const updateItem = find(defaultAttributes, function (oo) {
              return oo.id === item;
            });
            myObjAttributes["a-" + item] = updateItem
              ? updateItem
              : {
                  id: item,
                  description: "",
                  value: "",
                  switch: false,
                };
            return {
              id: item,
              description: "",
              value: "",
              switch: false,
            };
          });

          // console.log("myObjAttributes", myObjAttributes);
          updateField("attributes", myObjAttributes);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
};

const AttributesList = (props) => {
  const {
    sites,
    formData,
    record,
    translate,
    updateField,
    listAttId,
    attUpdate,
  } = props;

  const defaultAttributes = get(formData, "attributes", []);
  if (keys(defaultAttributes).length === 0) {
    setAttributesList(get(record, "attribute_set_id", 0), props.updateField);
  } else {
    if (attUpdate) {
      editAttributesList(
        get(record, "attribute_set_id", 0),
        props.updateField,
        defaultAttributes
      );
      props.onDisabledUpdated();
    }
  }
  // console.log("keys(defaultAttributes)", keys(defaultAttributes));
  return (
    <Flex width={[1]} flexDirection={"column"} p={[2]}>
      {listAttId.map((key) => {
        return (
          <Attributes
            key={`a-${key}`}
            formData={formData}
            sites={sites}
            source={`attributes.a-${key}`}
            attributesId={`a-${key}`}
            translate={translate}
            updateField={updateField}
          />
        );
      })}
      {/* {keys(formData.attributes).length > 0 &&
        Object.keys(formData.attributes).map((key) => {
          console.log(key);
          return (
            <Attributes
              key={key}
              formData={formData}
              sites={sites}
              source={`attributes.${key}`}
              attributesId={key}
              translate={translate}
              updateField={updateField}
            />
          );
        })} */}
      {/* <ArrayInput source="attributes">
        <SimpleFormIterator disableRemove disableAdd disableIndex>
          <Attributes formData={formData} sites={sites} />
        </SimpleFormIterator>
      </ArrayInput> */}
    </Flex>
  );
};

const AttributesFields = (props) => {
  const { formValues } = props;
  const { data, isLoading } = useQuery(
    ["sites", formValues.site_id],
    () => RestApi(`/sites/${formValues.site_id}`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  // const { data, loading } = useQueryWithStore({
  //   type: "getOne",
  //   resource: "sites",
  //   payload: { id: formValues.site_id },
  // });
  if (isLoading) {
    return <div />;
  }
  if (get(formValues, "attribute_set_id", null) === null) {
    return <div />;
  }

  return (
    <Flex width={[1]} flexDirection={"column"} p={[2]}>
      <HitAttributeSet formData={formValues} {...props} sites={data} />
    </Flex>
  );
};

const HitAttributeSet = (props) => {
  const { formData } = props;
  const { data, isLoading } = useQuery(
    ["attributeSets", formData.attribute_set_id],
    () =>
      RestApi(`/attributeSets/${formData.attribute_set_id}`).then((res) =>
        res.json()
      ),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return <div />;
  }

  return (
    <AttributesList
      formData={formData}
      {...props}
      listAttId={get(data, "attributes", [])}
    />
  );
};

const Attributes = (props) => {
  const { attributesId } = props;
  const myatt = attributesId.replace(/'/g, "");
  // const attributesId = get(formData, `${source}.id`, 0)
  if (myatt && myatt === 0) {
    return <div />;
  }
  return <AttributeItem {...props} />;
};

const AttributeItem = (props) => {
  const translate = useTranslate();
  const { sites, source, attributesId, updateField, formData } = props;

  const myatt = attributesId.replace("a-", "");
  const { data, isLoading } = useQuery(
    ["attributes", myatt],
    () => RestApi(`/attributes/${myatt}`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute2,
    }
  );

  if (isLoading) {
    return (
      <div key={source}>
        {translate("resources.products.note_text.loading")}
      </div>
    );
  }

  const default_locale = get(sites, "default_locale", "en");
  const attribute = get(data, `locale[${default_locale}]`, {});
  const title =
    attribute && attribute.name ? attribute.name.split("_").join(" ") : "";

  return (
    <Box width={[1]} p={[2]} key={source}>
      <Text
        fontWeight={"bold"}
        style={{ textTransform: "capitalize" }}
        mb={"-18px"}
      >
        {title} {has(data, "unit") && data.unit !== "" ? `(${data.unit})` : ""}
      </Text>

      {has(data, "unit") && get(data, "data_type", "") === "Number" && (
        <Box>
          <TextFieldInput
            source={`${source}.value`}
            value={get(formData, `${source}.value`, "")}
            type={"number"}
            label={translate("resources.products.fields.value_number")}
            updateField={updateField}
            style={{ width: 300 }}
            data={data}
            sourceParent={source}
          />
        </Box>
        // <RaNumberInput
        //   label={"resources.products.fields.value_number"}
        //   source={`${source}.value`}
        //   style={{ width: 256 }}
        // />
      )}
      {get(data, "data_type", "") === "Text" && (
        <Box>
          <TextFieldAttInput
            source={`${source}.value`}
            value={get(formData, `${source}.value`, "")}
            label={translate("resources.products.fields.value_text")}
            updateField={updateField}
            style={{ width: 256 }}
            data={data}
            sourceParent={source}
          />
        </Box>
        // <RaTextInput
        //   label={"resources.products.fields.value_text"}
        //   source={`${source}.value`}
        //   style={{ width: 256 }}
        // />
      )}

      {get(data, "data_type", "") === "Rich Text" && (
        <Box width={["600px"]}>
          <RTDescriptionProduct
            resource={"products"}
            label={"resources.products.fields.description"}
            source={`${source}.description`}
            toolbar={toolbar}
            data={data}
            sourceParent={source}
            updateField={updateField}
          />
        </Box>
      )}
      {/* {(get(data, "data_type", "") === "Text" ||
        get(data, "data_type", "") === "Number") && (
        <Box width={["600px"]} py={[2]}>
          <TextAreaInput
            source={`${source}.description`}
            value={get(formData, `${source}.description`, "")}
            label={translate("resources.products.fields.description")}
            updateField={updateField}
            style={{ width: 500 }}
          />
        </Box>
        // <RaTextArea
        //   source={`${source}.description`}
        //   label={"resources.products.fields.description"}
        //   rows={6}
        //   style={{ width: 500 }}
        //   InputLabelProps={{
        //     shrink: true,
        //   }}
        // />
      )} */}
      {get(data, "data_type", "") === "Yes/No" && (
        <Box>
          <BooleanFieldInput
            source={`${source}.switch`}
            label={translate("resources.products.fields.switch")}
            updateField={updateField}
            value={get(formData, `${source}.switch`, false)}
            data={data}
            sourceParent={source}
          />
        </Box>
        // <BooleanInput
        //   label={"resources.products.fields.switch"}
        //   source={`${source}.switch`}
        // />
      )}
    </Box>
  );
};

export default AttributesFields;
