import React from "react";
import { Flex } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { StyledTableCell, StyledTableRow } from "../../../../components";
import { useLocation, useHistory } from "react-router-dom";
const ListAmendment = (props) => {
  const { list, translate } = props;
  const location = useLocation();
  const history = useHistory();
  const onRowClick = (item) => {
    if (item && item.id && location.pathname !== "") {
      history.replace(`${location.pathname}/${item.id}`);
    }

    //location, history
    // if (item && item.status === "Requested") {
    //   console.log(props);
    //
    // }
  };
  return (
    <Flex width={[1]} flexDirection='column' bg={"#FFFFFF"}>
      <Flex width={[1]} flexDirection='column' p={[2]}>
        <TableContainer component={"div"}>
          <Table aria-label='shipment table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  {translate("resources.orders.fields.reference")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.fields.type")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.fields.status")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.labels.items")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.fields.refund_amount")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.labels.shipment")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.fields.created_at")}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <StyledTableRow
                  key={index}
                  onClick={() => onRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  <StyledTableCell component='th' scope='row'>
                    {row.reference}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {row.type}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {row.item_cancel}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {row.refund_amount}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {row.shipment_status}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {moment(row.created_at).format("DD-MMM-YYYY HH:mm")}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
};

export default ListAmendment;
