import React, { useState } from "react";
// import { TreeSelect } from "antd";
import { Box } from "rebass";
import { get } from "lodash";
import { Tree } from "antd";
import { dataProvider } from "../../../../restClient";
// const { SHOW_PARENT, TreeNode } = TreeSelect

const CategoriesTree = (props) => {
  const { dataSource, formValues, websiteId, contentCategory } = props;

  const [expandedKeys, setExpandedKeys] = useState(contentCategory.categories);
  const [checkedKeys, setCheckedKeys] = useState(contentCategory.categories);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [state, setState] = useState({
    value: contentCategory.categories,
    contentCategory: contentCategory,
  });
  const onChange = (value) => {
    // console.log("onChange ", value)
    // console.log(contentCategory)

    const params = {
      website_id: websiteId,
      product_id: formValues.id,
      site_id: formValues.site_id,
      categories: value,
    };
    if (state.contentCategory && state.contentCategory.id === 0) {
      dataProvider
        .create("productCategories", { data: params })
        .then((response) => {
          // console.log("create", response.data)
          if (response && response.data) {
            setState({
              ...state,
              contentCategory: response.data,
              value: get(response, "data.categories", value),
            });
          }
        });
    } else {
      dataProvider
        .update("productCategories", {
          id: state.contentCategory.id,
          data: params,
          previousData: contentCategory,
        })
        .then((response) => {
          // console.log("update", response.data)
          if (response && response.data) {
            setState({
              ...state,
              contentCategory: response.data,
              value: get(response, "data.categories", value),
            });
          }
        });
    }
    setState({ value });
  };
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (newValue) => {
    // console.log("onCheck", newValue);
    setCheckedKeys(newValue);
    onChange(newValue);
    // updateField(joinSoureParent, newValue);
  };

  return (
    <Box width={[1]}>
      <Tree
        checkable
        selectable={false}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        treeData={dataSource}
      />
    </Box>
  );
};

export default CategoriesTree;
