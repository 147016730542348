import React from "react"
import { get } from "lodash"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import { useTranslate } from "react-admin"
import Chip from "@material-ui/core/Chip"
const ShowFieldSKu = ({ record }) => {
  const translate = useTranslate()
  const variants = get(record, "variant.variants", [])
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        style={{ textTransform: "capitalize" }}
      >
        {translate("resources.products.label.show_sku")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="product-variants-dialog-title"
        open={open}
        maxWidth={"lg"}
      >
        <DialogTitle id="product-variants-dialog-title">
          {translate("resources.products.fields.sku")}
        </DialogTitle>

        <List>
          {variants.map((item, index) => {
            return (
              <ListItem
                button
                key={index}
                style={{ paddingRight: 16, paddingLeft: 16 }}
              >
                <Chip label={item.sku} />
              </ListItem>
            )
          })}
        </List>
      </Dialog>
    </div>
  )
}
export default ShowFieldSKu
