import React, { useState } from "react"
import { useQueryWithStore, Loading } from "react-admin"
import { Flex } from "rebass"
import { get, filter } from "lodash"
import Button from "@material-ui/core/Button"
import { FieldArray } from "react-final-form-arrays"
import { Field } from "react-final-form"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Delete"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import Divider from "@material-ui/core/Divider"
import EditSelectChipInput from "../EditSelectChipInput"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const defaultState = {
  open: false,
  message: "Please choice other",
}

const renderOptionsFields = (
  items,
  index,
  fields,
  variantData,
  formData,
  setState,
  updateField
) => {
  // const { formData } = this.props
  const onChangeSelect = (e, v, input) => {
    const options = get(formData, "variant.options", [])
    const isEmpty = filter(options, function (o) {
      return o.name === v.props.value
    })
    // console.log(v)
    if (isEmpty.length === 0) {
      input.onChange(v.props.value)
    } else {
      setState({
        open: true,
        message: v.props.value + " Already added",
      })
    }
  }

  const values = get(formData, `${items}.values`, [])
  const name = get(formData, `${items}.name`, "")
  return (
    <Box key={index}>
      <Flex
        key={index}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Box>
          <Field
            key={index}
            label="Options"
            name={`${items}.name`}
            placeholder="variant"
          >
            {(props) => (
              <Select
                name={props.input.name}
                value={props.input.value}
                onChange={(e, v) => onChangeSelect(e, v, props.input)}
                style={{ width: 180 }}
              >
                {variantData.map((item, indexx) => {
                  return (
                    <MenuItem value={item.code} key={indexx}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          </Field>
        </Box>
        <Box width={1} pr={2} pl={2}>
          {name !== "" && (
            <Field
              label={""}
              name={`${items}.values`}
              component={EditSelectChipInput}
              dataOptions={values}
              updateField={updateField}
            />
          )}
        </Box>
        <Box>
          <IconButton onClick={() => fields.remove(index)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Flex>
      <Divider />
    </Box>
  )
}

const CreateFormVariants = (props) => {
  const { formData, translate, updateField } = props
  const [state, setState] = useState(defaultState)
  const { loading, data } = useQueryWithStore({
    type: "getList",
    resource: "variants",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "ASC" },
      filters: { site_id: formData.site_id },
    },
  })

  const handleRequestClose = () => {
    setState({
      ...state,
      open: false,
    })
  }

  const onAddOptions = (fields) => {
    fields.push({})
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <FieldArray name="variant.options">
        {({ fields }) => {
          return (
            <Box>
              <Box>
                {fields.map((item, index) =>
                  renderOptionsFields(
                    item,
                    index,
                    fields,
                    data,
                    formData,
                    setState,
                    updateField
                  )
                )}
              </Box>
              <Flex flexDirection={"row"} justifyContent={"center"} mt={[2]}>
                <Button
                  variant={"contained"}
                  color="primary"
                  onClick={() => onAddOptions(fields)}
                >
                  {translate("resources.buttons.add_variant")}
                </Button>
              </Flex>
            </Box>
          )
        }}
      </FieldArray>
      <Snackbar
        open={state.open}
        autoHideDuration={4000}
        onClose={handleRequestClose}
      >
        <Alert onClose={handleRequestClose} severity="error">
          {state.message}
        </Alert>
      </Snackbar>
    </Flex>
  )
}

export default CreateFormVariants
