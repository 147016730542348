import React from "react"
import { get, keys } from "lodash"
import { Flex, Box } from "rebass"
import {
  FormDataConsumer,
  useQueryWithStore,
  Loading,
  required,
} from "react-admin"
import { RaTextInput } from "./"
import { Attributes } from "./Attributes"

export const FormFields = (props) => {
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "attributes",
    payload: {
      pagination: { page: 1, perPage: 200 },
      sort: { field: "id", order: "ASC" },
      filter: {},
    },
  })
  if (loading) {
    return <Loading />
  }
  return (
    <Flex flexDirection={"column"} px={[2]}>
      <RaTextInput
        source="name"
        label={"resources.attributeSet.fields.name"}
        style={{ width: 300 }}
        InputLabelProps={{ shrink: true }}
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <Box width={[1]} px={[2]}>
            <Attributes
              {...props}
              formData={formData}
              myOptions={newData(data)}
            />
          </Box>
        )}
      </FormDataConsumer>
    </Flex>
  )
}

function newData(data) {
  let newData = []
  newData = data.map((item) => {
    const valueLocale = get(item, "locale", {})
    const keyLocale = keys(valueLocale)
    const newItem = {
      value: item.id,
      label:
        keyLocale && keyLocale.length > 0
          ? get(item, `locale.${keyLocale[0]}.name`, "")
          : item.code,
    }
    return newItem
  })
  return newData
}
