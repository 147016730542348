import React, { Children, isValidElement, useRef } from "react"

import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import TabbedFormView from "./TabbedFormView"
import {
  useTranslate,
  sanitizeEmptyValues,
  getFormInitialValues,
} from "react-admin"
import get from "lodash/get"

const WizardEditorForm = ({
  initialValues,
  defaultValue,
  saving,

  ...props
}) => {
  let redirect = useRef(props.redirect)
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect
  }

  const translate = useTranslate()
  // const classes = useStyles()

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  )

  const submit = (values) => {
    const finalRedirect =
      typeof redirect.current === undefined ? props.redirect : redirect.current
    const finalValues = sanitizeEmptyValues(finalInitialValues, values)

    props.save(finalValues, finalRedirect)
  }

  return (
    <Form
      key={props.version}
      initialValues={finalInitialValues}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
        updateField: ([field, value], state, utils) => {
          utils.changeValue(state, field, () => value)
        },
      }}
      setRedirect={setRedirect}
      keepDirtyOnReinitialize
      subscription={defaultSubscription}
      {...props}
      render={(formProps) => {
        // console.log(formProps)
        // console.log(formProps)
        return (
          <TabbedFormView
            saving={formProps.submitting || saving}
            translate={translate}
            {...props}
            {...formProps}
          />
        )
      }}
    />
  )
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

export default WizardEditorForm

export const findTabsWithErrors = (children, errors) => {
  return Children.toArray(children).reduce((acc, child) => {
    if (!isValidElement(child)) {
      return acc
    }

    const inputs = Children.toArray(child.props.children)

    if (
      inputs.some(
        (input) => isValidElement(input) && get(errors, input.props.source)
      )
    ) {
      return [...acc, child.props.label]
    }

    return acc
  }, [])
}
