import React from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { RadioButtonGroupInput } from "react-admin";
import { RaNumberInput } from "../../../../components";
import { PaperCard } from "./PaperCard";
import { dataPromoType, dataPromoTypeProduct } from "./utils";

const PercentageField = (props) => {
  const { sourceParent } = props;
  return (
    <RaNumberInput
      style={{ width: 170 }}
      source={sourceParent}
      label='resources.promotions.fields.promotion_amount'
      notetext='resources.promotions.note_text.promotion_amount'
    />
  );
};

const PromotionTypeFields = (props) => {
  const { translate, formData } = props;
  const typeValue = get(formData, "type", "");
  const promotionRule = get(formData, "promotion_rule", "");
  console.log("formData", formData);
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.promotion_type")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"} p={[2]}>
          <RadioButtonGroupInput
            label={""}
            row={false}
            source='type'
            choices={
              promotionRule === "Product-based"
                ? dataPromoTypeProduct
                : dataPromoType
            }
          />
          {typeValue === dataPromoType[0].id && (
            <PercentageField sourceParent={"criteria.percentage.percentage"} />
          )}
          {typeValue === dataPromoType[1].id && (
            <PercentageField sourceParent={"criteria.amount.amount"} />
          )}

          {/** Fixed Price**/}
          {typeValue === dataPromoType[3].id && (
            <Flex width={[1]} flexDirection={"row"}>
              <RaNumberInput
                style={{ width: 210, marginRight: 20 }}
                source={"criteria.fixed_price.minimum_order_amount"}
                label='resources.promotions.fields.min_order_amount'
                notetext='resources.promotions.note_text.min_order_amount'
              />
              <RaNumberInput
                style={{ width: 210, marginRight: 20 }}
                source={"criteria.fixed_price.minimum_order_qty"}
                label='resources.promotions.fields.minimum_order_qty'
                notetext='resources.promotions.note_text.minimum_order_qty'
              />
              <RaNumberInput
                style={{ width: 170 }}
                source={"criteria.fixed_price.price_per_product"}
                label='resources.promotions.fields.price_per_product'
                notetext='resources.promotions.note_text.blank'
              />
            </Flex>
          )}
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default PromotionTypeFields;
