import crypto from "crypto";
import { filter, sortBy } from "lodash";

const generateRandomKey = () => {
  let mykey = crypto.randomBytes(6).toString("base64");

  return mykey;
};

const formatChoices = (choices) => choices.map(formatChoice);
const formatChoice = (choice) => {
  return {
    value: choice.id,
    text: choice.name,
    category: choice,
  };
};

const listChild = (dataSource, tier, child) => {
  const myId = generateRandomKey();
  const myChild = filter(dataSource, function (o) {
    return o.parent_id === child.id;
  });

  const newChildList = sortBy(myChild, (o) => o.sort_order);
  let newChildren = newChildList.map((childSub) => {
    const myIdChild = generateRandomKey();
    const mychild = {
      id: myIdChild,
      title: childSub.name,
      parent_id: myId,
      category_id: childSub.id,
      parent_category_id: child.id,
      tier: tier,
      category: childSub,
      children: listGrandChild(dataSource, tier + 1, childSub),
    };
    return mychild;
  });
  return newChildren;
};

const listGrandChild = (dataSource, tier, child) => {
  const myId = generateRandomKey();
  const myChild = filter(dataSource, function (o) {
    return o.parent_id === child.id;
  });
  const newChildList = sortBy(myChild, (o) => o.sort_order);
  let newChildren = newChildList.map((childSub) => {
    const myIdChild = generateRandomKey();
    const mychild = {
      id: myIdChild,
      title: childSub.name,
      parent_id: myId,
      category_id: childSub.id,
      parent_category_id: child.id,
      tier: tier,
      category: childSub,
      children: [],
    };
    return mychild;
  });
  return newChildren;
};

const listCategories = (dataSource) => {
  let list = [];
  const parent = filter(dataSource, function (o) {
    return o.parent_id === null;
  });

  const myOrderedArray = sortBy(parent, (o) => o.sort_order);

  myOrderedArray.map((category) => {
    const myId = generateRandomKey();
    const myChild = filter(dataSource, function (o) {
      return o.parent_id === category.id;
    });
    const newChildList = sortBy(myChild, (o) => o.sort_order);
    let newChildren = newChildList.map((child) => {
      const myIdChild = generateRandomKey();
      const mychild = {
        id: myIdChild,
        title: child.name,
        parent_id: myId,
        category_id: child.id,
        parent_category_id: category.id,
        tier: 1,
        category: child,
        children: listChild(dataSource, 2, child),
      };
      return mychild;
    });
    const mytest = {
      children: newChildren,
      id: myId,
      title: category.name,
      parent_id: "root",
      category_id: category.id,
      parent_category_id: null,
      tier: 0,
      category: category,
    };
    list.push(mytest);
    return mytest;
  });

  return list;
};

const treeCanDrop = ({
  node,
  prevPath,
  prevParent,
  prevTreeIndex,
  nextPath,
  nextParent,
  nextTreeIndex,
}) => {
  if (prevParent === null && nextParent === null) {
    return true;
  } else {
    if (nextParent !== null) {
      // if (
      //   prevParent !== null &&
      //   ((prevParent.target === "content" && nextParent.target === "content") ||
      //     (prevParent.target === "root" && nextParent.target === "root"))
      // ) {
      //   return true
      // }
      // if (prevParent !== null && prevParent.id === nextParent.id) {
      //   return true
      // }
      if (prevParent !== null) {
        return true;
      }
    }

    return false;
  }
};

const listType = [
  { id: "Attribute", name: "Attribute" },
  { id: "Variant", name: "Variant" },
];

const defaultListFilter = [
  {
    children: [
      {
        id: "gY65voFM",
        title: "Featured",
        parent_id: "rPXB96hW",
        category_id: 2,
        parent_category_id: 1,
        tier: 1,
        target: "content",
        children: [
          {
            id: "pWp08rRU",
            title: "New Arrivals",
            parent_id: "PK+AN/u3",
            category_id: 3,
            parent_category_id: 2,
            tier: "content",
            target: 2,
          },
          {
            id: "46yRu45y",
            title: "Bestsellers",
            parent_id: "PK+AN/u3",
            category_id: 4,
            parent_category_id: 2,
            tier: "content",
            target: 2,
          },
          {
            id: "03Pf688l",
            title: "Exclusives",
            parent_id: "PK+AN/u3",
            category_id: 5,
            parent_category_id: 2,
            tier: "content",
            target: 2,
          },
          {
            id: "MBp69F/m",
            title: "Sale",
            parent_id: "PK+AN/u3",
            category_id: 6,
            parent_category_id: 2,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "38NpQW4G",
        title: "SHOES",
        parent_id: "rPXB96hW",
        category_id: 7,
        parent_category_id: 1,
        tier: 1,
        target: "content",
        children: [
          {
            id: "Yqvvt2LB",
            title: "Running",
            parent_id: "EPy2FKhS",
            category_id: 8,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "U4d8+rja",
            title: "Trail Running & Hiking",
            parent_id: "EPy2FKhS",
            category_id: 9,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "2ESAURIi",
            title: "Training & Gym",
            parent_id: "EPy2FKhS",
            category_id: 10,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "YANW6aBT",
            title: "Tennis",
            parent_id: "EPy2FKhS",
            category_id: 11,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "N55sfD1U",
            title: "Volleyball",
            parent_id: "EPy2FKhS",
            category_id: 12,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "4XXimDRa",
            title: "Indoor",
            parent_id: "EPy2FKhS",
            category_id: 13,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "1uKoy11u",
            title: "Others",
            parent_id: "EPy2FKhS",
            category_id: 14,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
          {
            id: "yqJnknQB",
            title: "Sandals",
            parent_id: "EPy2FKhS",
            category_id: 15,
            parent_category_id: 7,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "iybyTmbQ",
        title: "CLOTHING",
        parent_id: "rPXB96hW",
        category_id: 16,
        parent_category_id: 1,
        tier: 1,
        target: "content",
        children: [
          {
            id: "GjS5bxIZ",
            title: "Tank Tops",
            parent_id: "WeRcEsoK",
            category_id: 17,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
          {
            id: "VSbMe3SN",
            title: "Jackets",
            parent_id: "WeRcEsoK",
            category_id: 18,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
          {
            id: "Php643F7",
            title: "Short Sleeve Shirts",
            parent_id: "WeRcEsoK",
            category_id: 19,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
          {
            id: "Xv4RFKuy",
            title: "Long Sleeve Shirts",
            parent_id: "WeRcEsoK",
            category_id: 20,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
          {
            id: "gkHH1Ib+",
            title: "Tights",
            parent_id: "WeRcEsoK",
            category_id: 21,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
          {
            id: "sS/0feAU",
            title: "Shorts",
            parent_id: "WeRcEsoK",
            category_id: 22,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
          {
            id: "LxIDNeXd",
            title: "Pants",
            parent_id: "WeRcEsoK",
            category_id: 23,
            parent_category_id: 16,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "eMNxPV0M",
        title: "ACCESSORIES",
        parent_id: "rPXB96hW",
        category_id: 24,
        parent_category_id: 1,
        tier: 1,
        target: "content",
        children: [
          {
            id: "nJo00ckd",
            title: "Caps & Headbands",
            parent_id: "Uu112xxA",
            category_id: 25,
            parent_category_id: 24,
            tier: "content",
            target: 2,
          },
          {
            id: "isLTQ4rn",
            title: "Socks",
            parent_id: "Uu112xxA",
            category_id: 26,
            parent_category_id: 24,
            tier: "content",
            target: 2,
          },
          {
            id: "kl8YpNPq",
            title: "Bags & Packs",
            parent_id: "Uu112xxA",
            category_id: 27,
            parent_category_id: 24,
            tier: "content",
            target: 2,
          },
          {
            id: "nmaL3Zbo",
            title: "Gloves",
            parent_id: "Uu112xxA",
            category_id: 28,
            parent_category_id: 24,
            tier: "content",
            target: 2,
          },
          {
            id: "4+ZMkF9V",
            title: "Arm & Calf Sleeves",
            parent_id: "Uu112xxA",
            category_id: 29,
            parent_category_id: 24,
            tier: "content",
            target: 2,
          },
          {
            id: "zvOVzd/4",
            title: "Equipment",
            parent_id: "Uu112xxA",
            category_id: 30,
            parent_category_id: 24,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "NR33OXJw",
        title: "Sportstyle",
        parent_id: "rPXB96hW",
        category_id: 31,
        parent_category_id: 1,
        tier: 1,
        target: "content",
        children: [
          {
            id: "c7+dVkWw",
            title: "Shoes",
            parent_id: "Kgx7OKTc",
            category_id: 32,
            parent_category_id: 31,
            tier: "content",
            target: 2,
          },
          {
            id: "GCRhN4FM",
            title: "Clothing",
            parent_id: "Kgx7OKTc",
            category_id: 33,
            parent_category_id: 31,
            tier: "content",
            target: 2,
          },
          {
            id: "7n5KohyX",
            title: "Accessories",
            parent_id: "Kgx7OKTc",
            category_id: 34,
            parent_category_id: 31,
            tier: "content",
            target: 2,
          },
        ],
      },
    ],
    id: "rPXB96hW",
    title: "MEN",
    parent_id: "root",
    category_id: 1,
    parent_category_id: null,
    tier: 0,
    target: "root",
  },
  {
    children: [
      {
        id: "QCFCtzm/",
        title: "Featured",
        parent_id: "CeruXWIA",
        category_id: 36,
        parent_category_id: 35,
        tier: 1,
        target: "content",
        children: [
          {
            id: "fwHd1oRR",
            title: "New Arrivals",
            parent_id: "8iWAUBmn",
            category_id: 37,
            parent_category_id: 36,
            tier: "content",
            target: 2,
          },
          {
            id: "E/yVtzCW",
            title: "Bestsellers",
            parent_id: "8iWAUBmn",
            category_id: 38,
            parent_category_id: 36,
            tier: "content",
            target: 2,
          },
          {
            id: "yprjXLOM",
            title: "Exclusives",
            parent_id: "8iWAUBmn",
            category_id: 39,
            parent_category_id: 36,
            tier: "content",
            target: 2,
          },
          {
            id: "qvF4BhhN",
            title: "Sale",
            parent_id: "8iWAUBmn",
            category_id: 40,
            parent_category_id: 36,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "iLTCN64k",
        title: "SHOES",
        parent_id: "CeruXWIA",
        category_id: 41,
        parent_category_id: 35,
        tier: 1,
        target: "content",
        children: [
          {
            id: "b3GIy+3I",
            title: "Running",
            parent_id: "y/62/jSj",
            category_id: 42,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "yHPSYEXP",
            title: "Trail Running & Hiking",
            parent_id: "y/62/jSj",
            category_id: 43,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "OjKnRVph",
            title: "Training & Gym",
            parent_id: "y/62/jSj",
            category_id: 44,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "nhyT5Dyd",
            title: "Tennis",
            parent_id: "y/62/jSj",
            category_id: 45,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "w4E2R5Sj",
            title: "Volleyball",
            parent_id: "y/62/jSj",
            category_id: 46,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "lCHXETmI",
            title: "Indoor",
            parent_id: "y/62/jSj",
            category_id: 47,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "mKbFowiL",
            title: "Netball",
            parent_id: "y/62/jSj",
            category_id: 48,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
          {
            id: "lZ0GpGfZ",
            title: "Others",
            parent_id: "y/62/jSj",
            category_id: 49,
            parent_category_id: 41,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "Js6lxZov",
        title: "CLOTHING",
        parent_id: "CeruXWIA",
        category_id: 50,
        parent_category_id: 35,
        tier: 1,
        target: "content",
        children: [
          {
            id: "D8N+pJaf",
            title: "Tank Tops",
            parent_id: "FB6BYJ2s",
            category_id: 51,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "UYCjzkTH",
            title: "Jackets",
            parent_id: "FB6BYJ2s",
            category_id: 52,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "WEaRG5e0",
            title: "Short Sleeves Shirts",
            parent_id: "FB6BYJ2s",
            category_id: 53,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "prbuuGiH",
            title: "Long Sleeves Shirts",
            parent_id: "FB6BYJ2s",
            category_id: 54,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "DjAKXKM6",
            title: "Sports Bras",
            parent_id: "FB6BYJ2s",
            category_id: 55,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "DaBdtSiw",
            title: "Tights",
            parent_id: "FB6BYJ2s",
            category_id: 56,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "d6YUA2hu",
            title: "Shorts",
            parent_id: "FB6BYJ2s",
            category_id: 57,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "hhJ3Hsqk",
            title: "Pants",
            parent_id: "FB6BYJ2s",
            category_id: 58,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
          {
            id: "HL9qvgiz",
            title: "Skorts",
            parent_id: "FB6BYJ2s",
            category_id: 59,
            parent_category_id: 50,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "S4akaQwj",
        title: "ACCESSORIES",
        parent_id: "CeruXWIA",
        category_id: 60,
        parent_category_id: 35,
        tier: 1,
        target: "content",
        children: [
          {
            id: "G969KcIr",
            title: "Caps & Headbands",
            parent_id: "6U72H9/y",
            category_id: 61,
            parent_category_id: 60,
            tier: "content",
            target: 2,
          },
          {
            id: "C/yAV8n9",
            title: "Bags & Packs",
            parent_id: "6U72H9/y",
            category_id: 62,
            parent_category_id: 60,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "uXm76s4s",
        title: "Sportstyle",
        parent_id: "CeruXWIA",
        category_id: 63,
        parent_category_id: 35,
        tier: 1,
        target: "content",
        children: [
          {
            id: "ZHb4NWc3",
            title: "Shoes",
            parent_id: "pu28Radu",
            category_id: 64,
            parent_category_id: 63,
            tier: "content",
            target: 2,
          },
          {
            id: "GrwW0Fkv",
            title: "Clothing",
            parent_id: "pu28Radu",
            category_id: 65,
            parent_category_id: 63,
            tier: "content",
            target: 2,
          },
          {
            id: "YIMlyA90",
            title: "Accessories",
            parent_id: "pu28Radu",
            category_id: 66,
            parent_category_id: 63,
            tier: "content",
            target: 2,
          },
        ],
      },
    ],
    id: "CeruXWIA",
    title: "WOMEN",
    parent_id: "root",
    category_id: 35,
    parent_category_id: null,
    tier: 0,
    target: "root",
  },
  {
    children: [
      {
        id: "gqi9vCIm",
        title: "Featured",
        parent_id: "lb5ZwROj",
        category_id: 68,
        parent_category_id: 67,
        tier: 1,
        target: "content",
        children: [
          {
            id: "u1lt/iVY",
            title: "New Arrivals",
            parent_id: "DodwluSC",
            category_id: 69,
            parent_category_id: 68,
            tier: "content",
            target: 2,
          },
          {
            id: "4J5i8Zlc",
            title: "Bestsellers",
            parent_id: "DodwluSC",
            category_id: 70,
            parent_category_id: 68,
            tier: "content",
            target: 2,
          },
          {
            id: "whb3r8w7",
            title: "Sale",
            parent_id: "DodwluSC",
            category_id: 71,
            parent_category_id: 68,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "Zv/D53rw",
        title: "SHOP BY AGE GROUP",
        parent_id: "lb5ZwROj",
        category_id: 72,
        parent_category_id: 67,
        tier: 1,
        target: "content",
        children: [
          {
            id: "kYOx6L6C",
            title: "Pre-School",
            parent_id: "fifPnsyT",
            category_id: 73,
            parent_category_id: 72,
            tier: "content",
            target: 2,
          },
          {
            id: "yopVAQgn",
            title: "Grade School",
            parent_id: "fifPnsyT",
            category_id: 74,
            parent_category_id: 72,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "myxbYhf+",
        title: "SHOES",
        parent_id: "lb5ZwROj",
        category_id: 75,
        parent_category_id: 67,
        tier: 1,
        target: "content",
        children: [
          {
            id: "5/ThInO9",
            title: "Running",
            parent_id: "FpbMneH4",
            category_id: 76,
            parent_category_id: 75,
            tier: "content",
            target: 2,
          },
          {
            id: "ZNiNxZU0",
            title: "Others",
            parent_id: "FpbMneH4",
            category_id: 77,
            parent_category_id: 75,
            tier: "content",
            target: 2,
          },
        ],
      },
      {
        id: "3mp+JntI",
        title: "Sportstyle",
        parent_id: "lb5ZwROj",
        category_id: 78,
        parent_category_id: 67,
        tier: 1,
        target: "content",
        children: [
          {
            id: "GL0Mycqz",
            title: "Shoes",
            parent_id: "OLinKuO+",
            category_id: 79,
            parent_category_id: 78,
            tier: "content",
            target: 2,
          },
          {
            id: "3vI39fIx",
            title: "Clothing",
            parent_id: "OLinKuO+",
            category_id: 80,
            parent_category_id: 78,
            tier: "content",
            target: 2,
          },
          {
            id: "DbOr0Rvf",
            title: "Accessories",
            parent_id: "OLinKuO+",
            category_id: 81,
            parent_category_id: 78,
            tier: "content",
            target: 2,
          },
        ],
      },
    ],
    id: "lb5ZwROj",
    title: "KIDS",
    parent_id: "root",
    category_id: 67,
    parent_category_id: null,
    tier: 0,
    target: "root",
  },
];

export {
  listType,
  generateRandomKey,
  listCategories,
  formatChoices,
  treeCanDrop,
  defaultListFilter,
  listChild,
};
