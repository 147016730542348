import React from "react";
import { Box, Flex } from "rebass";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useTranslate, FormDataConsumer } from "react-admin";
import { BackIcon } from "../../../components/icon_svg";
import Content from "./Content";
import { useWindowSize } from "../../../components";

const ContentField = (props) => {
  const translate = useTranslate();
  const styleWindow = useWindowSize();
  const { match, updateField, isCreate } = props;

  const { width, height } = styleWindow;
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        p={[3]}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          zIndex: 1000,
          top: 72,
          position: "fixed",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Button
          to={`${match.url}`}
          component={Link}
          color='primary'
          style={{ textTransform: "capitalize" }}
        >
          <BackIcon color={"primary"} /> {translate("resources.buttons.back")}
        </Button>
      </Flex>
      <Box width={[1]} pt={["0px"]} mt={["-28px"]} pl={[0]}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Content
                translate={translate}
                widthScreen={width - 256}
                heightScreen={height}
                values={formData}
                updateField={updateField}
                statusForm={isCreate ? "create" : "edit"}
              />
            );
          }}
        </FormDataConsumer>
        {/* {localeValues && (
                
              )} */}
      </Box>
    </Flex>
  );
};

export default ContentField;
