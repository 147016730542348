import React, { useState } from "react";
import { Box } from "rebass";
import { get } from "lodash";
import { Button } from "@material-ui/core";
import { useRefresh, useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RestApiCRUD } from "../../../../restClient";

const SetPaidFields = (props) => {
  const { translate, record } = props;
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const onSetPaid = () => {
    setLoading(true);
    RestApiCRUD(`/orders/${record.id}/paid`, "GET")
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res) {
          refresh();
          notify("resources.orders.messages.set_to_paid");
        } else {
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify("resources.errors.please_try_again", "warning", {
          smart_count: 1,
        });
      });
  };
  if (get(record, "payment_info.status", "") === "Paid") {
    return <Box pr={[2]} />;
  }
  return (
    <Box pr={[2]}>
      <Button color={"primary"} onClick={() => onSetPaid()}>
        {loading ? (
          <CircularProgress size={14} />
        ) : (
          translate("resources.buttons.set_to_paid")
        )}
      </Button>
    </Box>
  );
};
export default SetPaidFields;
