import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { RadioButtonGroupInput, SelectInput } from "react-admin";
import { PaperCard } from "./PaperCard";
import { dataCustomerBuy, dataCustomerFrom } from "./utils";
import { RaNumberInput } from "../../../../components";
import CategoryFields from "./CategoryFields";
import ProductFields from "./ProductFields";
const CustomerBuyFields = (props) => {
  const { translate, formData, listCategories } = props;
  const buyType = get(formData, "criteria.buy_x_get_y.buys.type", "");
  const buyFrom = get(formData, "criteria.buy_x_get_y.buys.from", "");
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.customer_buys")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <RadioButtonGroupInput
            row={false}
            label={""}
            source={`criteria.buy_x_get_y.buys.type`}
            choices={dataCustomerBuy}
            defaultValue={dataCustomerBuy[0].id}
          />
          {buyType === dataCustomerBuy[0].id && (
            <RaNumberInput
              source="criteria.buy_x_get_y.buys.qty"
              label={"resources.promotions.fields.qty"}
            />
          )}
          {buyType === dataCustomerBuy[1].id && (
            <RaNumberInput
              source="criteria.buy_x_get_y.buys.amount"
              label={"resources.promotions.fields.amount"}
            />
          )}
          <SelectInput
            source="criteria.buy_x_get_y.buys.from"
            label={"resources.promotions.fields.any_items_from"}
            choices={dataCustomerFrom}
            style={{ width: 300 }}
          />
          {formData.website_id && buyFrom !== "" && (
            <Fragment>
              {buyFrom === dataCustomerFrom[0].id && (
                <CategoryFields
                  {...props}
                  categories={listCategories}
                  categorySource={"buy_x_get_y.buys"}
                />
              )}
              {buyFrom === dataCustomerFrom[1].id && (
                <ProductFields {...props} productSource={"buy_x_get_y.buys"} />
              )}
            </Fragment>
          )}

          <RaNumberInput
            source="priority"
            label={"resources.promotions.fields.priority"}
            notetext={"resources.promotions.note_text.priority"}
          />
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default CustomerBuyFields;
