import React from "react";
import { Flex, Box } from "rebass";
import { PaperCard } from "./PaperCard";
import { RaNumberInput } from "../../../../components";

const RequirementFields = (props) => {
  const { translate, source, sourceMoq } = props;
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.requirements")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <RaNumberInput
            style={{ width: 256 }}
            source={source}
            label='resources.promotions.fields.min_order_amount'
            notetext='resources.promotions.note_text.min_order_amount'
            defaultValue={0}
          />
          <RaNumberInput
            style={{ width: 256 }}
            // source={"minimum_order_qty"}
            source={sourceMoq}
            label='resources.promotions.fields.minimum_order_qty'
            notetext='resources.promotions.note_text.minimum_order_qty'
            defaultValue={0}
          />
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default RequirementFields;
