import React from "react";
import {
  BetterCreateCustomForm,
  BetterCreate,
  DetailAction,
  FooterToolbar,
} from "../../components";
import { FormFields } from "./components";
import { getSiteID } from "../../restClient";
const initValues = {
  site_id: getSiteID(),
  active: true,
  name: "",
};

export const CreateCollection = (props) => {
  return (
    <BetterCreate
      {...props}
      actions={<DetailAction title={"resources.collections.new_page"} />}
    >
      <BetterCreateCustomForm
        {...props}
        toolbar={<FooterToolbar />}
        initialValues={initValues}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <FormFields {...props} isCreate={true} />
      </BetterCreateCustomForm>
    </BetterCreate>
  );
};
