import React from "react"
import { find } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Text } from "rebass"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"

import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
export const AttributeField = props => {
  const { translate, onUpdateFilter, attribute, filter, attributes } = props
  const classes = useStyles()
  const [attributeId, setAttributeId] = React.useState(filter.attribute_id)

  const handleChange = event => {
    const listAttribute = find(attributes, function(o) {
      return o.id === event.target.value
    })
    setAttributeId(event.target.value)
    onUpdateFilter("attribute_id", event.target.value, listAttribute)
  }

  return (
    <Box width={[1]}>
      <FormControl className={classes.formControl}>
        <InputLabel id="attributes-filter-select-label">
          {translate("resources.filters.fields.attribute")}
        </InputLabel>
        <Select
          labelId="attributes-filter-select-label"
          id="attributes-filter-select"
          value={attributeId}
          onChange={handleChange}
          style={{ width: 256 }}
        >
          {attributes &&
            attributes.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
      {attribute && attribute.data_type && (
        <Box p={[2]}>
          <Text fontSize={[0]} fontWeight={"bold"}>
            {translate("resources.categories.fields.data_type")}
          </Text>
          <Text fontSize={[0]} pt={[1]}>
            {attribute.data_type}
          </Text>
        </Box>
      )}
    </Box>
  )
}
