import React, { useState } from "react"
import { keyBy } from "lodash"
import { Box } from "rebass"
import { get, find } from "lodash"
import {
  useQueryWithStore,
  Pagination,
  Loading,
  TextField,
  BooleanField,
} from "react-admin"
import {} from "lodash"
import { useLocation } from "react-router-dom"
import { CreateButton, ButtonEditView, Datagrid } from "../../../../components"
import { dataCountry } from "../../../websites"
import { HeaderBox, ContentBox } from "../../../../utils"
const CountryField = ({ record = {} }) => {
  const countryCode = get(record, "address.country")
  const nameCountry = find(dataCountry, function (o) {
    return o.id === countryCode
  })

  return <span>{nameCountry && nameCountry.name}</span>
}
CountryField.defaultProps = { label: "Country" }

const ButtonEdit = (props) => {
  const { basePath, ...rest } = props
  const newBasePath = "/sources"
  return <ButtonEditView {...rest} basePath={newBasePath} />
}

const SourceDataGrid = (props) => {
  // const match = useRouteMatch()
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "sources",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { site_id: get(props, "id", 0) },
    },
  })
  if (loading) {
    return <Loading />
  }
  return (
    <Box width={[1]}>
      <HeaderBox px={[1]}>
        <CreateButton
          basePath={location.pathname}
          sizeIcon={"xsmall"}
          style={{ textTransform: "capitalize" }}
        />
      </HeaderBox>

      {data && (
        <ContentBox px={[1]} py={[3]}>
          <Datagrid
            {...props}
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath="/sources" // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField source="name" label="resources.sources.fields.name" />
            <TextField source="type" label="resources.sources.fields.type" />
            <TextField
              source="address.state"
              label="resources.sources.fields.state"
            />
            <CountryField
              source="address.country"
              label="resources.sources.fields.country"
            />
            <BooleanField
              source="active"
              label="resources.sources.fields.active"
            />
            <ButtonEdit {...props} />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={total}
          />
        </ContentBox>
      )}
    </Box>
  )
}

export default SourceDataGrid
