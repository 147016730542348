import React, { Fragment } from "react";
import { Flex, Box, Text } from "rebass";
import {
  FormDataConsumer,
  useTranslate,
  TextInput,
  required,
} from "react-admin";
import { Field } from "react-final-form";
import { RaNumberInput, ROLE_USER, ImageOlaSingleInput } from "./";
// import { dataType } from "./utils"
import { get } from "lodash";
import LocaleCategories from "./LocaleCategories";
import { ListFilters } from "./filters";
import { Paper } from "@material-ui/core";
import { getSiteID } from "../../../restClient";
import EditSearchChipInput from "../../products/components/EditSearchChipInput";

const FormFields = (props) => {
  const { permissions } = props;
  const translate = useTranslate();
  const onSaveFilter = (filters) => {
    // console.log(filters)
    props.updateField("filters", filters);
  };
  return (
    <Flex flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const SiteID =
            formData.site_id === getSiteID() ? formData.site_id : getSiteID();
          if (
            formData.site_id !== getSiteID() &&
            permissions === ROLE_USER.super_admin
          ) {
            props.updateField("site_id", SiteID);
          }
          return (
            <Fragment>
              <Box width={[1]} pt={[2]} pb={[2]}>
                {formData && formData.site_id && (
                  <LocaleCategories
                    {...rest}
                    formData={formData}
                    updateField={props.updateField}
                  />
                )}
              </Box>
              <ImageOlaSingleInput
                source={`icon`}
                toValue={"categories"}
                labelImage={translate("resources.categories.fields.icon")}
                label={"resources.categories.fields.icon"}
                accept="image/*"
                record={formData}
                {...props}
                basePath={"/categories"}
              />
              <Box width={[1]} py={[2]}>
                <RaNumberInput
                  source="sort_order"
                  label="resources.categories.fields.sort_order"
                />
              </Box>
              <Box width={[1]} pb={[2]}>
                <TextInput
                  source="slug"
                  label="resources.categories.fields.slug"
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  validate={[required()]}
                />
              </Box>
              <Box width={[1]} pt={[2]}>
                <Text fontWeight={"bold"} fontSize={[0]}>
                  {translate("resources.products.fields.tags")}
                </Text>
                <Field
                  label={""}
                  name={`tags`}
                  component={EditSearchChipInput}
                  dataOptions={get(formData, "tags", [])}
                  updateField={props.updateField}
                />
                {/* <Text fontSize={[0]}>
                        {translate(
                          "resources.products.note_text.searchable_colors"
                        )}
                      </Text> */}
              </Box>
              <Paper style={{ padding: 16 }} elevation={3}>
                <Box width={[1]} py={[1]}>
                  <Text fontWeight={"bold"} fontSize={[2]}>
                    {translate("resources.categories.label.metadata")}
                  </Text>
                  <TextInput
                    source="metadata_title"
                    label="resources.categories.label.metadata_title"
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextInput
                    source="metadata_keywords"
                    label="resources.categories.label.metadata_keywords"
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextInput
                    source="metadata_description"
                    label="resources.categories.label.metadata_description"
                    fullWidth={true}
                    multiline={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextInput
                    source="seo_metadata.custom_canonical_page"
                    label="resources.categories.label.metadata_custom_canonical_page"
                    fullWidth={true}
                    multiline={false}
                    InputLabelProps={{ shrink: true }}
                    helperText={`note : fill "women" and without space`}
                  />
                </Box>
              </Paper>
              <ListFilters
                formData={formData}
                updateField={props.updateField}
                translate={translate}
                onSaveFilter={onSaveFilter}
              />
            </Fragment>
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
};

export default FormFields;
