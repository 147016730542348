import React from "react"
import { TextField, BooleanField } from "react-admin"
import { BetterList, ButtonEditView, HeaderTitle } from "../../components"
import CreateSiteDialog from "./components/CreateSiteDialog"

export const SitesList = (props) => {
  return (
    <BetterList
      {...props}
      actions={
        <HeaderTitle title="resources.sites.name">
          <CreateSiteDialog />
        </HeaderTitle>
      }
    >
      <TextField source="name" />
      <BooleanField source="active" />
      <ButtonEditView {...props} />
    </BetterList>
  )
}
