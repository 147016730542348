import crypto from "crypto";
import { filter, sortBy } from "lodash";

const generateRandomKey = () => {
  let mykey = crypto.randomBytes(6).toString("base64");

  return mykey;
};

export const listCategories = (dataSource) => {
  let list = [];
  const parent = filter(dataSource, function (o) {
    return o.parent_id === null;
  });

  const myOrderedArray = sortBy(parent, (o) => o.sort_order);

  myOrderedArray.map((category) => {
    const myId = generateRandomKey();
    const myChild = filter(dataSource, function (o) {
      return o.parent_id === category.id;
    });
    const newChildList = sortBy(myChild, (o) => o.sort_order);
    let newChildren = newChildList.map((child) => {
      const myIdChild = generateRandomKey();
      const mychild = {
        id: myIdChild,
        title: child.name,
        parent_id: myId,
        key: child.id,
        value: child.id,
        category_id: child.id,
        parent_category_id: category.id,
        tier: 1,
        category: child,
        children: listChild(dataSource, 2, child),
      };
      return mychild;
    });
    const mytest = {
      children: newChildren,
      id: myId,
      title: category.name,
      parent_id: "root",
      key: category.id,
      value: category.id,
      category_id: category.id,
      parent_category_id: null,
      tier: 0,
      category: category,
    };
    list.push(mytest);
    return mytest;
  });

  return list;
};

const listChild = (dataSource, tier, child) => {
  const myId = generateRandomKey();
  const myChild = filter(dataSource, function (o) {
    return o.parent_id === child.id;
  });

  const newChildList = sortBy(myChild, (o) => o.sort_order);
  let newChildren = newChildList.map((childSub) => {
    const myIdChild = generateRandomKey();
    const mychild = {
      id: myIdChild,
      title: childSub.name,
      parent_id: myId,
      key: childSub.id,
      value: childSub.id,
      category_id: childSub.id,
      parent_category_id: child.id,
      tier: tier,
      category: childSub,
    };
    return mychild;
  });
  return newChildren;
};
