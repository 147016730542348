import React, { useState } from "react"
import { union, uniqBy, map } from "lodash"
import Chip from "@material-ui/core/Chip"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { newDataColors } from "./utils"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    chip: {
      textTransform: "capitalize",
    },
  },
}))

const EditSelectColorChipInput = props => {
  const classes = useStyles()
  const { dataOptions } = props
  // eslint-disable-next-line no-unused-vars
  const [disabledIndex, setDisabledIndex] = useState(dataOptions)

  const valueOptions = map(dataOptions, item => {
    return { id: item.toLowerCase(), title: item }
  })
  const newData = union(dataOptions, newDataColors)
  const listData = map(newData, item => {
    return { id: item.toLowerCase(), title: item }
  })
  const onChangeChip = (e, v) => {
    const valueOptions1 = map(v, item => {
      return item.title
    })
    const check = union(disabledIndex, valueOptions1)
    props.updateField(props.input.name, check)
  }
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        disableClearable
        id="select-variant-standard"
        options={uniqBy(listData, "id")}
        value={valueOptions}
        onChange={onChangeChip}
        filterSelectedOptions={true}
        getOptionLabel={option => option.title}
        style={{ width: "100%" }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                label={option.title}
                {...getTagProps({ index })}
                disabled={disabledIndex.length > index}
              />
            )
          })
        }
        renderInput={params => (
          <TextField
            fullWidth
            {...params}
            variant="standard"
            label=""
            placeholder=""
          />
        )}
      />
    </div>
  )
}

export default EditSelectColorChipInput
