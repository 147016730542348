import React from "react"
import {
  Button,
  SaveButton,
  SelectInput,
  useUpdate,
  useNotify,
  FormWithRedirect,
  useTranslate,
} from "react-admin"
import IconCancel from "@material-ui/icons/Cancel"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { dataStatus } from "./"
import { Flex } from "rebass"
import HeaderFields from "./HeaderFields"
import FormFields from "./FormFields"
const DialogEditReview = (props) => {
  const { showDialog, setShowDialog, id, record } = props
  const translate = useTranslate()

  const [update, { loading }] = useUpdate("reviews")
  const notify = useNotify()

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    update(
      { payload: { id: id, data: values } },
      {
        onSuccess: ({ data }) => {
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("id", data.id)
          // console.log(data)
          notify("resources.notification.updated")
          setShowDialog(false)
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <div>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("resources.reviews.title")}
      >
        <DialogTitle>{translate("resources.reviews.title")}</DialogTitle>

        <FormWithRedirect
          resource="reviews"
          save={handleSubmit}
          initialValues={props.record}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <Flex flexDirection={"row"} width={[1]} alignItems={"center"}>
                  <HeaderFields record={record} />
                  <SelectInput
                    source={"status"}
                    label="resources.reviews.fields.status"
                    choices={dataStatus}
                  />
                </Flex>
                <FormFields record={record} />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </div>
  )
}

export default DialogEditReview
