import React from "react"
import { Create } from "react-admin"
import {
  BetterCustomForm,
  FooterToolbar,
  TopBarTitleAction,
} from "../../components"
import { useTranslate } from "react-admin"
import { MainTab } from "./components"

export const SiteCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create
      {...props}
      actions={
        <TopBarTitleAction title={translate("resources.sites.new_title")} />
      }
    >
      <BetterCustomForm {...props} toolbar={<FooterToolbar />}>
        <MainTab {...props} />
      </BetterCustomForm>
    </Create>
  )
}
