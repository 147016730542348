import React from "react"
import { useTranslate } from "react-admin"
import { Box, Flex, Text } from "rebass"
import { Rate } from "antd"
import { get } from "lodash"
import { TextRecord } from "../../../components"
import ProductField from "./ProductField"
const FormFields = (props) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <Box width={[1]}>
      <Flex flexDirection={"row"} sx={{ "&>div": { px: [3] } }}>
        <ProductField record={record} />

        <Box>
          <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
            {translate("resources.reviews.fields.rating")}
          </Text>
          <Rate disabled defaultValue={get(record, "rating", 0)} />
        </Box>
      </Flex>
      <Box width={[1]} px={[3]}>
        <TextRecord
          source={"subject"}
          label={"resources.reviews.fields.subject"}
          record={record}
        />
        <TextRecord
          source={"review"}
          label={"resources.reviews.fields.review"}
          record={record}
        />
      </Box>
    </Box>
  )
}

export default FormFields
