/* eslint-disable array-callback-return */
import React, { Component } from "react";
import SortableTree, { removeNodeAtPath } from "react-sortable-tree";
import { findIndex, find } from "lodash";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Flex, Box } from "rebass";
import "react-sortable-tree/style.css";
import "./filters/shortable-tree.css";
import { listCategories, treeCanDrop } from "./filters/utils";
// import DialogAutoComplete from "./filters/DialogAutoComplete"
import DialogRemoveItem from "./filters/DialogRemoveItem";
import { pink } from "@material-ui/core/colors";
import { dataProvider } from "../../../restClient";

class ListCategories extends Component {
  constructor(props) {
    super(props);

    // console.log(myparent)
    this.state = {
      treeData: listCategories(props.dataSource),
      open: false,
      openRemove: false,
      showCategory: 0,
      nodeChild: null,
      getNodeKey: null,
      getPathChild: null,
      nodeRemove: null,
      getNodeRemove: null,
      pathRemove: null,
    };
  }

  getListCategory = () => {
    dataProvider
      .getList("categories", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((response) => {
        // console.log(response)
        if (response && response.data) {
          this.setState({
            treeData: listCategories(response.data),
          });
        }
      });
  };
  // onOpenDialogChild = (node, path, getNodeKey) => {
  //   console.log(node);
  //   this.setState({
  //     showCategory: 2,
  //     nodeChild: node,
  //     getNodeKey: getNodeKey,
  //     getPathChild: path,
  //     open: true,
  //   });
  // };

  onSetOpen = (open) => {
    this.setState({ open: open });
  };
  onSetOpenByShow = (open, key) => {
    this.setState({ open: open, showCategory: key });
  };
  onMoveNode = ({ node, treeData, nextParentNode, prevPath }) => {
    /**node, prevPath, prevTreeIndex, nextPath, nextTreeIndex, */
    // console.log("nextTreeIndex", prevPath)
    if (nextParentNode === null) {
      if (treeData && treeData.length > 0) {
        treeData.map((item, indexRoot) => {
          let newCategory = item.category;
          newCategory.sort_order = indexRoot;
          dataProvider.update("categories", {
            id: newCategory.id,
            data: newCategory,
            previousData: item.category,
          });
        });
      }
    } else {
      if (nextParentNode && nextParentNode.children.length > 0) {
        // console.log(prevTreeIndex, nextTreeIndex)

        const { category } = node;

        if (category && category.id) {
          const { children } = nextParentNode;
          //get newOrder
          const newIndex = findIndex(children, function (o) {
            return o.category_id === category.id;
          });
          //get old ParentId
          const categoryPush = find(children, function (o) {
            return o.category_id === category.id;
          });
          //set OldCategory update previous Data
          const myOld = {
            ...categoryPush.category,
            parent_id: categoryPush.parent_category_id,
          };
          const newCategory = category;
          newCategory["parent_id"] = nextParentNode.category_id;
          newCategory["sort_order"] = newIndex;

          dataProvider
            .update("categories", {
              id: newCategory.id,
              data: newCategory,
              previousData: myOld,
            })
            .then((response) => {
              // console.log(response)
            });
          // console.log("new", newCategory)
        }
      }
    }
  };

  onRemoveItem = (node, path, getNodeKey) => {
    if (node && node.category) {
      this.setState({
        nodeRemove: node,
        getNodeRemove: getNodeKey,
        pathRemove: path,
        openRemove: true,
      });
    }
  };
  onRemoveItemDialog = () => {
    const { nodeRemove, getNodeRemove, pathRemove } = this.state;
    if (nodeRemove && nodeRemove.category) {
      // console.log(nodeRemove)
      let newCategory = nodeRemove.category;
      dataProvider.delete("categories", {
        id: newCategory.id,
        previousData: nodeRemove.category,
      });
      // .then((response) => {
      //   console.log(response)
      // })
      this.setState((state) => ({
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path: pathRemove,
          getNodeKey: getNodeRemove,
        }),
      }));
      this.setState({
        nodeRemove: null,
        getNodeRemove: null,
        pathRemove: null,
        openRemove: false,
      });
    }
  };
  onSetOpenRemoveItem = (open) => {
    this.setState({ openRemove: open });
  };
  render() {
    const { translate } = this.props;
    const getNodeKey = ({ treeIndex }) => treeIndex;
    return (
      <Box width={[1]}>
        <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-start"}>
          <Box px={[3]}>
            <Button
              color='primary'
              // onClick={() => this.onSetOpenByShow(true, 1)}
              onClick={() => this.props.openCreate(null)}
              size='small'
              style={{ textTransform: "capitalize" }}
            >
              {translate("resources.categories.label.add_root_category")}
            </Button>
          </Box>
        </Flex>
        <div style={{ height: "80vh" }}>
          <SortableTree
            maxDepth={4}
            treeData={this.state.treeData}
            onChange={(treeData) => this.setState({ treeData })}
            onMoveNode={(p) => this.onMoveNode(p)}
            canDrop={(v) => treeCanDrop(v)}
            generateNodeProps={({ node, path }) => {
              // console.log(node.children);
              return {
                buttons:
                  node.tier < 3
                    ? [
                        <Button
                          size='small'
                          color='primary'
                          style={{ textTransform: "capitalize", margin: 8 }}
                          onClick={() =>
                            this.props.openCreate(node.category_id)
                          }
                        >
                          {translate(
                            "resources.categories.label.add_sub_category"
                          )}
                        </Button>,
                        <IconButton
                          style={{ padding: 8 }}
                          onClick={() => this.props.openEdit(node.category_id)}
                        >
                          <EditIcon fontSize='small' color={"primary"} />
                        </IconButton>,
                        <div>
                          {node && node.children && node.children.length === 0 && (
                            <IconButton
                              style={{ padding: 8 }}
                              onClick={() =>
                                this.onRemoveItem(node, path, getNodeKey)
                              }
                            >
                              <DeleteIcon
                                fontSize='small'
                                style={{ color: pink[500] }}
                              />
                            </IconButton>
                          )}
                        </div>,
                      ]
                    : [
                        <IconButton
                          style={{ padding: 8 }}
                          onClick={() => this.props.openEdit(node.category_id)}
                        >
                          <EditIcon fontSize='small' color={"primary"} />
                        </IconButton>,
                        <div>
                          {node && node.children && node.children.length === 0 && (
                            <IconButton
                              style={{ padding: 8 }}
                              onClick={() =>
                                this.onRemoveItem(node, path, getNodeKey)
                              }
                            >
                              <DeleteIcon
                                fontSize='small'
                                style={{ color: pink[500] }}
                              />
                            </IconButton>
                          )}
                        </div>,
                      ],
              };
            }}
          />
        </div>
        <DialogRemoveItem
          state={this.state}
          onSetOpen={(v) => this.onSetOpenRemoveItem(v)}
          onRemoveItem={() => this.onRemoveItemDialog()}
          {...this.props}
        />
      </Box>
    );
  }
}

export default ListCategories;
