import React, { useState } from "react"
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  useTranslate,
  BooleanInput,
} from "react-admin"
import IconContentAdd from "@material-ui/icons/Add"
import IconCancel from "@material-ui/icons/Cancel"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

function CreateSiteDialog({ onChange }) {
  const translate = useTranslate()
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("sites")
  const notify = useNotify()
  // const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("id", data.id)

          notify("ra.notification.created")
          onChange()
          setShowDialog(false)
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("resources.sites.new_title")}
      >
        <DialogTitle>{translate("resources.sites.new_title")}</DialogTitle>

        <FormWithRedirect
          resource="sites"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  source="name"
                  validate={required()}
                  style={{ width: 300 }}
                  label="resources.sites.fields.name"
                  InputLabelProps={{ shrink: true }}
                />
                <BooleanInput source="active" />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default CreateSiteDialog
