import React from "react";
import { get } from "lodash";
import { Flex, Text } from "rebass";
import { Currency } from "../../../../components";

const RowField = ({ title, value, currency_symbol, isDiscount = false }) => {
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        "&>div": {
          py: [2],
        },
      }}
    >
      <Text fontWeight={"bold"}>{title}</Text>
      {isDiscount ? (
        <Text style={{ color: "#FF0000" }}>
          -
          <Currency
            value={value}
            unit={currency_symbol}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Text>
      ) : (
        <Text>
          <Currency
            value={value}
            unit={currency_symbol}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Text>
      )}
    </Flex>
  );
};

export default (props) => {
  const { translate, record, website } = props;
  console.log("record", record);
  const currency_symbol = get(website, "locale.currency_symbol", "");
  const subtotal = get(record, "amounts.subtotal", 0);
  const shipping = get(record, "amounts.shipping", 0);
  const total = get(record, "amounts.total", 0);
  const discount = get(record, "amounts.subsidy", 0) * 1;
  return (
    <Flex width={[1]} flexDirection='column' pb={[3]}>
      <Flex width={[1]} flexDirection={"column"} px={[3]} py={[3]}>
        <RowField
          title={translate("resources.orders.labels.subtotal")}
          value={subtotal}
          currency_symbol={currency_symbol}
        />
        <RowField
          title={translate("resources.orders.labels.discount")}
          value={discount}
          currency_symbol={currency_symbol}
          isDiscount={discount > 0}
        />
        <RowField
          title={translate("resources.orders.labels.shipping")}
          value={shipping}
          currency_symbol={currency_symbol}
        />
        <RowField
          title={translate("resources.orders.labels.tax")}
          value={0}
          currency_symbol={currency_symbol}
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        bg={"#EEEEEE"}
        px={[3]}
        mb={[2]}
        sx={{
          "&>div": {
            py: [2],
          },
        }}
      >
        <Text fontWeight={"bold"}>
          {translate("resources.orders.labels.total")}
        </Text>
        <Text>
          <Currency
            value={total}
            unit={currency_symbol}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Text>
      </Flex>
    </Flex>
  );
};
