import React from "react"
import { Flex } from "rebass"
import { parseInt, isNaN } from "lodash"
import TextField from "@material-ui/core/TextField"

const ItemNumbers = props => {
  const { onUpdateFilter, filter, translate } = props

  const handleChange = prop => event => {
    const numberValue = isNaN(event.target.value)
      ? 0
      : parseInt(event.target.value)
    onUpdateFilter(prop, numberValue)
  }

  return (
    <Flex width={["300px"]} flexDirection={"column"} p={[2]}>
      <TextField
        id={`filter-first_step`}
        label={translate("resources.filters.fields.first_step")}
        type="number"
        value={filter.first_step}
        onChange={handleChange("first_step")}
        InputLabelProps={{
          shrink: true,
        }}
        helperText={translate("resources.filters.note_text.first_step")}
      />
      <TextField
        id={`filter-last_step`}
        label={translate("resources.filters.fields.last_step")}
        type="number"
        value={filter.last_step}
        onChange={handleChange("last_step")}
        InputLabelProps={{
          shrink: true,
        }}
        helperText={translate("resources.filters.note_text.last_step")}
      />
      <TextField
        id={`filter-interval`}
        label={translate("resources.filters.fields.interval")}
        type="number"
        value={filter.interval}
        onChange={handleChange("interval")}
        InputLabelProps={{
          shrink: true,
        }}
        helperText={translate("resources.filters.note_text.interval")}
      />
    </Flex>
  )
}
export default ItemNumbers
