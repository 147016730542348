import React, { Component } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { Divider, Tabs, Tab } from "@material-ui/core";
import ShowContent from "./ShowContent";
import ResetContent from "./ResetContent";
import { filter } from "lodash";
import EditorLayout from "../EditorLayout";

class PageContent extends Component {
  constructor(props) {
    super(props);
    const { slug, locale } = props.values;

    let newContent = props.languageSite;

    if (locale) {
      // console.log(props.languageSite)
      newContent.map((item) => {
        if (locale[item.id] && locale[item.id].content) {
          if (!Array.isArray(locale[item.id].content)) {
            item.data = locale[item.id].content;
          }
          item.layoutSelected = locale[item.id].content;
        }
        return item;
      });
    }

    // newContent[0].isShowing = true
    this.state = {
      isSelected: false,
      slideIndex: 0,
      expandedMarketer: false,
      expandedField: true,
      slug: slug || "",
      tabId: 0,
      localeContent: newContent,
      openShow: false,
      showDevice: "desktop",
    };
  }
  onChangeData = (data, dataRaw, localeId) => {
    const { localeContent } = this.state;
    localeContent.map((item) => {
      if (item.id === localeId) {
        item.data = data;
        item.layoutSelected = dataRaw;
      }
      return item;
    });
    // console.log(localeContent)

    this.setState({
      localeContent,
    });

    // dataRaw, `locale.${localeId}.content`
    this.props.updateField(`locale.${localeId}.content`, dataRaw);
  };

  handleDialog = (isShow, device) => {
    // console.log(isShow, device)
    this.setState({
      open: isShow,
      showDevice: device,
    });
  };

  renderContentTemplate(source, localeId, tabSelected) {
    const refEditorPage = `editorpage${localeId}`;
    const content = filter(this.state.localeContent, function (o) {
      return o.id === localeId;
    });
    const newContent = content.length > 0 ? content[0].data : undefined;

    return (
      <Box width={[1]} py={[1]}>
        <EditorLayout
          ref={(ref) => (this[refEditorPage] = ref)}
          onChangeData={(data, dataRaw) =>
            this.onChangeData(data, dataRaw, localeId)
          }
          data={newContent}
          statusForm={this.props.statusForm}
          isShow={tabSelected}
          locale={localeId}
          onResetPage={() => this.onResetPage(refEditorPage, localeId)}
          widthScreen={this.props.widthScreen}
          heightScreen={this.props.heightScreen}
          websiteId={get(this.props, "values.website_id", 0)}
          siteId={get(this.props, "values.site_id", 0)}
        />
      </Box>
    );
  }
  onResetPage = (refEditorPage, localeId) => {
    // console.log(refEditorPage)
    this.setState({
      data: undefined,
      layoutSelected: [],
    });
    this[refEditorPage].onResetPage();
    this.props.updateField(`locale.${localeId}.content`, null);
  };
  handleChange = (event, value) => {
    // console.log(value)
    this.setState({
      tabId: value,
    });
  };
  render() {
    const {
      languageSite,
      translate,
      widthScreen,
      heightScreen,
      values,
    } = this.props;
    const { tabId } = this.state;

    // console.log(this.state.localeContent)
    // console.log(get(values, "website_id", 0));
    if (languageSite && languageSite.length === 1) {
      const item = languageSite[0];
      return (
        <Box width={[1]}>
          <Box
            width={[1]}
            py={["24px"]}
            key={item.id}
            style={{
              display: "flex",
              overflowY: "auto",
            }}
          >
            {this.renderContentTemplate(
              `locale.${item.id}.content`,
              item.id,
              item.isShowing
            )}
            <PreviewBox>
              <Flex flexDirection={"row"} p={[2]} alignItems='center'>
                <ResetContent
                  translate={translate}
                  localeId={item.id}
                  onResetPage={this.onResetPage}
                />

                <ShowContent
                  localeId={item.id}
                  content={this.state.localeContent}
                  translate={translate}
                  widthScreen={widthScreen}
                  heightScreen={heightScreen}
                  websiteId={get(values, "website_id", 0)}
                />
              </Flex>
            </PreviewBox>
          </Box>
        </Box>
      );
    }

    return (
      <Box width={[1]} style={{ backgroundColor: "#FFFFFF" }}>
        <Tabs
          onChange={this.handleChange}
          value={this.state.tabId}
          style={{
            position: "fixed",
            zIndex: 1000,
            width: "100%",
            backgroundColor: "#FFFFFF",
            paddingTop: 0,
          }}
        >
          {languageSite.map((item, index) => {
            return <Tab key={item.id} label={item.name} value={index} />;
          })}
        </Tabs>
        <Divider />
        {languageSite.map((item, index) => {
          return (
            <Box
              width={[1]}
              py={["50px"]}
              pb={["10px"]}
              key={item.id}
              pl={[2]}
              style={{
                display: tabId !== index ? "none" : "flex",
                overflowY: "auto",
              }}
            >
              {this.renderContentTemplate(
                `locale.${item.id}.content`,
                item.id,
                item.isShowing
              )}
              <PreviewBox>
                <Flex flexDirection={"row"} p={[2]} alignItems='center'>
                  <ResetContent
                    translate={translate}
                    localeId={item.id}
                    onResetPage={this.onResetPage}
                  />

                  <ShowContent
                    localeId={item.id}
                    content={this.state.localeContent}
                    translate={translate}
                    widthScreen={widthScreen}
                    heightScreen={heightScreen}
                    websiteId={get(values, "website_id", 0)}
                  />
                </Flex>
              </PreviewBox>
            </Box>
          );
        })}
      </Box>
    );
  }
}

const PreviewBox = styled.div`
  position: fixed;
  left: auto;
  top: 72px;
  right: 0px;
  z-index: 1999;
  padding: 0px 6px;
`;

export default PageContent;
