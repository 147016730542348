import React, { useState } from "react";
import { downloadCSV } from "react-admin";
import { Button } from "@material-ui/core";
import { get } from "lodash";
import moment from "moment";
import jsonExport from "jsonexport/dist";

const ButtonExportCSV = (props) => {
  const { products, translate } = props;
  const [loading, setLoading] = useState(false);
  const onExport = () => {
    setLoading(true);

    const listProducts = products.map((post) => {
      const params = {
        ID: get(post, "id", ""),
        SKU: get(post, "sku", ""),
      };
      return params;
    });

    jsonExport(
      listProducts,
      {
        headers: ["ID", "SKU"],
      },
      (err, csv) => {
        downloadCSV(csv, `Export-Applicable-Product-${moment().toISOString()}`); // download as 'products.csv` file
      }
    );
    setLoading(false);
  };
  return (
    <Button
      onClick={onExport}
      color={"primary"}
      disabled={loading || products.length === 0}
    >
      {translate("resources.buttons.export_to_csv")}
    </Button>
  );
};

export default ButtonExportCSV;
