import React, { Fragment } from "react";
import { Box, Text } from "rebass";
import { useQueryWithStore } from "react-admin";
import CustomerBuyFields from "./CustomerBuyFields";
import CustomerGetFields from "./CustomerGetFields";

const BuyXGetYFields = (props) => {
  const { formData, translate } = props;
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: `categories`,
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: {
        website_id: formData.website_id,
      },
    },
  });
  if (loading) {
    return (
      <Box width={[1]} p={[2]}>
        <Text textAlign={"center"} fontSize={[2]}>
          {translate(`resources.categories.messages.loading`)}
        </Text>
      </Box>
    );
  }
  return (
    <Fragment>
      <CustomerBuyFields {...props} listCategories={data} />
      <CustomerGetFields {...props} listCategories={data} />
    </Fragment>
  );
};

export default BuyXGetYFields;
