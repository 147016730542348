import React, { Component, Fragment } from "react";
import { Flex, Box } from "rebass";
import { get, filter, find, findIndex } from "lodash";
import { Drawer, Button, Paper } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import TableFilters from "./TableFilters";
import FilterForm from "./FilterForm";
import { dataProvider, getSiteID } from "../../../../restClient";
import { useStylesDrawer } from "./styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class ListFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreate: false,
      openSnack: false,
      oldFilters: props.formData.filters,
      attributeSelected: {},
      variantSelected: {},
      isEdit: false,
      valuesFilter: {
        attribute_id: "",
        first_step: 0,
        last_step: 0,
        interval: 0,
        is_auto_filter: false,
        data_filters: [],
        attributes: [],
        type: "Variant",
        variant_id: "",
      },
      attributes: [],
      variants: [],
      message: "Attribute Already Selected",
    };
  }
  componentDidMount() {
    dataProvider
      .getList("attributes", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "name", order: "ASC" },
        filter: { site_id: getSiteID() },
      })
      .then((items) => {
        if (items && items.data) {
          this.setState({ attributes: items.data });
        }
      })
      .catch((e) => {
        console.error(e);
      });
    dataProvider
      .getList("variants", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "name", order: "ASC" },
        filter: { site_id: getSiteID() },
      })
      .then((items) => {
        if (items && items.data) {
          this.setState({ variants: items.data });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
  onCreate = () => {
    this.setState({
      openCreate: true,
      isEdit: false,
      attributeSelected: {},
      valuesFilter: {
        attribute_id: "",
        first_step: 0,
        last_step: 0,
        interval: 0,
        is_auto_filter: false,
        data_filters: [],
        type: "Variant",
        variant_id: "",
      },
    });
  };
  onCreateSave = (values) => {
    const { translate } = this.props;
    if (values) {
      if (values.type === "Attribute") {
        if (values.attribute_id !== "") {
          const filters = get(this.props, "formData.filters", []);
          const isEmpty = filter(filters, function (o) {
            return o.attribute_id === values.attribute_id;
          });
          if (isEmpty.length === 0) {
            filters.push(values);
            this.props.onSaveFilter(filters);
            this.setState({
              openCreate: false,
            });
          } else {
            this.setState({
              openSnack: true,
              openCreate: true,
              message: translate("resources.filters.error.selected"),
            });
          }
        } else {
          this.setState({
            openSnack: true,
            openCreate: true,
            message: translate("resources.filters.error.not_selected"),
          });
        }
      } else if (values.type === "Variant") {
        if (values.variant_id !== "") {
          const filters = get(this.props, "formData.filters", []);
          const isEmpty = filter(filters, function (o) {
            return o.variant_id === values.variant_id;
          });
          if (isEmpty.length === 0) {
            filters.push(values);
            this.props.onSaveFilter(filters);
            this.setState({
              openCreate: false,
            });
          } else {
            this.setState({
              openSnack: true,
              openCreate: true,
              message: translate("resources.categories.error.variant_selected"),
            });
          }
        } else {
          this.setState({
            openSnack: true,
            openCreate: true,
            message: translate(
              "resources.categories.error.variant_not_selected"
            ),
          });
        }
      }
    }
  };
  onEditSave = (values) => {
    const { translate } = this.props;
    if (values) {
      console.log(values);
      if (values.type === "Attribute") {
        if (values.attribute_id !== "") {
          const filters = get(this.props, "formData.filters", []);
          const isEmpty = filter(filters, function (o) {
            return o.attribute_id === values.attribute_id;
          });

          if (isEmpty.length === 0) {
            filters.push(values);
            this.props.onSaveFilter(filters);
            this.setState({
              openCreate: false,
            });
          } else {
            const indexFilter = findIndex(filters, function (o) {
              return o.attribute_id === values.attribute_id;
            });
            filters[indexFilter] = values;
            this.props.onSaveFilter(filters);
            this.setState({
              openCreate: false,
            });
          }
        } else {
          this.setState({
            openSnack: true,
            openCreate: true,
            message: translate("resources.filters.error.not_selected"),
          });
        }
      } else if (values.type === "Variant") {
        if (values.variant_id !== "") {
          const filters = get(this.props, "formData.filters", []);
          const isEmpty = filter(filters, function (o) {
            return o.variant_id === values.variant_id;
          });

          if (isEmpty.length === 0) {
            filters.push(values);
            this.props.onSaveFilter(filters);
            this.setState({
              openCreate: false,
            });
          } else {
            const indexFilter = findIndex(filters, function (o) {
              return o.variant_id === values.variant_id;
            });
            filters[indexFilter] = values;
            this.props.onSaveFilter(filters);
            this.setState({
              openCreate: false,
            });
          }
        } else {
          this.setState({
            openSnack: true,
            openCreate: true,
            message: translate(
              "resources.categories.error.variant_not_selected"
            ),
          });
        }
      }
    }
  };
  onEdit = (items) => {
    console.log(items);
    if (items) {
      if (items.type === "Attribute") {
        const { attributes } = this.state;
        const listAttribute = find(attributes, function (o) {
          return o.id === items.attribute_id;
        });
        this.setState({
          openCreate: true,
          isEdit: true,
          valuesFilter: items,
          attributeSelected: listAttribute ? listAttribute : {},
        });
      } else if (items.type === "Variant") {
        const { variants } = this.state;
        const listVariants = find(variants, function (o) {
          return o.id === items.variant_id;
        });
        this.setState({
          openCreate: true,
          isEdit: true,
          valuesFilter: items,
          variantSelected: listVariants ? listVariants : {},
        });
      }
    }
  };

  onDelete = (item) => {
    const filters = get(this.props, "formData.filters", []);
    if (item) {
      if (item.type === "Attribute") {
        const listAttribute = filter(filters, function (o) {
          return o.attribute_id !== item.attribute_id;
        });
        this.props.onSaveFilter(listAttribute);
      } else if (item.type === "Variant") {
        const listVariants = filter(filters, function (o) {
          return o.variant_id !== item.variant_id;
        });
        this.props.onSaveFilter(listVariants);
      }
    }
  };

  handleSnackBar = () => {
    this.setState({ openSnack: false });
  };

  render() {
    const { translate, classes } = this.props;
    const {
      openCreate,
      openSnack,
      message,
      valuesFilter,
      attributes,
      variants,
      attributeSelected,
      isEdit,
    } = this.state;
    return (
      <Box width={[1]} pt={[2]} pb={[2]}>
        <Paper style={{ padding: 16 }} elevation={3}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{translate("resources.categories.fields.filters")}</Box>
            <Box>
              <Button
                color={"primary"}
                style={{ textTransform: "capitalize" }}
                onClick={() => this.onCreate()}
              >
                {translate("resources.buttons.create")}
              </Button>
            </Box>
          </Flex>
          <TableFilters
            {...this.props}
            translate={translate}
            onEdit={(item) => this.onEdit(item)}
            onDelete={(item) => this.onDelete(item)}
          />
        </Paper>
        <Drawer
          variant='persistent'
          open={openCreate}
          anchor='right'
          onClose={() => this.setState({ openCreate: false })}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {openCreate && (
            <Fragment>
              <FilterForm
                onCancel={() => this.setState({ openCreate: false })}
                onSave={(values) =>
                  isEdit ? this.onEditSave(values) : this.onCreateSave(values)
                }
                {...this.props}
                attributes={attributes}
                attributeSelected={attributeSelected}
                valuesFilter={valuesFilter}
                variants={variants}
              />
              <Snackbar
                open={openSnack}
                autoHideDuration={4000}
                onClose={this.handleSnackBar}
              >
                <Alert onClose={this.handleSnackBar} severity='error'>
                  {message}
                </Alert>
              </Snackbar>
            </Fragment>
          )}
        </Drawer>
      </Box>
    );
  }
}

export default withStyles(useStylesDrawer)(ListFilters);
