import React, { Fragment, useCallback } from "react"
import { Route, useHistory } from "react-router-dom"
import { Drawer, makeStyles } from "@material-ui/core"
import classnames from "classnames"
import {
  TextField,
  useTranslate,
  BooleanField,
  RefreshButton,
} from "react-admin"
import { Box, Flex } from "rebass"
import { lightBlue } from "@material-ui/core/colors"
import {
  BetterList,
  ButtonEditView,
  CreateButton,
  Title,
} from "../../components"
import { AddIcon } from "../../components/icon_svg"
// import CategoriesEdit from "./Edit"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 0,
  },
  drawerPaper: {
    zIndex: 20,
  },
}))

const FiltersActions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => {
  const translate = useTranslate()
  return (
    <Box width={[1]} pt={[2]} pl={[2]} pr={[2]}>
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.filters.title`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton
          basePath={basePath}
          icon={<AddIcon />}
          sizeIcon={"xsmall"}
          style={{ color: lightBlue[700] }}
        />
        <RefreshButton />
      </Flex>
    </Box>
  )
}

const FiltersList = props => {
  const classes = useStyles()
  const history = useHistory()
  const handleClose = useCallback(() => {
    history.push("/filters")
  }, [history])

  return (
    <div className={classes.root}>
      <Route path="/filters/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          )
          return (
            <Fragment>
              <BetterList
                {...props}
                actions={<FiltersActions />}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
              >
                <TextField source="name" />
                <BooleanField source="active" />
                <ButtonEditView {...props} />
              </BetterList>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch ? (
                  // <CategoriesEdit
                  //   id={match.params.id}
                  //   onCancel={handleClose}
                  //   {...props}
                  // />
                  <div>filter edits</div>
                ) : null}
              </Drawer>
            </Fragment>
          )
        }}
      </Route>
    </div>
  )
}

export default FiltersList
