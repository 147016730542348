import React from "react";
import { Flex } from "rebass";
import moment from "moment";
import { get, map } from "lodash";
import { PaperCard } from "../../../../components";
import CartItem from "./CartItem";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
const OrderItemFields = (props) => {
  const { translate, record, website } = props;
  // console.log(website);\
  const list = get(record, "items", []);
  const currency_symbol = get(website, "locale.currency_symbol", "");
  const onPrintSlip = () => {
    // const list = get(record, "items", []);
    if (list && list.length > 0) {
      let newList = map(list, function (o) {
        const newItem = {
          order_id: get(o, "order_id"),
          product_id: get(o, "product_id"),
          name: get(o, "name"),
          sku: get(o, "sku"),
          price: get(o, "price"),
          discount: get(o, "discount"),
          discounted_price: get(o, "discounted_price"),
          qty: get(o, "qty"),
        };
        return newItem;
      });
      jsonExport(
        newList,
        {
          headers: [
            "order_id",
            "product_id",
            "name",
            "sku",
            "price",
            "discount",
            "discounted_price",
            "qty",
          ],
          footers: {
            total: 8,
            subtotal: 10,
            shipping: 0,
            coupon: 0,
            total_items: 0,
          },
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(
            csv +
              `\n\ntotal:,${get(record, "amounts.total", 0)}\nsubtotal:,${get(
                record,
                "amounts.subtotal",
                0
              )}\nshipping:,${get(
                record,
                "amounts.shipping",
                0
              )}\ncoupon:,${get(
                record,
                "amounts.coupon",
                0
              )}\ntotal_items:,${get(record, "amounts.total_items", 0)}\n`,
            `order_item-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
          );
        }
      );
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate("resources.orders.labels.items")}
        showButton={list.length > 0}
        onClick={onPrintSlip}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
        labelButton={translate("resources.buttons.export_to_csv")}
      >
        <Flex width={[1]} flexDirection={"column"}>
          {list !== null &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <CartItem
                  item={item}
                  key={index}
                  currency_symbol={currency_symbol}
                />
              );
            })}
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default OrderItemFields;
