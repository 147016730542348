import React, { Fragment } from "react";
import {
  useTranslate,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  // AutocompleteArrayInput,
  // ReferenceArrayInput,
} from "react-admin";
import { get } from "lodash";
import { ThemeProvider } from "@material-ui/core/styles";
import { ROLE_USER } from "../../../utils";
import { Flex, Text } from "rebass";
import { RaBooleanInput, RaLocaleTabs } from "../../../components";
import { TextField } from "@material-ui/core";
import { getSiteID } from "../../../restClient";
import { themeRaTA } from "../../../components/ra-field/themeTextArea";
import ListProductCollections from "./ListProductCollections";
// import AutocompleteArrayInput from "./AutocompleteArrayInput";
// import SelectInputProducts from "./SelectInputProducts";
const ContentItem = (props) => {
  const translate = useTranslate();
  const { locale, formData } = props;

  const onChangeName = (key, name, localeName) => (e) => {
    if (key === "en") {
      props.updateField(name, e.target.value);
    }
    props.updateField(localeName, e.target.value);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <TextField
        value={get(formData, `locale.${locale}.name`, "")}
        id={`locale.${locale}.name`}
        label={translate("resources.collections.fields.name")}
        style={{ width: 400 }}
        onChange={onChangeName(locale, "name", `locale.${locale}.name`)}
      />
      <Text pt={[2]} color={"#616161"} fontSize={"12px"} fontWeight={"bold"}>
        {translate("resources.collections.fields.description")}
      </Text>
      <ThemeProvider theme={themeRaTA}>
        <TextField
          variant='filled'
          id={`locale.${locale}.description`}
          label={""}
          onChange={onChangeName(
            locale,
            "description",
            `locale.${locale}.description`
          )}
          rows={6}
          value={get(formData, `locale.${locale}.description`, "")}
          style={{ width: 600 }}
          multiline={true}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </ThemeProvider>
    </Flex>
  );
};

export default (props) => {
  const { permissions, isCreate } = props;
  // const onSetItemValue = (k, v) => {
  //   console.log(k, v);
  //   props.updateField(k, v);
  // };

  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      style={{ backgroundColor: "#FFFFFF" }}
    >
      {isCreate && (
        <ReferenceInput
          label={"resources.collections.fields.site_id"}
          source='site_id'
          reference='sites'
          style={{ width: 320 }}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
      )}

      <FormDataConsumer>
        {({ formData }) => {
          const SiteID =
            formData.site_id === getSiteID() ? formData.site_id : getSiteID();
          if (
            formData.site_id !== getSiteID() &&
            permissions === ROLE_USER.super_admin
          ) {
            props.updateField("site_id", SiteID);
          }
          return (
            <Fragment>
              {/* {permissions === ROLE_USER.super_admin && (
                <ReferenceInput
                  label={"resources.collections.fields.website"}
                  source='website_id'
                  reference='websites'
                  filter={{ site_id: SiteID }}
                  style={{ width: 320 }}
                >
                  <SelectInput optionText='name' />
                </ReferenceInput>
              )} */}
              <RaBooleanInput
                source={"active"}
                label={"resources.collections.fields.active"}
              />

              <ListProductCollections {...props} formData={formData} />
              {/* <ReferenceArrayInput
                label='resources.collections.fields.collections'
                reference='products'
                source='products'
                resource={"products"}
                perPage={80}
                sort={{ field: "name", order: "ASC" }}
                filterToQuery={(searchText) => ({ search: searchText })}
                fullWidth={true}
                filter={{ active: true, site_id: formData.site_id }}
              >
                <AutocompleteArrayInput
                  optionText={(record) => {
                    return `${get(record, "name", "")}`;
                  }}
                  optionValue={"id"}
                /> 
              </ReferenceArrayInput>*/}
              {/* <SelectInputProducts
                id={`list-collections-product-value`}
                title={"Products"}
                onChange={(v) => onSetItemValue("products", v)}
                value={get(formData, "products", [])}
              /> */}
              <RaLocaleTabs
                siteId={formData.site_id}
                nameTab='tab-collections-info'
              >
                <ContentItem {...props} formData={formData} />
              </RaLocaleTabs>
            </Fragment>
          );
        }}
      </FormDataConsumer>

      {/* <RaTextInput source='name' style={{ width: 400 }} validate={required()} /> */}
    </Flex>
  );
};
