import React from "react";
import { Flex } from "rebass";
import { useTranslate } from "react-admin";
import { VariantDialog } from "./variants";

import { VariantList } from "./variants";

const VariantsFields = (props) => {
  // console.log(props);
  const { formValues, ...rest } = props;
  const translate = useTranslate();
  return (
    <Flex width={[1]} flexDirection={"column"} p={[2]}>
      <VariantDialog
        {...rest}
        formData={formValues}
        variant={formValues.variant}
        translate={translate}
      />
      <VariantList {...props} />
    </Flex>
  );
};

export default VariantsFields;
