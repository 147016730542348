import React from "react";
import { Flex, Text, Box } from "rebass";
import { get } from "lodash";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { red, blue } from "@material-ui/core/colors";

const myURL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "https://www.playitright.com";
const ProductItemFields = (props) => {
  const { record, onDeleteProduct } = props;

  return (
    <Flex
      width={[1]}
      flexDirection='row'
      justifyContent={"flex-start"}
      alignItems={"center"}
      py={[1]}
      style={{ borderBottom: `1px solid #EEEEEE` }}
    >
      <Flex
        flexDirection='row'
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={[1 / 6]}
      >
        <Text pl={[2]}>{get(record, "id", "")}</Text>
      </Flex>
      <Box style={{ flex: 1 }}>
        <Text px={[2]} textAlign='left'>
          {get(record, "sku", "")}
        </Text>
      </Box>
      <Flex
        px={[2]}
        flexDirection='row'
        justifyContent={"flex-end"}
        alignItems={"center"}
        sx={{
          "&>*": {
            px: [2],
          },
        }}
      >
        <div style={{ paddingTop: 8 }}>
          <a
            href={`${myURL}/products/${get(record, "id", "")}`}
            target={"_blank"}
            rel='noopener noreferrer'
          >
            <ImageEye style={{ color: blue[900] }} />
          </a>
        </div>
        <IconButton onClick={() => onDeleteProduct()}>
          <DeleteIcon style={{ color: red[900] }} />
        </IconButton>
      </Flex>
    </Flex>
  );
};

const TableProductsSKU = (props) => {
  const { formData, productSource, onDeleteProduct } = props;
  const products = get(formData, `criteria.${productSource}.products`, []);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      {products.length > 0 && (
        <Flex
          width={[1]}
          flexDirection='row'
          justifyContent={"flex-start"}
          alignItems={"center"}
          py={[1]}
          style={{ borderBottom: `1px solid #EEEEEE` }}
        >
          <Flex
            flexDirection='row'
            justifyContent={"flex-start"}
            alignItems={"center"}
            width={[1 / 6]}
          >
            <Text pl={[2]} style={{ fontWeight: "bold" }}>
              ID
            </Text>
          </Flex>
          <Box>
            <Text px={[2]} style={{ fontWeight: "bold" }} textAlign='left'>
              SKU
            </Text>
          </Box>
          <Flex
            px={[2]}
            flexDirection='row'
            justifyContent={"flex-end"}
            alignItems={"center"}
            sx={{
              "&>*": {
                px: [2],
              },
            }}
          >
            <div />
          </Flex>
        </Flex>
      )}
      {products.length > 0 &&
        products.map((item) => {
          return (
            <ProductItemFields
              key={item.id + "-" + item.sku}
              record={item}
              onDeleteProduct={() => onDeleteProduct(item)}
            />
          );
        })}
    </Flex>
  );
};

export default TableProductsSKU;
