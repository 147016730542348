import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useTranslate,
  useUpdate,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import { get, has } from "lodash";
// import IconContentAdd from "@material-ui/icons/Add"
import IconCancel from "@material-ui/icons/Cancel";
import { Flex } from "rebass";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormFieldDialog from "./FormFieldDialog";
import { EditIcon } from "../../../components/icon_svg";

function EditVariantDialog({ onChange, record, ...props }) {
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate("variants");
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    let finalValues = values;
    if (has(values, "locale.en")) {
      finalValues["name"] = get(values, "locale.en.name", "");
      finalValues["code"] = get(values, "locale.en.code", "");
    }
    update(
      {
        payload: {
          id: finalValues.id,
          data: finalValues,
          previousData: record,
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("ra.notification.updated", "success", {
            smart_count: 1,
          });
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("id", data.id)
          setShowDialog(false);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        <IconButton
          onClick={handleClick}
          size='small'
          aria-label='edit'
          color={"primary"}
          // style={{
          //   display: "flex",
          //   justifyContent: "flex-end",
          // }}
        >
          <EditIcon fontSize={"inherit"} />
        </IconButton>
      </Flex>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("resources.variants.edit_title")}
      >
        <DialogTitle>{translate("resources.variants.edit_title")}</DialogTitle>

        <FormWithRedirect
          initialValues={record}
          resource='variants'
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => {
            return (
              <>
                <DialogContent>
                  <FormFieldDialog {...props} />
                </DialogContent>
                <DialogActions>
                  <Button
                    label='ra.action.cancel'
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </>
  );
}

export default EditVariantDialog;
