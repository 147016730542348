import React, { Children, cloneElement, useRef, isValidElement } from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import { get } from "lodash";
import { Field } from "react-final-form";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";

import CloseIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslate } from "react-admin";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";

import { FormInput } from "react-admin";
import {
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  useStyles,
} from "./variants";

const TableFormIteratorVariant = (props) => {
  const {
    basePath,
    children,
    fields,
    meta: { error, submitFailed },
    record,
    resource,
    source,
    disableAdd,
    disableRemove,
    variant,
    margin,
    titleAdd,
    defaultValue,
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  );

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  );

  const removeField = (index) => () => {
    ids.current.splice(index, 1);
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === "boolean") {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  const addField = () => {
    ids.current.push(nextId.current++);
    fields.push(undefined);
  };

  const records = get(record, source);
  return fields ? (
    <Box>
      {submitFailed && typeof error !== "object" && error && (
        <FormHelperText error>{error}</FormHelperText>
      )}
      {!disableAdd && (
        <Box pb={2}>
          <span className={classes.action}>
            <Button
              variant={"contained"}
              className={classNames("button-add", `button-add-${source}`)}
              size="small"
              color={"primary"}
              onClick={() => addField()}
            >
              <AddIcon className={classes.leftIcon} />
              {translate(titleAdd)}
            </Button>
          </span>
        </Box>
      )}
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate("resources.products.fields.name_sku")}
              </StyledTableCell>
              <StyledTableSmall>
                {translate("resources.products.fields.threshold")}
              </StyledTableSmall>
              <StyledTableSmall>
                {translate("resources.products.fields.price")}
              </StyledTableSmall>
              <StyledTableSmall>
                {translate("resources.products.fields.active")}
              </StyledTableSmall>
              {!disableRemove && <StyledTableAction />}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((member, index) => (
              <StyledTableRow key={index}>
                {Children.map(children, (input, index2) => {
                  if (isValidElement(input) && input.props.source === "name") {
                    return (
                      <StyledTableCell component="td" scope="row">
                        <Box>
                          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            <Field
                              name={
                                input.props.source ? `${member}.name` : member
                              }
                            >
                              {(props) => (
                                <Chip
                                  variant="default"
                                  color="primary"
                                  label={props.input.value}
                                />
                              )}
                            </Field>
                          </div>
                          <FormInput
                            basePath={input.props.basePath || basePath}
                            input={cloneElement(input, {
                              source: `${member}.sku`,
                              index: input.props.source ? undefined : index2,
                              label: translate(`resources.products.fields.sku`),
                            })}
                            record={(records && records[index]) || {}}
                            resource={resource}
                            variant={variant}
                            margin={margin}
                          />
                          {/* <FormInput
                            basePath={input.props.basePath || basePath}
                            input={cloneElement(input, {
                              source: `${member}.code`,
                              index: input.props.source ? undefined : index2,
                              label: translate(
                                `resources.products.fields.code`
                              ),
                            })}
                            record={(records && records[index]) || {}}
                            resource={resource}
                            variant={variant}
                            margin={margin}
                          /> */}
                        </Box>
                      </StyledTableCell>
                    );
                  } else if (
                    isValidElement(input) &&
                    input.props.source !== "sku" &&
                    input.props.source !== "code" &&
                    input.props.source !== "name"
                  ) {
                    return (
                      <StyledTableSmall component="td" scope="row">
                        <FormInput
                          basePath={input.props.basePath || basePath}
                          input={cloneElement(input, {
                            source: input.props.source
                              ? `${member}.${input.props.source}`
                              : member,
                            index: input.props.source ? undefined : index2,
                            label: "",
                          })}
                          record={(records && records[index2]) || {}}
                          resource={resource}
                          variant={variant}
                          margin={margin}
                        />
                      </StyledTableSmall>
                    );
                  }
                })}

                {!disableRemoveField(
                  (records && records[index]) || {},
                  disableRemove
                ) && (
                  <StyledTableAction>
                    <IconButton
                      onClick={() => removeField(index)}
                      className={classes.leftIcon}
                    >
                      <CloseIcon />
                    </IconButton>
                  </StyledTableAction>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : null;
};

// class TableFormIteratorVariant extends Component {
//   constructor(props) {
//     super(props)
//     // we need a unique id for each field for a proper enter/exit animation
//     // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
//     // so we keep an internal map between the field position and an auto-increment id
//     this.nextId = props.fields.length
//       ? props.fields.length
//       : props.defaultValue
//       ? props.defaultValue.length
//       : 0

//     // We check whether we have a defaultValue (which must be an array) before checking
//     // the fields prop which will always be empty for a new record.
//     // Without it, our ids wouldn't match the default value and we would get key warnings
//     // on the CssTransition element inside our render method
//     this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : []
//   }

//   removeField = (index) => () => {
//     const { fields } = this.props
//     this.ids.splice(index, 1)
//     fields.remove(index)
//   }

//   // Returns a boolean to indicate whether to disable the remove button for certain fields.
//   // If disableRemove is a function, then call the function with the current record to
//   // determining if the button should be disabled. Otherwise, use a boolean property that
//   // enables or disables the button for all of the fields.
//   disableRemoveField = (record, disableRemove) => {
//     if (typeof disableRemove === "boolean") {
//       return disableRemove
//     }
//     return disableRemove && disableRemove(record)
//   }

//   addField = () => {
//     const { fields } = this.props
//     this.ids.push(this.nextId++)
//     fields.push({})
//   }

//   render() {
//     const {
//       basePath,
//       classes = {},
//       children,
//       fields,
//       meta: { error, submitFailed },
//       record,
//       resource,
//       source,
//       translate,
//       disableAdd,
//       disableRemove,
//       variant,
//       margin,
//       titleAdd,
//     } = this.props
//     const records = get(record, source)
//     return fields ? (
//       <Box>
//         {submitFailed && typeof error !== "object" && error && (
//           <FormHelperText error>{error}</FormHelperText>
//         )}
//         {!disableAdd && (
//           <Box pb={2}>
//             <span className={classes.action}>
//               <Button
//                 variant={"contained"}
//                 className={classNames("button-add", `button-add-${source}`)}
//                 size="small"
//                 color={"primary"}
//                 onClick={this.addField}
//               >
//                 <AddIcon className={classes.leftIcon} />
//                 {translate(titleAdd)}
//               </Button>
//             </span>
//           </Box>
//         )}
//         <TableContainer component={"div"}>
//           <Table className={classes.table} aria-label="customized table">
//             <TableHead>
//               <TableRow>
//                 <StyledTableCell>
//                   {translate("resources.products.fields.name_sku")}
//                 </StyledTableCell>
//                 <StyledTableSmall>
//                   {translate("resources.products.fields.threshold")}
//                 </StyledTableSmall>
//                 <StyledTableSmall>
//                   {translate("resources.products.fields.price")}
//                 </StyledTableSmall>
//                 <StyledTableSmall>
//                   {translate("resources.products.fields.active")}
//                 </StyledTableSmall>
//                 {!disableRemove && <StyledTableAction />}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {fields.map((member, index) => (
//                 <StyledTableRow key={index}>
//                   {Children.map(children, (input, index2) => {
//                     if (
//                       isValidElement(input) &&
//                       input.props.source === "name"
//                     ) {
//                       return (
//                         <StyledTableCell component="td" scope="row">
//                           <Box>
//                             <div style={{ paddingTop: 8, paddingBottom: 8 }}>
//                               <Field
//                                 name={
//                                   input.props.source ? `${member}.name` : member
//                                 }
//                               >
//                                 {(props) => (
//                                   <Chip
//                                     variant="default"
//                                     color="primary"
//                                     label={props.input.value}
//                                   />
//                                 )}
//                               </Field>
//                             </div>
//                             <FormInput
//                               basePath={input.props.basePath || basePath}
//                               input={cloneElement(input, {
//                                 source: `${member}.sku`,
//                                 index: input.props.source ? undefined : index2,
//                                 label: translate(
//                                   `resources.products.fields.sku`
//                                 ),
//                               })}
//                               record={(records && records[index]) || {}}
//                               resource={resource}
//                               variant={variant}
//                               margin={margin}
//                             />
//                             <FormInput
//                               basePath={input.props.basePath || basePath}
//                               input={cloneElement(input, {
//                                 source: `${member}.code`,
//                                 index: input.props.source ? undefined : index2,
//                                 label: translate(
//                                   `resources.products.fields.code`
//                                 ),
//                               })}
//                               record={(records && records[index]) || {}}
//                               resource={resource}
//                               variant={variant}
//                               margin={margin}
//                             />
//                           </Box>
//                         </StyledTableCell>
//                       )
//                     } else if (
//                       isValidElement(input) &&
//                       input.props.source !== "sku" &&
//                       input.props.source !== "code" &&
//                       input.props.source !== "name"
//                     ) {
//                       return (
//                         <StyledTableSmall component="td" scope="row">
//                           <FormInput
//                             basePath={input.props.basePath || basePath}
//                             input={cloneElement(input, {
//                               source: input.props.source
//                                 ? `${member}.${input.props.source}`
//                                 : member,
//                               index: input.props.source ? undefined : index2,
//                               label: "",
//                             })}
//                             record={(records && records[index2]) || {}}
//                             resource={resource}
//                             variant={variant}
//                             margin={margin}
//                           />
//                         </StyledTableSmall>
//                       )
//                     }
//                   })}

//                   {!this.disableRemoveField(
//                     (records && records[index]) || {},
//                     disableRemove
//                   ) && (
//                     <StyledTableAction>
//                       <IconButton
//                         onClick={this.removeField(index)}
//                         className={classes.leftIcon}
//                       >
//                         <CloseIcon />
//                       </IconButton>
//                     </StyledTableAction>
//                   )}
//                 </StyledTableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     ) : null
//   }
// }

TableFormIteratorVariant.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

TableFormIteratorVariant.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  titleAdd: PropTypes.string,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
export default TableFormIteratorVariant;
// export default compose(translate, withStyles(styles))(TableFormIteratorVariant)
