import React from "react";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { RadioButtonGroupInput, useQueryWithStore } from "react-admin";
import { RaNumberInput } from "../../../../components";
import { PaperCard } from "./PaperCard";
import { dataApplyTo } from "./utils";
import ProductFields from "./ProductFields";
import CategoryFields from "./CategoryFields";
const AppliesToFields = (props) => {
  const { translate, sourceParent, formData } = props;
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: `categories`,
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: {
        website_id: formData.website_id,
      },
    },
  });
  const joinSoureParent = `criteria.${sourceParent}`;
  const appliesTo = get(formData, `${joinSoureParent}.applies_to`, "");

  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.applies_to")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <RadioButtonGroupInput
            row={false}
            label={""}
            source={`${joinSoureParent}.applies_to`}
            choices={dataApplyTo}
          />

          {appliesTo === dataApplyTo[0].id &&
            (loading ? (
              <Box width={[1]} p={[2]}>
                <Text textAlign={"center"} fontSize={[2]}>
                  {translate(`resources.categories.messages.loading`)}
                </Text>
              </Box>
            ) : formData.website_id ? (
              <CategoryFields
                {...props}
                categories={data}
                categorySource={sourceParent}
              />
            ) : (
              <Box width={[1]} p={[2]}>
                <Text textAlign={"center"} fontSize={[2]}>
                  {translate(
                    `resources.promotions.note_text.website_id_not_found`
                  )}
                </Text>
              </Box>
            ))}
          {appliesTo === dataApplyTo[1].id && (
            <ProductFields {...props} productSource={sourceParent} />
          )}
          {sourceParent !== "fixed_price" && (
            <RaNumberInput
              source='priority'
              label={"resources.promotions.fields.priority"}
              notetext={"resources.promotions.note_text.priority"}
            />
          )}
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default AppliesToFields;
