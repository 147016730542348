import React, { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import { get, filter } from "lodash";
import { useRefresh, useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { RestApiCRUDString } from "../../../../restClient";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const statusOrder = {
  pending_payment: "Pending Payment",
  processing: "Processing",
  not_paid: "Not Paid",
};

const statusShipping = {
  cancelled: "Cancelled",
  pending: "Pending",
  shipping: "Shipping",
};

const getStatusShipping = (shipmentList) => {
  const list = filter(shipmentList, function (o) {
    return (
      o.status === statusShipping.cancelled ||
      o.status === statusShipping.pending
    );
  });
  return list.length === shipmentList.length;
};

const DialogCancelOrder = (props) => {
  const { translate, record, shipmentList } = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [showMessage, setShowMessage] = useState(
    translate("resources.orders.messages.reason_fill_empty")
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const onSubmit = () => {
    if (value !== "" && record && record.id) {
      setLoading(true);
      RestApiCRUDString(`/orders/${record.id}/cancel`, "POST", {
        reason: value,
      })
        .then((res) => res.json())
        .then((response) => {
          setLoading(false);
          if (response && response.message) {
            setShowMessage(response.message);
            setShow(true);
          } else {
            setOpen(false);
            refresh();
            notify("resources.orders.messages.cancel_order_success");
          }
        })
        .catch((e) => {
          setLoading(false);
          setShowMessage(e.message);
          setShow(true);
        });
    } else {
      setShowMessage(translate("resources.orders.messages.reason_fill_empty"));
      setShow(true);
    }
  };
  const onCheckOrder = () => {
    const orderStatus = get(record, "status", "");
    const paymentStatus =
      get(record, "payment_info.status", "") !== ""
        ? get(record, "payment_info.status", "")
        : statusOrder.not_paid;
    const isShipping =
      shipmentList && shipmentList.length > 0
        ? getStatusShipping(shipmentList)
          ? statusShipping.pending
          : statusShipping.shipping
        : statusShipping.pending;
    const isShow =
      paymentStatus === statusOrder.not_paid ||
      (orderStatus === statusOrder.processing &&
        (isShipping === statusShipping.pending ||
          isShipping === statusShipping.cancelled));

    // console.log("isShow", isShow);
    if (isShow) {
      setOpen(true);
    } else {
      notify("resources.orders.messages.cancel_order_message", "warning");
    }
  };

  return (
    <Fragment>
      <Button color={"primary"} onClick={() => onCheckOrder()}>
        {translate("resources.buttons.cancel_order")}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='form-dialog-title-cancel-order-form'
      >
        <DialogTitle id='form-dialog-title-cancel-order-form'>
          {translate("resources.buttons.cancel_order")}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='reason'
            label={translate("resources.orders.fields.cancellation_reason")}
            multiline
            rows={4}
            value={value}
            fullWidth={true}
            style={{ minWidth: 500 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange(e)}
            variant='outlined'
          />
          <Snackbar open={show} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity='error'>
              {showMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            {translate("resources.buttons.cancel")}
          </Button>
          <Button
            onClick={() => onSubmit()}
            color='primary'
            startIcon={loading ? <CircularProgress size={22} /> : null}
          >
            {translate("resources.buttons.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DialogCancelOrder;
