import React from "react"
//BooleanField
import {
  TextField,
  useTranslate,
  BooleanField,
  RefreshButton,
} from "react-admin"
import { Flex, Box } from "rebass"
import { AddIcon } from "../../components/icon_svg"
import {
  BetterList,
  ButtonEditView,
  CreateButton,
  Title,
} from "../../components"
import { lightBlue } from "@material-ui/core/colors"

const ReturnsAction = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => {
  const translate = useTranslate()
  return (
    <Box width={[1]} pt={[2]} pl={[2]} pr={[2]}>
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.returns.title`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton
          basePath={basePath}
          icon={<AddIcon />}
          sizeIcon={"xsmall"}
          style={{ color: lightBlue[700] }}
        />
        <RefreshButton />
      </Flex>
    </Box>
  )
}

const ReturnsList = props => {
  // console.log(props)
  return (
    <BetterList {...props} actions={<ReturnsAction />}>
      <TextField source="name" />
      <BooleanField source="active" />
      <ButtonEditView {...props} />
    </BetterList>
  )
}
export default ReturnsList
