import React, { useState } from "react";
import { Box } from "rebass";
import { useQueryWithStore, useTranslate, Loading } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { CategoriesWebsite } from "./categories";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 256,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CategoriesFields = (props) => {
  const { formValues } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const [website, setWebsite] = useState("");
  const payload = {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "name", order: "ASC" },
    filter: { site_id: formValues.site_id },
  };
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: `websites`,
    payload: payload,
  });
  const handleChange = (event) => {
    const isUpdate = website !== event.target.value;
    if (isUpdate) {
      setWebsite(event.target.value);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Box width={[1]} p={[2]}>
      <Box width={[1]}>
        <FormControl className={classes.formControl}>
          <InputLabel id='websites-select-label'>
            {translate(`resources.categories.fields.website`)}
          </InputLabel>
          <Select
            labelId='websites-select-label'
            id='websites-select'
            value={website}
            onChange={handleChange}
          >
            {data &&
              data.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
      {website !== "" && (
        <Box width={[1]} p={[2]} sx={{ minHeight: 400 }}>
          <CategoriesWebsite websiteId={website} {...props} />
        </Box>
      )}
    </Box>
  );
};

export default CategoriesFields;
