import React from "react"
import { Field, FormSpy } from "react-final-form"
import { OnChange } from "react-final-form-listeners"
import { makeStyles } from "@material-ui/core/styles"
import { dataProvider } from "../../../../restClient"

const toolbar = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ color: [] }, { background: [] }],
  ["image"],
]
const DataProductType = [
  { id: "Product", name: "Product" },
  { id: "Bundle", name: "Bundle" },
  { id: "Service", name: "Service" },
]
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    minHeight: 200,
    borderRadius: 4,
    padding: theme.spacing(3),
  },
}))

const WhenFieldChanges = ({ field, set }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {(value) => {
              if (value && value !== null) {
                console.log(value)
                dataProvider
                  .getOne("attributeSets", { id: value })
                  .then((items) => {
                    if (items && items.data) {
                      console.log(items.data)
                      const { data } = items
                      let myObjAttributes = {}
                      data.attributes.map((item) => {
                        myObjAttributes[item] = {
                          id: item,
                          description: "",
                          value: 0,
                          switch: false,
                        }
                        return {
                          id: item,
                          description: "",
                          value: 0,
                          switch: false,
                        }
                      })
                      console.log(myObjAttributes)
                      onChange(myObjAttributes)
                    }
                  })
                  .catch((e) => {
                    console.error(e)
                  })
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
)

export { toolbar, WhenFieldChanges, useStyles, DataProductType }
