import React, { cloneElement } from "react"
import {
  useCreateController,
  useTranslate,
  useRefresh,
  useNotify,
  useRedirect,
} from "react-admin"
import { find, isNaN } from "lodash"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import { Flex } from "rebass"

import { FormFields } from "./components"
import { BetterCustomForm, FooterToolbar } from "../../components"
import { getSiteID } from "../../restClient"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
    position: "fixed",
    zIndex: 10,
    backgroundColor: "#FFFFFF",
  },
  form: {
    paddingTop: 62,
    [theme.breakpoints.up("xs")]: {
      width: 600,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}))
const initValues = {
  site_id: getSiteID(),
  filters: [],
}

const MyCreate = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = useCreateController(props)
  return (
    <div>
      {cloneElement(props.children, {
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
      })}
    </div>
  )
}
function getNumbers(string) {
  var output = []

  output = string.replace(/'/g, "").split(/(\d+)/).filter(Boolean)
  const result = find(output, function (o) {
    return o !== "?pid="
  })
  return result * 1
}

const CategoriesCreate = (props) => {
  const { onCancel, location, website } = props
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const redirect = useRedirect()
  const controllerProps = useCreateController(props)
  const translate = useTranslate()
  if (!controllerProps.record) {
    return null
  }
  const onSuccess = () => {
    redirect("list", "/categories")
    refresh()
    notify("ra.notification.created")
  }
  let initParentIdValue = { ...initValues, website_id: website }

  if (location.search && location.search !== null && location.search !== "") {
    const result = getNumbers(location.search)
    if (!isNaN(result)) {
      initParentIdValue["parent_id"] = result
    }
  }

  return (
    <div className={classes.root}>
      <Flex
        style={{ backgroundColor: "#FFFFFF", position: "fixed", zIndex: 20 }}
        width={[1]}
        flexDirection={"column"}
      >
        <Flex
          width={[1, "600px"]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={[2]}
        >
          <Typography variant="h6">
            {translate("resources.categories.create_title")}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Flex>
      </Flex>
      <MyCreate {...props} onSuccess={onSuccess}>
        <BetterCustomForm
          {...props}
          initialValues={initParentIdValue}
          toolbar={<FooterToolbar />}
          className={classes.form}
        >
          <FormFields {...props} isCreate={true} />
        </BetterCustomForm>
      </MyCreate>
    </div>
  )
}

export default CategoriesCreate
