import React from "react";
import { useQueryWithStore } from "react-admin";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogDelete from "./DialogDelete";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    padding: 4,
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCellSmall = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: 100,
  },
  body: {
    width: 100,
    padding: 4,
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCellAction = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    width: 80,
    padding: 0,
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: 540,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TableRowFilter = ({ id, classes, row, onEdit, resource, onDelete }) => {
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: resource,
    payload: { id: id },
  });

  if (!loaded) {
    return (
      <TableRow>
        <TableCell component='td'>Loading ...</TableCell>
      </TableRow>
    );
  }

  return (
    <StyledTableRow>
      <StyledTableCell component='th' scope='row'>
        {data && data.name}
      </StyledTableCell>
      <StyledTableCellSmall component='td' scope='type'>
        {row && row.type}
      </StyledTableCellSmall>
      <StyledTableCellAction align='right'>
        <IconButton
          className={classes.margin}
          size='small'
          onClick={() => onEdit(row)}
        >
          <EditIcon fontSize='inherit' />
        </IconButton>
        <DialogDelete onDelete={() => onDelete(row)} classes={classes} />
      </StyledTableCellAction>
    </StyledTableRow>
  );
};

const TableFilters = (props) => {
  const { formData, translate, onEdit, onDelete } = props;
  const classes = useStyles();

  return (
    <TableContainer component={"div"}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              {translate("resources.categories.fields.name")}
            </TableCell>
            <TableCell>
              {translate("resources.categories.fields.type")}
            </TableCell>
            <TableCell align='right' />
          </TableRow>
        </TableHead>
        <TableBody>
          {formData &&
            formData.filters &&
            formData.filters.length > 0 &&
            formData.filters.map((row, index) => (
              <TableRowFilter
                key={index}
                row={row}
                id={
                  row.type === "Attribute" ? row.attribute_id : row.variant_id
                }
                classes={classes}
                resource={row.type === "Attribute" ? "attributes" : "variants"}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableFilters;
