import React from "react";
import { Create } from "react-admin";
import { FormFields, WizardEditorForm, ContentField } from "./components";
import { FooterToolbar, DetailAction, Loading } from "../../components";
import FormTab from "./components/FormTab";
import { getSiteID, getWebsites } from "../../restClient";
import { useQuery } from "react-query";
import moment from "moment";
const cacheTimeMinute = 1000 * 60 * 30;
const initValues = {
  site_id: getSiteID(),
  start_date: moment(),
  end_date: moment().add(7, "days"),
};

export const ContentCreate = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props;
  const { data, isLoading } = useQuery("websites", () => getWebsites(), {
    cacheTime: cacheTimeMinute,
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Create
      {...rest}
      actions={<DetailAction title={"resources.contents.new_page"} />}
    >
      <WizardEditorForm
        {...rest}
        toolbar={<FooterToolbar />}
        redirect='list'
        submitOnEnter={false}
        initialValues={initValues}
      >
        <FormTab label='resources.products.tabs.info' path={""}>
          <FormFields {...rest} isCreate={true} websites={data} />
        </FormTab>
        <FormTab label='resources.products.tabs.images' path={"page"}>
          <ContentField {...rest} isCreate={true} />
        </FormTab>
      </WizardEditorForm>
    </Create>
  );
};
