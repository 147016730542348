import React from "react"
import { Edit, useTranslate } from "react-admin"
import {
  TopBarTitleAction,
  BetterCustomForm,
  EditToolbar,
} from "../../components"
import { FormFields } from "./components"
const VariantsEdit = (props) => {
  const translate = useTranslate()
  return (
    <Edit
      {...props}
      actions={
        <TopBarTitleAction title={translate("resources.variants.edit_title")} />
      }
      undoable={false}
    >
      <BetterCustomForm {...props} toolbar={<EditToolbar />}>
        <FormFields {...props} />
      </BetterCustomForm>
    </Edit>
  )
}

export default VariantsEdit
