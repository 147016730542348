import React, { useState, useEffect } from "react";
import { Flex } from "rebass";
import { filter, get } from "lodash";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Loading } from "react-admin";
// import { ListShipment } from "./shipment_item";
import { dataTypeAmendment } from "./utils";
import { RestApi } from "../../../restClient";
import { EmptyList, PaperCard } from "../../../components";
import { ListAmendment } from "./amendment_item";
const AmendmentFields = (props) => {
  const { record, dataProvider, translate } = props;
  const orderId = get(record, "id", 0);
  const [type, setType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const handleChange = (e) => {
    setType(e.target.value);
  };
  const onChangeText = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      RestApi(`/orders/${orderId}/amendments?search=${searchText}`)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          // console.log(res);
          if (res) {
            setList(res);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [orderId, searchText, dataProvider]);
  // if (loading) {
  //   return ;
  // }
  const myList = filter(list, function (o) {
    return type === o.type;
  });
  const newList = type === "" ? list : myList;
  // console.log(newList);
  return (
    <Flex width={[1]} flexDirection={"column"} py={[2]}>
      <PaperCard
        title={""}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3] }}
      >
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          p={[3]}
        >
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id='provider-select-label'>
              {translate("resources.orders.fields.type")}
            </InputLabel>
            <Select
              labelId='provider-select-label'
              id='provider-select'
              value={type}
              onChange={handleChange}
            >
              {dataTypeAmendment.map((p, index) => {
                return (
                  <MenuItem value={p.id} key={index}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id='search-amendment'
            label='Search'
            onChange={onChangeText}
            value={searchText}
          />
        </Flex>
        <Flex width={[1]} flexDirection={"column"}>
          {loading && <Loading />}
          {newList.length === 0 ? (
            <EmptyList message={"resources.orders.messages.no_record"} />
          ) : (
            <ListAmendment {...props} list={newList} />
          )}
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default AmendmentFields;
