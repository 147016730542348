import React, { Children, useCallback, isValidElement, useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classnames from "classnames"
import { includes } from "lodash"
import { FormSpy } from "react-final-form"
import { Route } from "react-router-dom"

import {
  useInitializeFormWithRecord,
  setSidebarVisibility,
  Toolbar,
} from "react-admin"
import { IsDebugForm } from "../../../components/form/ValuesForm"
import { API_SERVER, API_DEBUG } from "../../../restClient"
import { sanitizeRestProps } from "../../../components/form/utils"
import { getTabFullPath } from "./TabbedFormTabs"
import { useRouteMatch, useLocation } from "react-router-dom"

const TabbedFormView = ({
  basePath,
  children,
  className,
  classes = {},
  form,
  handleSubmit,
  invalid,
  pristine,
  record,
  redirect: defaultRedirect,
  resource,
  saving,
  setRedirect,
  submitOnEnter,
  tabs,
  toolbar,
  translate,
  undoable,
  value,
  version,
  variant,
  margin,
  dirtyFieldsSinceLastSubmit,
  setSidebarVisibility,
  ...rest
}) => {
  useInitializeFormWithRecord(record)
  // setSidebarVisibility(false)
  useEffect(() => {
    setSidebarVisibility(true)
  })

  const handleSubmitWithRedirect = useCallback(
    (redirect = defaultRedirect) => {
      setRedirect(redirect)
      handleSubmit()
    },
    [setRedirect, defaultRedirect, handleSubmit]
  )

  // const tabsWithErrors = findTabsWithErrors(children, form.getState().errors)

  const match = useRouteMatch()
  const location = useLocation()

  const url = match ? match.url : location.pathname

  const { updateField } = form.mutators

  const isShow = includes(location.pathname, "page")

  return (
    <form
      className={classnames("tabbed-form", className)}
      key={version}
      {...sanitizeRestProps(rest)}
    >
      <div className={classes.content}>
        {Children.map(children, (tab, index) => {
          const newChild = tab.props.children
          const newTab = React.cloneElement(tab, {
            children: React.cloneElement(newChild, {
              updateField: updateField,
            }),
          })

          return (
            newTab && (
              <Route exact path={getTabFullPath(newTab, index, url)}>
                {(routeProps) => {
                  // console.log("url=>", url)
                  return isValidElement(newTab) ? (
                    <div>
                      {React.cloneElement(newTab, {
                        intent: "content",
                        resource,
                        record,
                        basePath,
                        hidden: !routeProps.match,
                        variant,
                        margin,
                        classes,
                      })}
                    </div>
                  ) : null
                }}
              </Route>
            )
          )
        })}
      </div>
      {!isShow &&
        toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          className: "toolbar",
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect: defaultRedirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
      {!isShow && API_SERVER === API_DEBUG && IsDebugForm && (
        <FormSpy subscription={{ values: true }}>
          {(props) => {
            return (
              <code style={{ fontSize: 10, padding: 10, margin: 16 }}>
                {JSON.stringify(props.values, undefined, 2)}
              </code>
            )
          }}
        </FormSpy>
      )}
    </form>
  )
}

TabbedFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  tabsWithErrors: PropTypes.arrayOf(PropTypes.string),
  toolbar: PropTypes.element,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.number,
  version: PropTypes.number,
}

TabbedFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar />,
}
export default connect(null, { setSidebarVisibility })(TabbedFormView)
