import React, { Fragment } from "react"
import { Flex } from "rebass"
import { FormDataConsumer } from "react-admin"
import { RaTextInput, RaLocaleTabs } from "../../../components"
import { getSiteID } from "../../../restClient"
const FormFieldDialog = (props) => {
  return (
    <Flex width={[1]} flexDirection={"column"} style={{ minHeight: 135 }}>
      <FormDataConsumer>
        {({ formData }) => {
          const SiteID =
            formData.site_id === getSiteID() ? formData.site_id : getSiteID()
          return (
            <RaLocaleTabs
              siteId={SiteID}
              nameTab="tab-variants-info-dialog"
              isHideLoading={true}
            >
              <ContentItem {...props} />
            </RaLocaleTabs>
          )
        }}
      </FormDataConsumer>
    </Flex>
  )
}

const ContentItem = (props) => {
  const { locale } = props

  return (
    <Fragment>
      <RaTextInput
        source={`locale.${locale}.name`}
        label="resources.variants.fields.name"
        style={{ width: 400 }}
      />
      <RaTextInput
        source={`locale.${locale}.code`}
        label="resources.variants.fields.code"
        style={{ width: 400 }}
        notetext={"resources.variants.note_text.code"}
      />
    </Fragment>
  )
}

export default FormFieldDialog
