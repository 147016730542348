import React from "react";
import { TextField, SearchInput } from "react-admin";
import { ButtonEditView, ListAction } from "../../components";
import { BetterList, Filter } from "../../components/ra-list";
import moment from "moment";
import { get } from "lodash";
import { Flex } from "rebass";
import Chip from "@material-ui/core/Chip";

const DateField = ({ record, source }) => {
  const myDate = get(record, source, "");
  if (myDate === null || myDate === "") {
    return <span />;
  }
  return <span>{moment(myDate).format("DD-MMM-YYYY")}</span>;
};

const TagsField = ({ record, source }) => {
  const myTags = get(record, source, []);
  if (myTags && myTags !== null && myTags.length > 0) {
    return (
      <Flex
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{ "&>div": { p: [1] } }}
      >
        {myTags.map((item, index) => {
          return <Chip label={item} color='primary' size='small' key={index} />;
        })}
      </Flex>
    );
  }
  return <span />;
};

const ContentFilter = (props) => (
  <Filter {...props}>
    <SearchInput source='search' alwaysOn />
  </Filter>
);

export const ContentList = (props) => (
  <BetterList
    {...props}
    actions={<ListAction title={`resources.contents.name`} />}
    filters={<ContentFilter />}
  >
    <TextField source='title' />
    <TagsField source={"tags"} />
    <DateField
      source={"start_date"}
      label={"resources.contents.fields.start_date"}
    />
    <DateField
      source={"end_date"}
      label={"resources.contents.fields.end_date"}
    />
    <ButtonEditView {...props} />
  </BetterList>
);
