import React, { Component } from "react";
import { Box, Flex, Text } from "rebass";
import { filter, isArray } from "lodash";
import { IconButton, Dialog, Button } from "@material-ui/core";
import DesktopIcon from "@material-ui/icons/DesktopMacTwoTone";
import MobileIcon from "@material-ui/icons/PhoneIphoneTwoTone";
import { StaticPage } from "../../../editorPreview";
import { DialogTitle, DialogContent } from "../../../components";
import { BoxMobile } from "./ContentLayout";
import { withDataProvider } from "react-admin";
class ShowContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      device: "desktop",
      settings: null,
    };
  }

  componentDidMount() {
    const { dataProvider, websiteId } = this.props;
    dataProvider
      .getList("websiteLayouts", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
        filter: { website_id: websiteId },
      })
      .then(({ data }) => {
        if (data) {
          const menuOne = filter(data, function (o) {
            return o.default === true;
          });

          const dataSource = menuOne.length > 0 ? menuOne[0] : null;
          this.setState({
            settings: dataSource,
          });
        }
      })
      .catch((error) => {});
  }

  handleOpenDesktop = () => {
    this.setState({
      open: true,
      device: "desktop",
    });
  };
  handleOpenMobile = () => {
    this.setState({
      open: true,
      device: "mobile",
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  showDeviceDesktop = () => {
    this.setState({
      device: "desktop",
    });
  };
  showDeviceMobile = () => {
    this.setState({
      device: "mobile",
    });
  };
  render() {
    const { open, device } = this.state;
    const {
      localeId,
      content,
      translate,
      heightScreen,
      widthScreen,
      websiteId,
    } = this.props;
    let newContent = undefined;
    if (open) {
      const localeContent = filter(content, function (o) {
        return o.id === localeId;
      });

      newContent =
        localeContent.length > 0 ? localeContent[0].layoutSelected : undefined;
    }
    return (
      <div>
        <Flex
          flexDirection={"column"}
          pt={[2]}
          pb={[2]}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            onClick={this.handleOpenDesktop}
            color='primary'
            style={{ textTransform: "capitalize" }}
          >
            {translate("resources.buttons.preview")}
          </Button>
        </Flex>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby='customized-show-content-dialog-title'
          open={open}
          fullScreen={true}
          scroll={"paper"}
        >
          <DialogTitle
            id='customized-how-content-dialog-title'
            onClose={this.handleClose}
          >
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Text pr={[4]}>Preview</Text>

              <Flex flexDirection={"row"} alignItems={"center"}>
                <IconButton onClick={this.showDeviceDesktop} color={"primary"}>
                  <DesktopIcon style={{ color: "#0087BA" }} />
                </IconButton>
                <Flex p={[2]} />
                <IconButton onClick={this.showDeviceMobile}>
                  <MobileIcon style={{ color: "#0087BA" }} />
                </IconButton>
              </Flex>
            </Flex>
          </DialogTitle>
          <DialogContent dividers>
            <Box width={[1]} style={{ height: "100%" }}>
              {newContent &&
                !isArray(newContent) &&
                (device === "mobile" ? (
                  <BoxMobile
                    data={newContent}
                    locale={localeId}
                    heightScreen={heightScreen}
                    widthScreen={widthScreen}
                    websiteId={websiteId}
                    settings={this.state.settings}
                  />
                ) : (
                  <StaticPage
                    data={newContent}
                    locale={localeId}
                    heightScreen={heightScreen}
                    widthScreen={widthScreen}
                    websiteId={websiteId}
                    settings={this.state.settings}
                  />
                ))}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withDataProvider(ShowContent);
