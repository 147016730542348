import React from "react"
import { Box, Flex, Text } from "rebass"
import { get } from "lodash"
import ChipInput from "material-ui-chip-input"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Switch from "@material-ui/core/Switch"

const ItemText = props => {
  const { translate, onUpdateFilter, filter } = props
  const [chip, setChip] = React.useState(get(filter, "data_filters", []))
  const [isAuto, setIsAuto] = React.useState(
    get(filter, "is_auto_filter", false)
  )
  const handleAddChip = valueChip => {
    const values = [...chip, valueChip]
    setChip(values)
    onUpdateFilter("data_filters", values)
  }
  const handleDeleteChip = (valueChip, index) => {
    const values = chip.filter(v => v !== valueChip)
    setChip(values)
    onUpdateFilter("data_filters", values)
  }
  const handleChange = event => {
    setIsAuto(event.target.checked)
    onUpdateFilter("is_auto_filter", event.target.checked)
  }

  return (
    <Box width={[1]} p={[2]} mb={[2]}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch checked={isAuto} onChange={handleChange} value={isAuto} />
          }
          label={translate("resources.filters.fields.automatics")}
        />
        <FormHelperText>
          {translate("resources.filters.note_text.is_filter")}
        </FormHelperText>
      </FormControl>
      {!isAuto && (
        <Flex
          width={[1]}
          pt={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-end"}
        >
          <Text width={"80px"} pr={[3]}>
            {translate("resources.filters.fields.filters")}
          </Text>

          <ChipInput
            value={filter.data_filters}
            onAdd={chip => handleAddChip(chip)}
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
            helperText={translate("resources.filters.note_text.filter")}
            fullWidth
          />
        </Flex>
      )}
    </Box>
  )
}
export default ItemText
