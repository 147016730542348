import React from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { Currency } from "../currency";
const RaCurrencyField = (props) => {
  const { record, source, record_parent } = props;
  const currency_symbol = get(record, "locale.currency_symbol", "");
  const decimal_separator = get(record, "locale.decimal_separator", ".");
  const thousand_separator = get(record, "locale.thousand_separator", ",");
  const ds = decimal_separator === "comma" ? "," : ".";
  const ts = thousand_separator === "dot" ? "." : ",";
  return (
    <Flex
      flexDirection={"row"}
      flexWrap={"wrap"}
      alignItems={"center"}
      sx={{ minHeight: 44 }}
    >
      <Currency
        value={get(record_parent, source, "")}
        thousandSeparator={ts}
        decimalSeparator={ds}
        unit={`${currency_symbol} `}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    </Flex>
  );
};

export default RaCurrencyField;
