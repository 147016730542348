import React, { useState } from "react";
import { Edit, useTranslate } from "react-admin";
import {
  BetterTabbedForm,
  EditWithDeleteToolbar,
  TopBarTitleAction,
  // FormCustomTab,
  FormValueTab,
} from "../../components";
import {
  InfoFields,
  ImageFields,
  // CustomizationsFields,
  // BundlesFields,
  AttributesFields,
  VariantsFields,
  CategoriesFields,
} from "./components";

const ProductEdit = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props;
  const translate = useTranslate();
  const [attUpdate, setAttUpdate] = useState(true);
  const onDisabledUpdated = () => {
    setAttUpdate(false);
  };
  return (
    <Edit
      {...rest}
      actions={
        <TopBarTitleAction title={translate("resources.products.edit_title")} />
      }
    >
      <BetterTabbedForm
        toolbar={<EditWithDeleteToolbar />}
        {...rest}
        value_validation={"slug"}
      >
        <FormValueTab label='resources.products.tabs.info'>
          <InfoFields {...rest} translate={translate} />
        </FormValueTab>
        <FormValueTab
          label='resources.products.tabs.variants'
          path={"variants"}
        >
          <VariantsFields {...rest} />
        </FormValueTab>
        <FormValueTab label='resources.products.tabs.images' path={"images"}>
          <ImageFields {...rest} />
        </FormValueTab>
        <FormValueTab
          label='resources.products.tabs.categories'
          path={"categories"}
        >
          <CategoriesFields {...rest} />
        </FormValueTab>
        <FormValueTab
          label='resources.products.tabs.attributes'
          path={"attributes"}
        >
          <AttributesFields
            {...rest}
            translate={translate}
            onDisabledUpdated={() => onDisabledUpdated()}
            attUpdate={attUpdate}
          />
        </FormValueTab>
        {/* <FormTab label="resources.products.tabs.custom" path={"customizations"}>
          <CustomizationsFields {...rest} />
        </FormTab>
        <FormCustomTab
          label="resources.products.tabs.bundles"
          path={"bundles"}
          showSource={"product_type"}
          showValue="Bundle"
        >
          <BundlesFields {...rest} />
        </FormCustomTab> */}
      </BetterTabbedForm>
    </Edit>
  );
};

export default ProductEdit;
