import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Flex, Box } from "rebass";
import { withStyles } from "@material-ui/core/styles";
import { styles, StyledTableCell, StyledTableRow } from "./styles";
import { get } from "lodash";

const RowItem = (props) => {
  const { item } = props;
  //   const contents = get(item, "contents", []);
  return (
    <StyledTableRow>
      <StyledTableCell>{get(item, "line_number", "")}</StyledTableCell>
      <StyledTableCell>{get(item, "sku", "")}</StyledTableCell>
      <StyledTableCell>{get(item, "qty", "")}</StyledTableCell>
      {/* <StyledTableCell>
        {get(item, "active", false) ? "Active" : "Inactive"}
      </StyledTableCell> */}
      {/* {contents &&
        contents.length > 0 &&
        contents.map((itemrow, indexRow) => {
          return (
            <StyledTableCell key={indexRow}>
              {itemrow !== "" ? itemrow : ""}
            </StyledTableCell>
          );
        })} */}
    </StyledTableRow>
  );
};
const ListProducts = (props) => {
  const { list, translate, classes } = props;
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Box width={[1]} py={[3]}>
        <TableContainer component={"div"}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {translate("resources.products.fields.line_number")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.products.fields.sku")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.products.fields.qty")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list &&
                list.length > 0 &&
                list.map((item, index) => {
                  return <RowItem key={index} item={item} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};

export default withStyles(styles)(ListProducts);
