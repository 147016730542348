import { get, isArray, filter } from "lodash";

export const getPaymentInfo = (record) => {
  const myPaymentInfo = get(record, "payment_info");

  if (myPaymentInfo && myPaymentInfo !== null) {
    if (isArray(myPaymentInfo)) {
      const myPI = filter(myPaymentInfo, function (o) {
        return o.order_id === record.id;
      });
      if (myPI.length > 0) {
        return myPI[0];
      }
    }
  }
  return myPaymentInfo;
};
