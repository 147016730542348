import React from "react"
import { Create } from "react-admin"
import { BetterCustomSimpleForm, FooterToolbar, BackTitleAction } from "./"
import { FormFields } from "./components"

export const AttributeSetCreate = (props) => {
  return (
    <Create
      {...props}
      actions={
        <BackTitleAction
          title={"resources.attributeSet.new_attributeset"}
          backTitle="resources.attributeSet.back"
          basePath="/attributes"
        />
      }
    >
      <BetterCustomSimpleForm
        {...props}
        toolbar={<FooterToolbar />}
        labelBack={"resources.attributeSet.new_attributeset"}
        title={"resources.attributeSet.back"}
        sourceApi="attributeSets"
        typePostUrl={"CREATE"}
        pathUrl="attributeSets"
        isShowBack={true}
        hideAction={true}
      >
        <FormFields {...props} />
      </BetterCustomSimpleForm>
    </Create>
  )
}
