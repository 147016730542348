import React, { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Flex, Text } from "rebass";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import UploadCsv from "./UploadCsv";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const myListType = [
  { id: 0, name: "Footwear" },
  { id: 1, name: "SESM" },
  { id: 2, name: "SESM without color" },
];
export default (props) => {
  const { open, setOpen, onSubmit, translate } = props;
  const [typeImport, setTypeImport] = useState(0);
  return (
    <Fragment>
      {/* */}
      <Button
        color='primary'
        // disabled={disableClick}
        onClick={() => setOpen(true)}
        style={{
          textTransform: "capitalize",
        }}
      >
        {translate("resources.buttons.import_product_csv")}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"lg"}>
        <DialogTitle>
          <Flex
            width={500}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text>{translate("resources.buttons.import_product_csv")}</Text>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Flex>
        </DialogTitle>
        <DialogContent>
          <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
            <Select
              labelId='type_import-select-label'
              id='type_import-select'
              value={typeImport}
              onChange={(e) => setTypeImport(e.target.value)}
              label={"Type"}
              style={{ width: 256, marginBottom: 16 }}
            >
              {myListType.map((item) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
            <UploadCsv
              handleSubmit={(data) => onSubmit(data)}
              translate={translate}
              typeImport={typeImport}
              urlPath={"/products/import"}
              setOpen={(v) => setOpen(v)}
            />
          </Flex>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
