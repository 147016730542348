import React, { useState } from "react";

import { useTranslate } from "react-admin";
import SubMenuItem from "./SubMenuItem";
import MenuItemLink from "../MenuItemLink";
import { StarIcon } from "../../icon_svg";

const MenuProducts = (props) => {
  const { classes, onMenuClick } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();
  return (
    <SubMenuItem
      handleToggle={() => setOpenMenu(!openMenu)}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name='resources.menus.products'
      icon={<StarIcon />}
    >
      <MenuItemLink
        to={`/products`}
        primaryText={translate(`resources.menus.list`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to='/inventories'
        primaryText={translate(`resources.menus.inventory`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/priceBooks`}
        primaryText={translate(`resources.menus.price_book`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/categories`}
        primaryText={translate(`resources.menus.categories`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/collections`}
        primaryText={translate(`resources.menus.collections`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/variants`}
        primaryText={translate(`resources.menus.variants`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/attributes`}
        primaryText={translate(`resources.menus.attributes`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
    </SubMenuItem>
  );
};

export default MenuProducts;
