import React, { useState } from "react"
import { useStyles } from "./styles"
import { Flex, Box } from "rebass"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { AttributeField } from "./AttributeField"
import ItemNumbers from "./ItemNumbers"
import ItemText from "./ItemText"
import VariantSelectField from "./VariantSelectField"
import { listType } from "./utils"

const FilterForm = (props) => {
  const { onCancel, translate, onSave, valuesFilter, attributeSelected } = props
  const [attribute, setAttribute] = React.useState(attributeSelected)
  const [filter, setFilter] = useState(valuesFilter)
  const classes = useStyles()
  const onUpdateFilter = (key, value) => {
    setFilter({ ...filter, [key]: value })
  }
  const onUpdateAttribute = (key, value, attribute) => {
    setFilter({ ...filter, [key]: value })
    setAttribute(attribute)
  }
  // console.log(filter)
  return (
    <div>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.categories.create_filter")}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.form}>
        <Flex width={1} flexDirection={"column"} p={[2]}>
          <Box width={[1]}>
            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-label">
                {translate(`resources.categories.fields.type`)}
              </InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={filter.type}
                onChange={(e) => onUpdateFilter("type", e.target.value)}
              >
                {listType.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          {filter.type === "Attribute" && (
            <AttributeField
              {...props}
              onUpdateFilter={onUpdateAttribute}
              filter={filter}
              attribute={attribute}
            />
          )}
          {filter.type === "Variant" && (
            <VariantSelectField
              {...props}
              onUpdateFilter={onUpdateFilter}
              filter={filter}
              classes={classes}
            />
          )}
          {attribute.data_type === "Number" && (
            <ItemNumbers
              {...props}
              onUpdateFilter={onUpdateFilter}
              filter={filter}
            />
          )}
          {(attribute.data_type === "Text" ||
            attribute.data_type === "Rich Text" ||
            filter.type === "Variant") && (
            <ItemText
              {...props}
              onUpdateFilter={onUpdateFilter}
              filter={filter}
            />
          )}
          <Box p={[2]}>
            <Button
              color={"primary"}
              variant={"contained"}
              style={{ textTransform: "capitalize" }}
              onClick={() => onSave(filter)}
            >
              {translate("resources.buttons.save")}
            </Button>
          </Box>
        </Flex>
      </div>
    </div>
  )
}

export default FilterForm
