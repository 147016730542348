import React from "react"
import { useQueryWithStore } from "react-admin"
import LinearProgress from "@material-ui/core/LinearProgress"

import { TextRecord } from "../../../components"

const ProductField = (props) => {
  const { record } = props
  const { loading, data } = useQueryWithStore({
    type: "getOne",
    resource: "products",
    payload: { id: record.product_id },
  })
  if (loading) {
    return <LinearProgress />
  }
  return (
    <TextRecord
      label={"resources.reviews.fields.product"}
      source="name"
      record={data}
    />
  )
}

export default ProductField
