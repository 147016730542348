import React from "react"
import { get } from "lodash"
import { Flex } from "rebass"
import Button from "@material-ui/core/Button"
import {
  ReferenceInput,
  ArrayInput,
  useTranslate,
  AutocompleteInput,
  NumberInput,
  SelectInput,
} from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./ExpandPanel"

import {
  RaSelectInput,
  RaTextInput,
  // RaNumberInput,
  RaBooleanInput,
  TableFormIterator,
} from "./"
const dataType = [
  { id: "Dropdown", name: "Dropdown" },
  { id: "Radio Buttons", name: "Radio Buttons" },
]
const dataOptionsType = [
  { id: "Amount", name: "Amount" },
  { id: "Fixed", name: "Fixed" },
  { id: "Percent", name: "Percent" },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    borderRadius: 4,
    padding: theme.spacing(0),
  },
}))

const ItemsBundle = (props) => {
  const { items, classes, indexItem } = props
  return (
    <Box
      boxShadow={3}
      className={classes.paper}
      display="flex"
      flexDirection="column"
      mb={[2]}
    >
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="bundle-panel1-content"
          id="bundle-panel1-header"
        >
          <Typography>{items.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Flex width={[1]} flexDirection={"column"}>
            <div className={classes.root}>
              <RaTextInput
                source={`bundle_options[${indexItem}].title`}
                label={"resources.products.fields.option_title"}
                style={{ width: 500 }}
              />
              <RaSelectInput
                source={`bundle_options[${indexItem}].type`}
                choices={dataType}
                margin="dense"
                label={"resources.products.fields.option_type"}
                style={{ width: 180, marginLeft: 16, marginRight: 16 }}
              />

              <RaBooleanInput
                label={"resources.products.fields.option_required"}
                source={`bundle_options[${indexItem}].required`}
                defaultValue={false}
                style={{ marginTop: 16 }}
              />
            </div>
            <ArrayInput
              source={`bundle_options[${indexItem}].options`}
              label=""
            >
              <TableFormIterator titleAdd="resources.buttons.add_product">
                <ReferenceInput
                  label="resources.products.fields.product"
                  source="product_id"
                  reference="products"
                  sort={{ field: "id", order: "ASC" }}
                  filterToQuery={(searchText) => ({
                    name: searchText,
                  })}
                >
                  <AutocompleteInput optionText="name" fullWidth={true} />
                </ReferenceInput>

                <NumberInput
                  source={`price`}
                  label={"resources.products.fields.price"}
                />
                <SelectInput
                  source={`type`}
                  choices={dataOptionsType}
                  margin="dense"
                  label={"resources.products.fields.type"}
                />
              </TableFormIterator>
            </ArrayInput>
          </Flex>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  )
}

const BundlesFields = (props) => {
  const { formValues } = props
  const translate = useTranslate()
  const classes = useStyles()
  const onAddOption = (data) => {
    data.push({
      type: "Dropdown",
      title: "Title",
      options: [],
      required: true,
    })
    props.updateField("bundle_options", data)
  }
  const bundle = get(formValues, "bundle_options", [])
  return (
    <Flex flexDirection={"column"} p={[2]} m={[2]}>
      <Flex
        flexDirection={"row"}
        width={[1]}
        justifyContent={"flex-end"}
        p={[2]}
      >
        <Button color={"primary"} onClick={() => onAddOption(bundle)}>
          {translate("resources.buttons.add_option")}
        </Button>
      </Flex>
      {bundle.length > 0 &&
        bundle.map((items, index) => {
          return (
            <ItemsBundle
              indexItem={index}
              key={index}
              items={items}
              classes={classes}
            />
          )
        })}
    </Flex>
  )
}

export default BundlesFields
