import React, { useState } from "react";
// import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin";
// import { styles } from "./styles"
import SubMenuItem from "./SubMenuItem";
import MenuItemLink from "../MenuItemLink";
import { DocumentIcon } from "../../icon_svg";

const MenuContent = (props) => {
  const { classes, onMenuClick } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();
  return (
    <SubMenuItem
      handleToggle={() => setOpenMenu(!openMenu)}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name="resources.menus.content"
      icon={<DocumentIcon />}
    >
      <MenuItemLink
        to={`/contents`}
        primaryText={translate(`resources.menus.pages`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
        to={`/banners`}
        primaryText={translate(`resources.menus.banners`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      /> */}
    </SubMenuItem>
  );
};

export default MenuContent;
