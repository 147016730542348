import React, { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
// import UploadCsv from "./UploadCsv";
import DialogResult from "./DialogResult";
import DialogUpload from "./DialogUpload";
export default (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState([]);
  const onSubmit = (data) => {
    setOpen(false);
    if (Array.isArray(data)) {
      if (data && data.length > 0) {
        setShow(true);
        setResult(data);
      } else {
        notify("resources.products.messages.upload_csv_success", "success");
      }
    } else {
      notify("resources.products.messages.upload_csv_success", "success");
    }
  };
  return (
    <div>
      <DialogUpload
        onSubmit={(data) => onSubmit(data)}
        translate={translate}
        open={open}
        setOpen={setOpen}
        {...props}
        notify={notify}
      />
      {show && (
        <DialogResult
          open={show}
          translate={translate}
          list={result}
          setOpen={setShow}
          {...props}
          notify={notify}
        />
      )}
    </div>
  );
};
