import React from "react"
import { Flex } from "rebass"
import { useTranslate } from "react-admin"
import { CreateFormVariants } from "./variants"
const CreateVariantFields = (props) => {
  const { formValues, ...rest } = props
  const translate = useTranslate()
  return (
    <Flex width={[1]} flexDirection={"column"} p={[2]}>
      <CreateFormVariants
        {...rest}
        formData={formValues}
        translate={translate}
      />
    </Flex>
  )
}

export default CreateVariantFields
