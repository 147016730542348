import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SwipeableViews from "react-swipeable-views"
// import { useRouteMatch, useLocation } from "react-router-dom"
import { Divider, Tabs, Tab } from "@material-ui/core"
import { TabPanel, a11yProps } from "../../../../components"
import SourceGroupDataGrid from "./SourceGroupDataGrid"
import SourceDataGrid from "./SourceDataGrid"

const tabs = [
  { id: "sources", name: "Source" },
  {
    id: "sourceGroup",
    name: "Source Group",
  },
]
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}))

const ListSources = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange}>
        {tabs.map((choice, index) => (
          <Tab
            key={choice.id}
            label={choice.name}
            {...a11yProps("sources", index)}
          />
        ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          name={"sources"}
        >
          <SourceDataGrid {...props} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          name={"sources"}
        >
          <SourceGroupDataGrid {...props} />
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default ListSources
