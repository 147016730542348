import React from "react";
import { Edit, useTranslate } from "react-admin";

import {
  BetterCustomForm,
  TopBarTitleAction,
  EditToolbar,
  Loading,
} from "../../components";
import { FormFields } from "./components";
import { getWebsites } from "../../restClient";
//getSiteID,
import { useQuery } from "react-query";
const cacheTimeMinute = 1000 * 60 * 30;
const EditPromotion = (props) => {
  const translate = useTranslate();
  const { data, isLoading } = useQuery("websites", () => getWebsites(), {
    cacheTime: cacheTimeMinute,
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Edit
      {...props}
      actions={
        <TopBarTitleAction
          title={translate("resources.promotions.title.edit_promotion")}
        />
      }
    >
      <BetterCustomForm
        {...props}
        toolbar={<EditToolbar />}
        defaultSubscription={{
          submitting: true,
          pristine: false,
          valid: true,
          invalid: true,
        }}
      >
        <FormFields {...props} websites={data} />
      </BetterCustomForm>
    </Edit>
  );
};

export default EditPromotion;
