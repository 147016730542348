import React from "react";
import { filter, get, union, uniqBy, orderBy } from "lodash";
import { Flex, Box } from "rebass";
import { PaperCardWithButton } from "./PaperCard";
import { useNotify } from "react-admin";
import DialogProducts from "./DialogProducts";
// import TableVariants from "./TableVariants";
import TableProductsSKU from "./TableProductsSKU";
import ButtonImportCSV from "./ButtonImportCSV";
import ButtonExportCSV from "./ButtonExportCSV";
const ProductFields = (props) => {
  const { translate, formData, productSource } = props;
  const notify = useNotify();
  // const onDeleteProduct = (product) => {
  //   const products = get(formData, `criteria.${productSource}.products`, []);
  //   // console.log(product);
  //   const newProducts = filter(products, function (o) {
  //     return o.id + "" !== product.id + "";
  //   });
  //   // console.log(newProducts);
  //   props.updateField(`criteria.${productSource}.products`, newProducts);
  // };

  const setSelectedProduct = (selected, product) => {
    const products = get(formData, `criteria.${productSource}.products`, []);
    const newProducts = filter(products, function (o) {
      return o.id + "" !== product.id + "";
    });
    // console.log(product);
    const newData = union(newProducts, selected);
    // console.log(selected, newData);
    props.updateField(`criteria.${productSource}.products`, newData);
  };

  const onDeleteProductSKU = (product) => {
    const products = get(formData, `criteria.${productSource}.products`, []);
    console.log(product);
    const newProducts = filter(products, function (o) {
      return o.sku !== product.sku;
    });
    console.log(newProducts);
    props.updateField(`criteria.${productSource}.products`, newProducts);
  };

  const onImport = (list) => {
    const listproducts = get(
      formData,
      `criteria.${productSource}.products`,
      []
    );
    if (list && list.length > 0) {
      const newList = [...listproducts, ...list];
      console.log("newList", newList);
      const listP = uniqBy(newList, "sku");
      const listOrder = orderBy(listP, ["id", "sku"], ["asc"]);
      props.updateField(`criteria.${productSource}.products`, listOrder);
    } else {
      notify("resources.errors.list_empty", "warning", {
        smart_count: 1,
      });
    }
  };
  const onExport = () => {};
  const listproducts = get(formData, `criteria.${productSource}.products`, []);
  return (
    <Flex width={[1]} py={[1]} flexDirection="column">
      <PaperCardWithButton
        title={translate(
          "resources.promotions.labels.select_applicable_products"
        )}
        buttons={
          <Flex
            px={[2]}
            sx={{
              "&>*": {
                mx: [2],
              },
            }}
          >
            <ButtonImportCSV onImport={onImport} translate={translate} />
            <ButtonExportCSV
              onExport={onExport}
              translate={translate}
              products={listproducts}
            />
          </Flex>
        }
        elevation={3}
      >
        <Box width={[1]}>
          <DialogProducts {...props} />
        </Box>
        <TableProductsSKU
          {...props}
          onDeleteProduct={(p) => onDeleteProductSKU(p)}
          setSelectedProduct={setSelectedProduct}
        />
        {/* <TableVariants
          {...props}
          onDeleteProduct={onDeleteProduct}
          setSelectedProduct={setSelectedProduct}
        /> */}
      </PaperCardWithButton>
    </Flex>
  );
};

export default ProductFields;
