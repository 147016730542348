import React, { Component } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Flex, Image, Text } from "rebass";
import CardActions from "@material-ui/core/CardActions";
import { Notification } from "react-admin";
import LoginButton from "./LoginButton";
import { loginClientJWT, TOKEN, PROFILE, ROLE } from "../restClient";
const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2C4F6B",
  },
  card: {
    minWidth: 640,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    borderRadius: 16,
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
};

export default class Login extends Component {
  componentDidMount() {
    if (window.location.href.indexOf("?token=") >= 0) {
      return loginClientJWT(
        JSON.stringify({
          token: window.location.href.substring(
            window.location.href.indexOf("?token=") + 7
          ),
        })
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          localStorage.setItem(TOKEN, response.token);
          localStorage.setItem(PROFILE, JSON.stringify(response.profile));
          localStorage.setItem(ROLE, response.profile.role);
          window.location.href = "/";
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
  render() {
    const { theme } = this.props;
    const muiTheme = createMuiTheme(theme);

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div style={{ ...styles.main, backgroundColor: "#0087ba" }}>
          {/* <Flex
            width={[1]}
            justifyContent={["center"]}
            paddingTop={[3]}
            paddingBottom={[4]}
          >
            <Image src={require("../assets/images/logo.png")} height={120} />
          </Flex> */}
          <Card style={styles.card}>
            <Flex
              width={[1]}
              flexDirection={"column"}
              alignItems={["center"]}
              justifyContent={"center"}
              paddingTop={[4]}
              paddingBottom={[2]}
            >
              <Image
                src={require("../assets/images/logo901.png")}
                width={340}
              />
            </Flex>
            <Text
              style={{ textAlign: "center", color: "#00aeef" }}
              fontSize={[12, 20]}
              fontWeight={"bold"}
              paddingBottom={[2]}
            >
              Loyalty
            </Text>
            <CardActions>
              <LoginButton />
            </CardActions>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

Login.defaultProps = {
  theme: {},
};
