import React from "react"
import { get } from "lodash"
import { Flex } from "rebass"
import Button from "@material-ui/core/Button"
import {
  ReferenceInput,
  FormDataConsumer,
  ArrayInput,
  useTranslate,
} from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./ExpandPanel"

import {
  RaSelectInput,
  RaTextInput,
  RaNumberInput,
  RaBooleanInput,
  TableFormIterator,
  RaTextArea,
} from "./"
const dataType = [
  { id: "Dropdown", name: "Dropdown" },
  { id: "Radio Buttons", name: "Radio Buttons" },
]
const dataOptionsType = [
  { id: "Amount", name: "Amount" },
  { id: "Fixed", name: "Fixed" },
  { id: "Percent", name: "Percent" },
]

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    borderRadius: 4,
    padding: theme.spacing(0),
  },
}))

const ItemsCustomization = props => {
  const { items, classes, indexItem } = props
  return (
    <Box
      boxShadow={3}
      className={classes.paper}
      display="flex"
      flexDirection="column"
    >
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{items.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Flex width={[1]} flexDirection={"column"}>
            <div className={classes.root}>
              <RaTextInput
                source={`customization_options[${indexItem}].title`}
                label={"resources.products.fields.option_title"}
                style={{ width: 500 }}
              />
              <RaSelectInput
                source={`customization_options[${indexItem}].type`}
                choices={dataType}
                margin="dense"
                label={"resources.products.fields.option_type"}
                style={{ width: 180, marginLeft: 16, marginRight: 16 }}
              />

              <RaBooleanInput
                label={"resources.products.fields.option_required"}
                source={`customization_options[${indexItem}].required`}
                defaultValue={false}
                style={{ marginTop: 16 }}
              />
              <ReferenceInput
                label="resources.products.fields.option_page"
                source="content_di"
                reference="contents"
              >
                <RaSelectInput
                  optionText="title"
                  style={{ width: 256, marginRight: 16 }}
                />
              </ReferenceInput>
            </div>
            <ArrayInput
              source={`customization_options[${indexItem}].options`}
              label=""
            >
              <TableFormIterator titleAdd="resources.products.fields.addvalue">
                <RaTextArea
                  source="title"
                  label={"resources.products.fields.title"}
                  rows="3"
                  fullWidth
                  multiline
                  isLabel={false}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  style={{ transform: "none", padding: 0 }}
                />

                <RaNumberInput
                  source={`price`}
                  label={"resources.products.fields.price"}
                  style={{ width: 100 }}
                />
                <RaSelectInput
                  source={`type`}
                  choices={dataOptionsType}
                  margin="dense"
                  label={"resources.products.fields.type"}
                  style={{ width: 100 }}
                />
                <RaTextArea
                  source="sku"
                  label={"resources.products.fields.sku"}
                  rows="3"
                  fullWidth
                  multiline
                  isLabel={false}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  style={{ transform: "none" }}
                />
              </TableFormIterator>
            </ArrayInput>
          </Flex>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  )
}

const CustomizationsFields = props => {
  const translate = useTranslate()
  const classes = useStyles()
  const onAddOption = data => {
    data.push({
      type: "Dropdown",
      title: "Title",
      options: [],
      required: true,
    })
    props.updateField("customization_options", data)
  }
  return (
    <Flex width={[1]}>
      <FormDataConsumer>
        {({ formData }) => {
          const customize = get(formData, "customization_options", [])
          return (
            <Flex width={[1]} flexDirection={"column"} p={[2]} m={[2]}>
              <Flex
                flexDirection={"row"}
                width={[1]}
                justifyContent={"flex-end"}
                p={[2]}
              >
                <Button
                  color={"primary"}
                  onClick={() => onAddOption(customize)}
                >
                  {translate("resources.buttons.add_option")}
                </Button>
              </Flex>
              {customize.length > 0 &&
                customize.map((items, index) => {
                  return (
                    <ItemsCustomization
                      indexItem={index}
                      key={index}
                      items={items}
                      classes={classes}
                    />
                  )
                })}
            </Flex>
          )
        }}
      </FormDataConsumer>
    </Flex>
  )
}

export default CustomizationsFields
