import React, { Component } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FieldArray } from "react-final-form-arrays"
import { isEqual, isArray } from "lodash"
import { Field } from "react-final-form"
import EditSelectChipInput from "../EditSelectChipInput"
import EditSelectColorChipInput from "../EditSelectColorChipInput"
import { getVariants } from "../utils"

class VariantDialog extends Component {
  constructor(props) {
    super(props)
    let newVariant = props.formData.variant
    if (!isArray(newVariant.options)) {
      newVariant["options"] = [newVariant.options]
      props.updateField("variant", newVariant)
    }
    this.state = {
      open: false,
      defaultOptions: newVariant,
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }
  handleCancel = () => {
    if (this.state.defaultOptions !== undefined) {
      this.props.updateField("variant", this.state.defaultOptions)
    }
    // this.props.onCancel(this.state.defaultOptions)
    this.setState({ open: false })
  }

  onSaveOptions = () => {
    const { formData } = this.props

    let value = []
    let count = 0
    const newValues = getVariants(
      formData.variant.options,
      formData.price,
      formData.brand,
      formData.slug
    )
    formData.variant.options.map((item, index) => {
      const contentValues = item.values.filter(
        (f) => !this.state.defaultOptions.options[index].values.includes(f)
      )
      if (contentValues.length > 0) {
        contentValues.map((item) => {
          value[count] = item
          count++
          return null
        })
      }
      return null
    })
    let newVariant = []
    let count1 = 0
    value.map((item) => {
      const nextValue = newValues.filter((f) => {
        const name = f.name
        return name.includes(item)
      })
      if (nextValue.length > 0) {
        nextValue.map((item) => {
          newVariant[count1] = item
          count1++
          return null
        })
      }
      return null
    })
    if (newVariant.length > 0) {
      newVariant.map((item) => {
        const contentVariants = formData.variant.variants.filter((f) => {
          const itemName = item.name
          const fieldName = f.name
          return isEqual(itemName.toLowerCase(), fieldName.toLowerCase())
        })
        if (contentVariants.length === 0) {
          formData.variant.variants.push(item)
        }
        return null
      })
      this.setState({
        defaultOptions: formData.variant,
      })
    }
  }
  handleSubmit = () => {
    // this.props.onSave()
    this.onSaveOptions()
    this.setState({ open: false })
  }
  renderOptionsList = ({ fields, meta: { error, submitFailed } }) => {
    return <div>{fields.map(this.renderOptionsFields)}</div>
  }
  renderColorField = (items, values, name) => {
    return (
      <div>
        <div>{name}</div>
        <Field
          label={name}
          name={`${items}.values`}
          component={EditSelectColorChipInput}
          dataOptions={values}
          updateField={this.props.updateField}
        />
      </div>
    )
  }
  renderOptionsFields = (items, index, fields) => (
    <div key={index}>
      <Field
        label={this.props.variant.options[index].name}
        name={`${items}.values`}
        component={EditSelectChipInput}
        dataOptions={this.props.variant.options[index].values}
        updateField={this.props.updateField}
      />
    </div>
  )
  render() {
    const { open } = this.state
    const { translate } = this.props
    return (
      <div>
        <Button variant="outlined" color="primary" onClick={this.handleOpen}>
          {translate("resources.buttons.add_variant")}
        </Button>
        <Dialog
          maxWidth={"md"}
          open={open}
          onClose={this.handleCancel}
          aria-labelledby="form-dialog-variant"
        >
          <DialogTitle id="form-dialog-variant">
            {translate("resources.products.fields.variant")}
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <FieldArray
                name="variant.options"
                component={this.renderOptionsList}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              {translate("resources.buttons.cancel")}
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              {translate("resources.buttons.save")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
export default VariantDialog
