import React, { useState } from "react";
import { useQuery } from "../../components";
import { listCategories } from "./components/categories";
import { TreeSelect } from "antd";
import { Field } from "react-final-form";
export default (props) => {
  const { source } = props;
  const { data, loading } = useQuery(`/categories`);
  const [value, setValue] = useState(undefined);
  const onChange = (value, input) => {
    // console.log(value, props);
    input.onChange(value);
    setValue(value);
  };
  if (loading) {
    return <div />;
  }

  const listCategory = listCategories(data);
  return (
    <div>
      <Field name={source}>
        {(props) => (
          <div>
            <TreeSelect
              style={{ width: 256 }}
              value={value}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
                minWidth: 256,
              }}
              placeholder='Please select'
              allowClear
              treeDefaultExpandAll
              onChange={(v) => onChange(v, props.input)}
              treeData={listCategory}
            />
          </div>
        )}
      </Field>
    </div>
  );
};
