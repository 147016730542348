import React, { useState } from "react";
// import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin";
// import { styles } from "./styles"
import SubMenuItem from "./SubMenuItem";
import MenuItemLink from "../MenuItemLink";
import { BagIcon } from "../../icon_svg";
// import { MenuShow } from "./utils"

const MenuOrders = (props) => {
  const { classes, onMenuClick } = props;
  //modules
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();

  return (
    <SubMenuItem
      handleToggle={() => setOpenMenu(!openMenu)}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name='resources.menus.orders'
      icon={<BagIcon />}
    >
      <MenuItemLink
        to={`/orders`}
        primaryText={translate(`resources.menus.list`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
        to={`/returns`}
        primaryText={translate(`resources.menus.returns`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      /> */}
      {/* <MenuShow
        translate={translate}
        to={"returns"}
        primaryText="resources.menus.returns"
        onMenuClick={onMenuClick}
        modules={modules}
      /> */}
      <MenuItemLink
        to={`/reviews`}
        primaryText={translate(`resources.menus.reviews`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
    </SubMenuItem>
  );
};

export default MenuOrders;
