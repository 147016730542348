import React, { useState, Fragment } from "react";
import { downloadCSV } from "react-admin";
import { Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { RestJsonReports } from "../../../restClient";
import moment from "moment";
/**
 * 
 Commerce Admin - Export Inventory API: GET /products/inventory/export
 */
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const ButtonExportCsv = (props) => {
  const { translate } = props;
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onSubtmit = () => {
    setLoading(true);
    RestJsonReports("/products/inventory/export")
      .then((response) => response.text())
      .then((data) => {
        if (data && data.includes('{"message":')) {
          const error = JSON.parse(data);
          setMessage(error.message);
          setOpenSnackbar(true);
        } else {
          downloadCSV(
            data,
            `Export-Inventories-date-${moment().toISOString()}`
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  return (
    <Fragment>
      <Button onClick={onSubtmit} color={"primary"} disabled={loading}>
        {translate("resources.buttons.export_to_csv")}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity='error'>
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default ButtonExportCsv;
