import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { Box } from "rebass"

const VariantSelectField = (props) => {
  const { onUpdateFilter, classes, filter, translate, variants } = props

  return (
    <Box width={[1]}>
      <FormControl className={classes.formControl}>
        <InputLabel id="variant-select-label">
          {translate(`resources.categories.fields.variant`)}
        </InputLabel>
        <Select
          labelId="variant-select-label"
          id="variant-select"
          value={filter.variant_id}
          onChange={(e) => onUpdateFilter("variant_id", e.target.value)}
        >
          {variants &&
            variants.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default VariantSelectField
