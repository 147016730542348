import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";
import { Text, Box } from "rebass";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getData } from "../../../../restClient";
const cacheTimeMinute = 1000 * 60 * 30;

const FieldView = (props) => {
  const translate = useTranslate();
  const {
    color,
    record,
    source,
    resources,
    label,
    defaultValue = "",
    pb = 3,
    isCaiptal = false,
    optionText,
  } = props;
  const { isLoading, data } = useQuery(
    `${resources}-${get(record, source)}`,
    () => getData(`/${resources}/${get(record, source)}`),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return (
      <Box pb={[pb]}>
        <LinearProgress />
      </Box>
    );
  }
  const subColor = color ? { color: color } : {};
  const fontCapital = isCaiptal ? { textTransform: "capitalize" } : {};
  return (
    <Box pb={[pb]}>
      <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
        {translate(label)}
      </Text>
      <Text style={{ ...fontCapital, ...subColor }}>
        {get(data, optionText, defaultValue)}
      </Text>
    </Box>
  );
};

export default FieldView;
