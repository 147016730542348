import React from "react"
import { Flex } from "rebass"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Button } from "@material-ui/core"

const ResetContent = (props) => {
  const { localeId, onResetPage, translate } = props
  const refEditorPage = `editorpage${localeId}`
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    onResetPage(refEditorPage, localeId)
    setOpen(false)
  }

  return (
    <Flex>
      <Button
        variant={"contained"}
        style={{ textTransform: "capitalize", marginRight: 32 }}
        color="primary"
        onClick={handleClickOpen}
      >
        {translate("resources.buttons.reset_page")}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-reset-page-title"
        aria-describedby="alert-dialog-reset-page-description"
      >
        <DialogTitle id="alert-dialog-reset-page-title">
          {translate("resources.contents.message.title_reset")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-reset-page-description">
            {translate("resources.contents.message.content_reset")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate("resources.buttons.no")}
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            {translate("resources.buttons.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Flex>
  )
}

export default ResetContent
