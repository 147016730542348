import React from "react";
import {
  TextField,
  SelectInput,
  ReferenceInput,
  SearchInput,
} from "react-admin";
import { get } from "lodash";
import moment from "moment";
import { ListPromotionAction, ButtonEditView } from "../../components";
import { Filter, BetterList } from "../../components/ra-list";
import { getSiteID } from "../../restClient";
import { dataStatus } from "./components";
const ReviewsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label=""
      source="website_id"
      reference="websites"
      alwaysOn
      InputLabelProps={{
        shrink: true,
      }}
      style={{ top: -10, position: "absolute", marginLeft: 130 }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <SelectInput source="status" choices={dataStatus} alwaysOn />
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const EffectiveDate = ({ record }) => {
  const startDate = get(record, "start_date", "");
  const endDate = get(record, "end_date", "");
  if (startDate === "" && endDate === "") {
    return <span />;
  }
  return (
    <span>
      {moment(startDate).format("DD MMM")} - {moment(endDate).format("DD MMM")}
    </span>
  );
};

const ListPromotions = (props) => {
  return (
    <BetterList
      {...props}
      actions={
        <ListPromotionAction
          title="resources.promotions.name"
          isHideCreate={false}
        />
      }
      filter={{ site_id: getSiteID() }}
      filters={<ReviewsFilter />}
    >
      <TextField source="name" label={"resources.promotions.fields.name"} />
      <TextField source="type" label={"resources.promotions.fields.type"} />
      <TextField
        source="coupon_settings.coupon_code"
        label={"resources.promotions.fields.coupon_code"}
      />
      <EffectiveDate label={"resources.promotions.fields.effective_date"} />
      <ButtonEditView {...props} />
    </BetterList>
  );
};

export default ListPromotions;
