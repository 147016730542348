import React from "react"
import { Flex } from "rebass"
import moment from "moment"
import { ReferenceInput, SelectInput, DateInput } from "react-admin"
import { RaTextInput, RaBooleanInput } from "./"
import { ROLE_USER } from "../../../utils"

export const dateParser = v => {
  const date = moment(v).toISOString()
  return date
}

const FormFields = props => {
  const { permissions } = props
  return (
    <Flex width={[1]} flexDirection={"column"}>
      {permissions === ROLE_USER.super_admin && (
        <ReferenceInput
          source="site_id"
          reference="sites"
          label={"resources.promotions.fields.site_id"}
        >
          <SelectInput optionText="name" style={{ width: 256 }} />
        </ReferenceInput>
      )}
      <RaBooleanInput
        source="active"
        label="resources.pricebook.fields.active"
      />
      <RaTextInput
        source="name"
        label="resources.pricebook.fields.name"
        style={{ width: 400 }}
      />
      <DateInput
        source="start_at"
        parse={dateParser}
        label={"resources.pricebook.fields.start_at"}
        style={{ width: 256 }}
      />
      <DateInput
        source="end_at"
        parse={dateParser}
        label={"resources.pricebook.fields.end_at"}
        style={{ width: 256 }}
      />
    </Flex>
  )
}

export default FormFields
