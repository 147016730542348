import React from "react";
import { Box, Text } from "rebass";
import { find } from "lodash";
import { useQueryWithStore, useTranslate } from "react-admin";
import CategoriesTree from "./CategoriesTree";
import { listCategories } from "./utils";

const CategoriesInit = (props) => {
  const { dataSource, translate, websiteId, formValues } = props;
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: `productCategories`,
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: {
        website_id: websiteId,
        product_id: formValues.id,
        site_id: formValues.site_id,
      },
    },
  });
  if (loading) {
    return (
      <Box width={[1]} p={[2]}>
        {translate(`resources.categories.messages.loading`)}
      </Box>
    );
  }
  let contentCategory = { id: 0, categories: [] };
  if (data && data.length > 0) {
    contentCategory = find(data, function (o) {
      return (
        o.website_id === websiteId &&
        o.product_id === formValues.id &&
        o.site_id === formValues.site_id
      );
    });
  }
  return (
    <Box width={[1]}>
      {contentCategory && contentCategory.categories && (
        <CategoriesTree
          dataSource={dataSource}
          listCategory={data ? data : []}
          contentCategory={contentCategory}
          {...props}
        />
      )}
    </Box>
  );
};

const CategoriesWebsite = (props) => {
  const { websiteId } = props;
  const translate = useTranslate();
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "categories",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "ASC" },
      filter: { website_id: websiteId },
    },
  });

  if (loading) {
    return (
      <Box p={[2]}>{translate("resources.categories.messages.loading")}</Box>
    );
  }
  console.log(data);
  return (
    <Box width={[1]}>
      {data && data.length > 0 ? (
        <CategoriesInit
          dataSource={listCategories(data)}
          translate={translate}
          {...props}
        />
      ) : (
        <Box>
          <Text fontSize={[2]}>
            {translate("resources.categories.messages.empty_list")}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default CategoriesWebsite;
