import React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  FormTab,
  TextField,
  Pagination,
  useTranslate,
} from "react-admin";
// import { useTheme } from "@material-ui/core/styles"
// import SwipeableViews from "react-swipeable-views"
// import { Divider, Tabs, Tab } from "@material-ui/core"
import { Box } from "rebass";
import {
  BetterTabbedForm,
  EditToolbar,
  TopBarTitleAction,
  ButtonEditView,
  RaReferenceManyField,
} from "../../components";
import { MainTab } from "./components";
//ChannelTab
import { ListSources } from "./components/sources";

const SiteEdit = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props;
  const translate = useTranslate();

  return (
    <Edit
      {...rest}
      actions={
        <TopBarTitleAction title={translate("resources.sites.edit_title")} />
      }
    >
      <BetterTabbedForm toolbar={<EditToolbar />} {...rest}>
        <FormTab label="resources.sites.tabs.site">
          <MainTab {...rest} />
        </FormTab>
        <FormTab label="resources.sites.tabs.websites" path={"websites"}>
          <RaReferenceManyField
            reference="websites"
            addLabel={false}
            target={"site_id"}
            pagination={<Pagination />}
            isCustomCreateButton={true}
            subheader={""}
            //translate("resources.sites.note.subheader")
            notShowSearch={true}
            labelCreate={translate("resources.buttons.create_websites")}
          >
            <Datagrid>
              <TextField source="name" />
              <DateField source="created_at" />
              <ButtonEditView {...rest} isCustomLink={true} taburl="websites" />
            </Datagrid>
          </RaReferenceManyField>
        </FormTab>
        <FormTab label="resources.sites.tabs.sources" path={"sources"}>
          <Box width={[1]} p={[2]}>
            <ListSources {...rest} />
          </Box>
        </FormTab>
        {/* <FormTab label="resources.sites.tabs.channels" path={"channels"}>
          <ChannelTab {...rest} />
          <RaReferenceManyField
            reference="channels"
            addLabel={false}
            target={"site_id"}
            pagination={<Pagination />}
            style={{ width: 500 }}
            isCustomCreateButton={true}
            subheader={""}
            notShowSearch={true}
            labelCreate={translate("resources.buttons.create")}
          >
            <Datagrid>
              <TextField source="name" />
              <DateField source="created_at" />
              <ButtonEditView
                {...props}
                isCustomLink={true}
                taburl="channels"
              />
            </Datagrid>
          </RaReferenceManyField> 
        </FormTab>*/}
      </BetterTabbedForm>
    </Edit>
  );
};

export default SiteEdit;
