import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
// import { get } from "lodash";
import { BooleanInput } from "react-admin";
import { RaNumberInput, RaTextInput } from "../../../../components";
import { PaperCard } from "./PaperCard";
// import { Button } from "@material-ui/core";

// const AutoFields = (props) => {
//   const { handleOpen, translate } = props;

//   return (
//     <Fragment>
//       <RaNumberInput
//         source="coupon_settings.qty"
//         label="resources.promotions.fields.qty"
//         style={{ width: 100 }}
//       />
//       <RaTextInput
//         source="coupon_settings.coupon_prefix"
//         label="resources.promotions.fields.coupon_prefix"
//         style={{ width: 256 }}
//         InputLabelProps={{ shrink: true }}
//       />
//       <RaTextInput
//         source="coupon_settings.coupon_suffix"
//         label="resources.promotions.fields.coupon_suffix"
//         style={{ width: 256 }}
//         InputLabelProps={{ shrink: true }}
//       />
//       <RaNumberInput
//         source="coupon_settings.coupon_code_length"
//         label="resources.promotions.fields.coupon_code_length"
//         style={{ width: 200 }}
//       />
//       <Flex
//         width={[1]}
//         flexDirection="row"
//         sx={{
//           "&>button": {
//             mr: [2],
//             my: [2],
//           },
//         }}
//       >
//         <Button color={"primary"} onClick={() => handleOpen("generate")}>
//           {translate("resources.buttons.generate")}
//         </Button>
//         <Button color={"primary"} onClick={() => handleOpen("view")}>
//           {translate("resources.buttons.view")}
//         </Button>
//         <Button color={"primary"} onClick={() => handleOpen("download")}>
//           {translate("resources.buttons.download_cvs")}
//         </Button>
//       </Flex>
//     </Fragment>
//   );
// };
const NotAutoFields = (props) => {
  return (
    <Fragment>
      <RaTextInput
        source="coupon_settings.coupon_code"
        label="resources.promotions.fields.coupon_code"
        style={{ width: 256 }}
        InputLabelProps={{ shrink: true }}
      />
      <RaNumberInput
        source="coupon_settings.limit_number_of_use"
        label="resources.promotions.fields.limit_number_use"
        style={{ width: 200 }}
        notetext="resources.promotions.note_text.limit_number_use"
      />

      <RaNumberInput
        source="coupon_settings.limit_per_customer"
        label="resources.promotions.fields.limit_per_customer"
        style={{ width: 200 }}
        notetext="resources.promotions.note_text.limit_per_customer"
      />
    </Fragment>
  );
};

const CouponSettingFields = (props) => {
  const { translate } = props;
  //formData
  // const isAuto = get(formData, "coupon_settings.auto_generate", false);
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.coupon_settings")}
        elevation={3}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <BooleanInput
            source={"coupon_settings.auto_generate"}
            label="resources.promotions.fields.auto_generate"
          />
          {/* {isAuto ? <AutoFields {...props} /> :} */}
          <NotAutoFields {...props} />
        </Flex>
      </PaperCard>
    </Box>
  );
};

export default CouponSettingFields;
