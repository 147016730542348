import lomap from "lodash/map";
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import styled from "styled-components";
import zipObject from "lodash/zipObject";

import create from "zustand";
export const useStoreAttributes = create((set) => ({
  attributes: [],
  oldAttributes: [],
  setAttributes: (newState) =>
    set((state) => {
      return { attributes: newState };
    }),
  setOldAttributes: (newState) =>
    set((state) => {
      return { oldAttributes: newState };
    }),
}));

const cartesianProduct = (elements, name, price, brandProduct, slugProduct) => {
  if (!Array.isArray(elements)) {
    return null;
  }
  const end = elements.length - 1;
  const result = [];

  function addTo(curr, start) {
    var first = elements[start],
      last = start === end;
    for (var i = 0; i < first.length; ++i) {
      var copy = curr.slice();
      copy.push(first[i]);
      if (last) {
        const variant = setVariantObject(
          copy,
          name,
          price,
          brandProduct,
          slugProduct
        );
        // console.log(variant);
        result.push(variant);
      } else {
        addTo(copy, start + 1);
      }
    }
  }

  if (elements.length) {
    addTo([], 0);
  } else {
    result.push([]);
  }

  return result;
};

const setVariantObject = (copy, name, price, brandProduct, slugProduct) => {
  var newOptions = {};
  var variants = {};
  var newName = "";
  copy.map((item, index) => {
    var obj = {};
    obj[name[index]] = item;
    newName += item + " ";
    newOptions[name[index]] = item;
    return obj;
  });
  // const nameP = nameProduct.trim().split(' ').join('-');
  const nameP = brandProduct.trim().split(" ").join("-");
  const nameS = slugProduct.trim().split(" ").join("-");
  const nameV = newName.trim().split(" ").join("-");
  const globalName = nameP + "-" + nameS + "-" + nameV;
  variants["qty"] = 1;
  variants["sku"] = globalName.toLowerCase();
  variants["name"] = newName.trim();
  variants["price"] = price;
  variants["threshold"] = 0;
  variants["active"] = true;
  variants["variant"] = newOptions;
  variants["code"] = globalName.toLowerCase();

  return variants;
};
export const getVariants = (elements, price, brandProduct, slugProduct) => {
  if (!Array.isArray(elements)) {
    return null;
  }
  const end = elements.length - 1;
  const contentOptions = [];
  const contentName = [];
  for (var j = 0; j <= end; j++) {
    const item = elements[j];
    contentOptions.push(item.values);
    contentName.push(item.name);
  }

  const result = cartesianProduct(
    contentOptions,
    contentName,
    price,
    brandProduct,
    slugProduct
  );
  return result;
};
export const isEqual = (value, other) => {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  const otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = function (item1, item2) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false; // Otherwise, do a simple comparison;
    } else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const BoxMessage = styled.div`
  display: flex;
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgba(236, 58, 58, 0.35);
  flex: 1;
`;

export const isVariant = (variant, nameProduct) => {
  if (
    variant &&
    variant.options &&
    variant.options.length > 0 &&
    nameProduct &&
    nameProduct !== ""
  ) {
    if (variant.options[0].name) {
      // console.log(variant)
      // console.log(nameProduct)
      return true;
    }
  }
  return false;
};

export const checkPhotosColorRecord = (photos, variant) => {
  let updateData = {};

  if (photos && photos !== null) {
    if (Array.isArray(photos)) {
      const optionsColor = variant.options.filter((item) => {
        const name = get(item, "name", undefined);
        return (
          (lowerCase(name) === "color" ||
            lowerCase(name) === "colors" ||
            lowerCase(name) === "warna") &&
          item.values &&
          item.values.length > 0
        );
      });

      if (optionsColor.length > 0) {
        const data = zipObject(
          optionsColor[0].values,
          lomap(optionsColor[0].values, (item, index) => {
            return { code: "#FFFFFF", photos: photos };
          })
        );
        return data;
      }
    } else if (photos["*"] && photos["*"].photos) {
      const optionsColor = variant.options.filter((item) => {
        const name = get(item, "name", undefined);
        return (
          (lowerCase(name) === "color" ||
            lowerCase(name) === "colors" ||
            lowerCase(name) === "warna") &&
          item.values &&
          item.values.length > 0
        );
      });
      if (optionsColor.length > 0) {
        const data = zipObject(
          optionsColor[0].values,
          lomap(optionsColor[0].values, (item, index) => {
            return { code: "#FFFFFF", photos: photos["*"].photos };
          })
        );
        return data;
      }
    } else {
      if (photos.photo_set) {
        const photoSet = photos.photo_set;
        const optionsColor = variant.options.filter((item) => {
          const name = get(item, "name", undefined);
          return (
            (lowerCase(name) === "color" ||
              lowerCase(name) === "colors" ||
              lowerCase(name) === "warna") &&
            item.values &&
            item.values.length > 0
          );
        });
        //check image when user add variant
        if (optionsColor.length > 0) {
          const data = zipObject(
            optionsColor[0].values,

            lomap(optionsColor[0].values, (item, index) => {
              // const gotOldSetUp = lodashFind(photoSet,)

              const getPhotoSet = get(photoSet, `${item}`);
              return getPhotoSet
                ? getPhotoSet
                : { code: "#FFFFFF", photos: [] };
            })
          );
          updateData = data;
        } else {
          updateData = photos.photo_set;
        }
      } else {
        updateData = photos.photo_set;
      }
    }
  } else {
    const optionsColor = variant.options.filter((item) => {
      const name = get(item, "name", undefined);
      return (
        (lowerCase(name) === "color" ||
          lowerCase(name) === "colors" ||
          lowerCase(name) === "warna") &&
        item.values &&
        item.values.length > 0
      );
    });
    if (optionsColor.length > 0) {
      const data = zipObject(
        optionsColor[0].values,
        lomap(optionsColor[0].values, (item, index) => {
          return { code: "#FFFFFF", photos: [] };
        })
      );
      return data;
    }
  }
  // console.log(updateData)
  return updateData;
};

export const variantData = [
  {
    id: "color",
    name: "Color",
  },
  {
    id: "collection",
    name: "Collection",
  },
  {
    id: "design",
    name: "Design",
  },
  {
    id: "model",
    name: "Model",
  },
  {
    id: "package",
    name: "Package",
  },
  {
    id: "size",
    name: "Size",
  },
  {
    id: "weight",
    name: "Weight",
  },
];
export const newDataColors = [
  "Black",
  "Blue",
  "Orange",
  "Green",
  "Gray",
  "Gold",
  "Purple",
  "Red",
  "Silver",
  "Yellow",
  "White",
];

export const dataColors = [
  {
    id: "black",
    name: "Black",
  },
  {
    id: "brown",
    name: "Brown",
  },
  {
    id: "blue",
    name: "Blue",
  },
  {
    id: "orange",
    name: "Orange",
  },
  {
    id: "gold",
    name: "Gold",
  },
  {
    id: "white",
    name: "White",
  },
  {
    id: "green",
    name: "Green",
  },
  {
    id: "gray",
    name: "Gray",
  },
  {
    id: "silver",
    name: "Silver",
  },
  {
    id: "red",
    name: "Red",
  },
  {
    id: "purple",
    name: "Purple",
  },
  {
    id: "yellow",
    name: "Yellow",
  },
];
