import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import { Box } from "rebass";
import { toLower, replace, trim, includes, toNumber } from "lodash";
const ButtonImportCSV = (props) => {
  const { accept, disableClick, maxSize, minSize, multiple, onImport } = props;
  /**
   * type: type,
    start_at: start_at,
    end_at: end_at,
    name: state.name,
    country_id: state.country_id,
   */
  const [array, setArray] = useState([]);
  const fileReader = new FileReader();
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        const lowerHeader = trim(toLower(header));
        const myHeader = includes(lowerHeader, "id") ? "id" : lowerHeader;
        const myValue = replace(values[index], "\r", "");
        object[myHeader] = myHeader === "id" ? toNumber(myValue) : myValue;
        return object;
      }, {});
      return obj;
    });

    setArray(array);
    return array;
  };
  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      //   console.log("accepted", accepted);
      if (accepted) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          const list = csvFileToArray(text);
          onImport(list);
        };

        fileReader.readAsText(accepted[0]);
        console.log("array", array);
      }
    }
  };

  return (
    <Box p={[2]}>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div>
            <div {...getRootProps()}>
              {!disableClick && <input {...getInputProps()} />}

              <Button
                color="primary"
                disabled={disableClick}
                style={{
                  textTransform: "capitalize",
                }}
              >
                Import CSV
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
    </Box>
  );
};

ButtonImportCSV.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
};

ButtonImportCSV.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  accept: ".csv, application/vnd.ms-excel, text/csv",
  onUpload: () => {},
};

export default ButtonImportCSV;
