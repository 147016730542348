import * as React from "react";
import PropTypes from "prop-types";
import { FormInput } from "react-admin";
import { FormSpy } from "react-final-form";
const hiddenStyle = { display: "none" };

const FormTab = ({
  basePath,
  className,
  contentClassName,
  children,
  hidden,
  icon,
  intent,
  label,
  margin,
  record,
  resource,
  variant,
  value,
  ...rest
}) => {
  const renderContent = () => {
    return (
      <FormSpy subscription={{ values: true }}>
        {(propsValue) => {
          return (
            <span
              style={hidden ? hiddenStyle : null}
              className={contentClassName}
            >
              {React.Children.map(
                children,
                (input) =>
                  input && (
                    <FormInput
                      basePath={basePath}
                      input={input}
                      record={record}
                      resource={resource}
                      variant={input.props.variant || variant}
                      margin={input.props.margin || margin}
                      formValues={propsValue.values}
                    />
                  )
              )}
            </span>
          );
        }}
      </FormSpy>
    );
  };

  return intent === "header" ? null : renderContent();
};

FormTab.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  intent: PropTypes.oneOf(["header", "content"]),
  hidden: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  path: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

FormTab.displayName = "FormTab";

export default FormTab;
