import React from "react"
import { TextField } from "react-admin"
import { BetterList, HeaderTitle } from "../../components"

import { EditVariantDialog, CreateVariantDialog } from "./components"

const VariantsList = (props) => {
  return (
    <BetterList
      {...props}
      actions={
        <HeaderTitle title={`resources.variants.title`}>
          <CreateVariantDialog {...props} />
        </HeaderTitle>
      }
    >
      <TextField source="name" label="resources.variants.fields.name" />
      <TextField source="code" label="resources.variants.fields.code" />
      <EditVariantDialog {...props} />
    </BetterList>
  )
}
export default VariantsList
